import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Table,
  Typography,
  Popconfirm,
  message,
  Select,
  DatePicker,
  Tag,
  Tooltip,
  Radio,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import {
  FiEdit2,
  FiFilter,
  FiPlus,
  FiSearch,
  FiTrash,
  FiGrid,
  FiUser,
} from "react-icons/fi";
import { Breadcrumb } from "../../components/Breadcrumb";
import { Actions, Input } from "./styles";
import { useAppSelector } from "../../hooks";
import ParameterService from "../../services/SalesPlanning/Setup/Parameter";
import { ParameterData } from "../../services/SalesPlanning/Setup/Parameter/ParameterDataTypes";
import GlobalService from "../../services/Global";
import moment, { Moment } from "moment";
import { UserData } from "../../services/Global/User/userDataTypes";
import UserService from "../../services/Global/User";
// import create from "../../services/Global";
// import getglCompanies from "../../services/Global";

const { Option } = Select;
export function Owner_empresas() {
  const companyId = useAppSelector((state) => state.parameters.company.id);

  const [modalNewParameter, setShowModalNewParameter] =
    useState<boolean>(false);

  const [modalDelete, setShowmodalDelete] = useState<boolean>(false);

  const [isLoading, setLoading] = useState<boolean>(false);

  const [selectedParameterId, setSelectedParameterId] = useState<number | null>(
    null
  );
  const [listParameters, setListParameters] = useState<any[]>([]);
  const [name, setName] = useState<string>("");
  const [value, setValue] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [searchData, setSearchData] = useState<any>([]);
  const [modalEditAccessType, setModalEditAccessType] = useState(false);
  const [modalEditUsers, setModalEditUsers] = useState(false);

  const [selectedInitiativeId, setSelectedInitiativeId] = useState<any>([]);
  const [selectedInitiative, setSelectedInitiative] = useState<any>([]);

  const [allok, setAllok] = useState<boolean>(false);
  // const [selecteds, setSelecteds] = useState<any[]>([]);
  const [selecteds, setSelecteds] = useState<string[]>([]);
  const [dataFim, setDataFim] = useState<string>("");
  const [dataIni, setDataIni] = useState<string>("");

  const [password, setPassword] = useState<string>("");
  const [passwordEntered, setPasswordEntered] = useState<boolean>(false);

  const [listUsers, setListUsers] = useState<any[]>([]);
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const [searchUserData, setSearchUserData] = useState<any>([]);

  // const handlePasswordSubmit = (password: string) => {
  //   if (password === "token") {
  //     setPasswordEntered(true);
  //   } else {
  //     alert("Senha inválida"); // Replace alert with your preferred error-handling mechanism
  //   }
  // };

  const handlePasswordSubmit = async (password: string): Promise<boolean> => {
    if (password === "token") {
      setPasswordEntered(true);
      return true;
    } else {
      alert("Senha inválida"); // Replace alert with a preferred error-handling mechanism
      return false;
    }
  };

  const getListParameter = useCallback(async () => {
    localStorage.setItem("historico", "buscou companies");
    try {
      setLoading(true);
      const response = await GlobalService.getglCompanies();
      console.log(response);
      setListParameters(response);
      setSearchData(response);
      setSearch("");
    } catch (e) {
      console.error("Falha ao buscar parâmetros: ");
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [companyId]);

  useEffect(() => {
    getListParameter();
  }, [getListParameter]);

  const columns2 = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
      sorter: (a: any, b: any) => a.email.localeCompare(b.email),
    },
    {
      title: "Profile",
      dataIndex: "profile",
      key: "profile",
    },
    // {
    //   title: "Owner",
    //   dataIndex: "access",
    //   key: "access",
    //   render: (access: number) => (access === 10 ? "Sim" : "Não"),

    // },
    {
      title: "Owner",
      key: "toggleAccess",
      render: (record: any) => (
        <Radio.Group
          value={record.access === 10 ? "checked" : "unchecked"}
          onChange={async (e) => {
            const isChecked = e.target.value === "checked";

            // Update the backend
            await GlobalService.updateAcessUser({
              id: record.id,
              access: isChecked ? 10 : null,
            });

            // Update the front-end state
            const updatedData = searchUserData.map((item: any) => {
              if (item.id === record.id) {
                return { ...item, access: isChecked ? 10 : null };
              }
              return item;
            });

            setSearchUserData(updatedData); // Ensure you update the state holding the table data
          }}
        >
          <Radio value="checked">Sim</Radio>
          <Radio value="unchecked">Não</Radio>
        </Radio.Group>
      ),
    },
  ];

  const columns = [
    {
      title: "Razão",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: "CNPJ",
      dataIndex: "registered_number",
      key: "registered_number",
      sorter: (a: any, b: any) =>
        a.registered_number.localeCompare(b.registered_number),
      render: (text: any) => {
        if (!text) return ""; // Return an empty string if `text` is undefined or falsy
        if (text.length > 50) {
          return text.substring(0, 50) + "..."; // Truncate if length exceeds 50
        }
        // Format CNPJ as "XX.XXX.XXX/XXXX-XX" if it's exactly 14 digits
        const formattedCNPJ =
          text.length === 14
            ? text.replace(
                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                "$1.$2.$3/$4-$5"
              )
            : text;

        return formattedCNPJ;
      },
    },
    {
      title: "Módulos",
      dataIndex: "module_names",
      key: "module_names",
      sorter: (a: any, b: any) => a.module_names.localeCompare(b.module_names),
    },
    {
      title: "Data de Inicio",
      dataIndex: "dateinit",
      key: "dateinit",
      sorter: (a: any, b: any) => a.dateinit.localeCompare(b.dateinit),
      render: (date: string | undefined): string => {
        return date
          ? date.replace(/^(\d{4})-(\d{2})-(\d{2}).*/, "$3/$2/$1")
          : "";
      },
    },
    {
      title: "Data de Vencimento",
      dataIndex: "dateend",
      key: "dateend",
      sorter: (a: any, b: any) => a.dateend.localeCompare(b.dateend),
      render: (date: string | undefined): string => {
        return date
          ? date.replace(/^(\d{4})-(\d{2})-(\d{2}).*/, "$3/$2/$1")
          : "";
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a: any, b: any) => a.status.localeCompare(b.status),
      render: (text: string) => {
        return <Tag color={getTagColor(text)}>{getTagText(text)}</Tag>;
      },
    },
    {
      title: "Ações",
      dataIndex: "uf",
      key: "uf",
      width: 150,
      align: "center" as const,
      render: (_: null, record: any) => {
        return (
          <Actions>
            <Tooltip title="Usuários">
              <Button
                type="text"
                icon={
                  <FiUser
                    size={18}
                    color="#00CC66"
                    onClick={() => handleUsers(record)}
                  />
                }
              />
            </Tooltip>
            <Tooltip title="Módulos">
              <Button
                type="text"
                icon={
                  <FiGrid
                    size={18}
                    color="#00CC66"
                    onClick={() => handleClickModule(record)}
                  />
                }
              />
            </Tooltip>
            <Tooltip title="Editar">
              <Button
                type="text"
                icon={
                  <FiEdit2
                    size={18}
                    color="#00CC66"
                    onClick={() => handleClickEdit(record)}
                  />
                }
              />
            </Tooltip>
            <Tooltip title="Excluir">
              <Popconfirm
                title="Tem certeza que deseja excluir essa empresa?"
                onConfirm={() => ModalconfirmDelete(record)}
                okText="Excluir"
                okButtonProps={{ danger: true }}
                cancelText="Cancelar"
              >
                <Button
                  type="text"
                  icon={<FiTrash size={18} color="#00CC66" />}
                />
              </Popconfirm>
            </Tooltip>
          </Actions>
        );
      },
    },
  ];

  async function ModalconfirmDelete(parameter: any) {
    setSelectedParameterId(parameter.company_id ?? null);
    setName(parameter.name);
    setShowmodalDelete(true);
  }

  async function confirmDelete() {
    const isPasswordValid = await handlePasswordSubmit(password);
    if (!isPasswordValid) {
      return; // Exit the function if the password is invalid
    }

    try {
      await GlobalService.deleteById(selectedParameterId);
    } catch (e) {
      console.error("Falha ao excluir parâmetro: ");
      console.error(e);
    }
    await getListParameter();
    setShowmodalDelete(false);
    clearData();
  }

  async function handleChange(value: string | string[] | any) {
    setSelecteds(await value);

    console.log(selecteds);
  }

  // async function handleChangecnpj(value: string | string[] | any) {
  //   setSelecteds(await value);
  // }

  const formatCNPJ = (cnpj: string): string => {
    // Remove non-numeric characters
    const onlyNumbers = cnpj.replace(/\D/g, "");
    // Apply formatting
    return onlyNumbers
      .replace(/^(\d{2})(\d)/, "$1.$2")
      .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
      .replace(/\.(\d{3})(\d)/, ".$1/$2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .substring(0, 18); // Limit length to a valid CNPJ
  };

  const handleChangecnpj = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatCNPJ(e.target.value);
    setValue(formattedValue);
  };

  function getTagText(text: string): string {
    switch (text) {
      case "A":
        return "Ativo";
      case "I":
        return " Inativo";

      default:
        return "Ativo";
    }
  }
  function getTagColor(text: string): string {
    switch (text) {
      case "A":
        return "green";
      case "I":
        return "red";

      default:
        return "green";
    }
  }

  const onChangeend = (date: Moment | null, dateString: string | string[]) => {
    if (typeof dateString === "string" && dateString) {
      console.log("Selected Date:", dateString);

      // Format the dateString to YYYY-MM-DD
      const [day, month, year] = dateString.split("/");
      const formattedDate = `${year}-${month}-${day}`;
      setDataFim(formattedDate);
    } else {
      setDataFim(""); // Clear the state
    }
  };

  const onChangeinit = (date: Moment | null, dateString: string | string[]) => {
    if (typeof dateString === "string" && dateString) {
      console.log("Selected Date:", dateString);

      // Format the dateString to YYYY-MM-DD
      const [day, month, year] = dateString.split("/");
      const formattedDate = `${year}-${month}-${day}`;
      setDataIni(formattedDate);
    } else {
      setDataIni(""); // Clear the state
    }
  };

  async function handleOk() {
    //  console.log(selecteds);
    handleClear();

    const payload = {
      id: selectedParameterId, // Company ID
      modules: selecteds
        .map((module) => {
          switch (module) {
            case "PGT":
              return 1; // Map to module IDs
            case "PGC":
              return 2;
            case "PGV":
              return 3;
            case "REBATE":
              return 4;
            default:
              return null; // Handle unexpected cases
          }
        })
        .filter((moduleId) => moduleId !== null), // Remove any `null` values
    };
    // const payload = {
    //   id: selectedParameterId,
    //   modules: selecteds
    // };
    localStorage.setItem(
      "historico",
      "editou modulos: " + JSON.stringify(payload)
    );
    try {
      // Send the data to the server

      await GlobalService.updateModules(payload);
      setAllok(true);
      localStorage.setItem("historico", "");
      handleClear();
    } catch (error) {
      localStorage.setItem("historico", "");
      console.error("Failed to update modules:", error);
    }

    setAllok(true);
  }

  async function handleOkUser() {
    //  console.log(selecteds);
    handleClear();
  }

  useEffect(() => {
    if (allok === true) {
      // putAccessType();
      getListParameter();
      setAllok(false);
      handleCancel();
    }
  }, [allok]);

  function handleClear() {
    // setPgc_action_plan(false);
    // setPgc_budget(false);
    // setPgc_customer_mapping(false);
    // setPgc_customer_wallet(false);
    // setPgc_level3_mapping(false);
  }

  const onChangeHandler = (value: any) => (event: any) => {
    // setSelectedInitiative({ value: event.target.value });
    setSelectedInitiativeId({ value: event.target.value });
  };

  function handleClickEdit(parameter: any) {
    console.log(parameter);
    setSelectedParameterId(parameter.company_id ?? null);
    setName(parameter.name);
    setValue(formatCNPJ(parameter.registered_number));
    setSelectedInitiativeId(parameter.type_id);
    setDataFim(parameter.dateend);
    setDataIni(parameter.dateinit);
    // setSelectedInitiative(parameter.type_id);
    setShowModalNewParameter(true);
  }

  // function handleClickModule(parameter: any) {
  //   console.log(parameter);
  //   setSelectedParameterId(parameter.company_id ?? null);
  //   setName(parameter.name);
  //   // setValue(parameter.registered_number);
  //   // setSelectedInitiativeId(parameter.type_id);
  //   // setSelectedInitiative(parameter.type_id);
  //   setModalEditAccessType(true);
  // }

  function handleClickModule(parameter: any) {
    setSelectedParameterId(parameter.company_id ?? null);
    setName(parameter.name);

    // Split module_names and set selecteds
    const modulesArray = parameter.module_names
      ? parameter.module_names.split(",")
      : [];
    setSelecteds(modulesArray);

    setModalEditAccessType(true);
  }

  const handleUsers = async (parameter: any) => {
    setSelectedParameterId(parameter.company_id ?? null);
    setName(parameter.name);

    try {
      const response = await UserService.getCompanyUserWithGlobalUser(
        parameter.company_id
      );
      setListUsers(response);
      setSearchUserData(response);

      // Split module_names and set selecteds
      const modulesArray = parameter.module_names
        ? parameter.module_names.split(",")
        : [];
      setSelecteds(modulesArray);

      setModalEditUsers(true);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  function showModalNewParameter() {
    setShowModalNewParameter(true);
  }

  async function handleSave() {
    console.log(dataFim);
    try {
      if (selectedParameterId) {
        localStorage.setItem("historico", "Atualizou empresa:" + name);
        await GlobalService.update({
          id: selectedParameterId,
          name: name.toUpperCase(),
          registered_number: value.replace(/\D/g, ""),
          dt_vencimento: dataFim ?? null,
          dt_inicio: dataIni ?? null,
        });
      } else {
        localStorage.setItem("historico", "criou empresa:" + name);
        await GlobalService.create({
          name: name.toUpperCase(),
          registered_number: value.replace(/\D/g, ""),
          dt_vencimento: dataFim ?? null,
          dt_inicio: dataIni ?? null,
        });
      }
    } catch (e) {
      console.error("Falha ao salvar parâmetro: ");
      console.error(e);
    }

    await getListParameter();
    setShowModalNewParameter(false);
    clearData();
  }

  function clearData() {
    setSelectedParameterId(null);
    setName("");
    setValue("");
    setPassword("");
  }

  function handleCancel() {
    setModalEditUsers(false);
    setModalEditAccessType(false);
    setShowModalNewParameter(false);
    setShowmodalDelete(false);
    setSelectedInitiativeId(null);
    setSelectedInitiative(null);
    clearData();
  }

  function searchTable(value: string) {
    if (value) {
      setSearch(value);

      const array = listParameters.filter((record) => {
        // Check if the value exists in `name` or `module_names`
        const nameMatch = record["name"]
          ?.toLowerCase()
          .includes(value.toLowerCase());
        const moduleNamesMatch = record["module_names"]
          ?.toLowerCase()
          .includes(value.toLowerCase());
        return nameMatch || moduleNamesMatch; // Include records matching either condition
      });

      setSearchData(array);
    } else {
      setSearch("");
      setSearchData(listParameters); // Reset to the full list if no value is entered
    }
  }

  return (
    <Breadcrumb path="Empresas" child={["Parâmetro"]}>
      <>
        <Card bordered={false}>
          <Row gutter={[16, 16]}>
            <Col span={18}>
              <Button
                type="primary"
                icon={<FiPlus />}
                onClick={showModalNewParameter}
              >
                Novo
              </Button>
            </Col>
            <Col span={6}>
              <Input
                allowClear
                value={search}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  searchTable(e.target.value)
                }
                placeholder="Pesquisar"
                prefix={<FiSearch color="#00CC66" />}
                suffix={<FiFilter color="#00CC66" />}
              />
            </Col>
            <Col span={24}>
              <Table
                rowKey="id"
                size="small"
                columns={columns}
                dataSource={searchData}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Card>

        <Modal
          open={modalNewParameter}
          okText={"Salvar"}
          onOk={handleSave}
          onCancel={handleCancel}
        >
          <Row justify="center">
            <Col span={16} style={{ textAlign: "center" }}>
              <Typography.Title level={4}>
                {selectedParameterId ? "Atualizar" : "Nova"} Empresa
              </Typography.Title>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  Razão
                </Typography.Text>
                <Input
                  size="large"
                  placeholder="Razão"
                  value={name != "" ? name.toUpperCase() : name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setName(e.target.value)
                  }
                />
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  CNPJ
                </Typography.Text>
                <Input
                  size="large"
                  placeholder="CNPJ"
                  value={value}
                  onChange={handleChangecnpj}
                  // onChange={(e) => setValue(e.target.value)}
                />
              </Col>

              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  Data de Inicio
                </Typography.Text>
                <DatePicker
                  size="large"
                  format="DD/MM/YYYY"
                  placeholder="Data de Inicio"
                  value={dataIni ? moment(dataIni, "YYYY-MM-DD") : null} // Convert string to moment
                  onChange={onChangeinit}
                />
              </Col>

              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  Data de Vencimento
                </Typography.Text>
                <DatePicker
                  size="large"
                  format="DD/MM/YYYY"
                  placeholder="Data de Vencimento"
                  value={dataFim ? moment(dataFim, "YYYY-MM-DD") : null} // Convert string to moment
                  onChange={onChangeend}
                />
              </Col>
            </Col>
          </Row>
        </Modal>

        <Modal
          open={modalDelete}
          okText={"Confirmar"}
          onOk={confirmDelete}
          onCancel={handleCancel}
        >
          <Row justify="center">
            <Col span={16} style={{ textAlign: "center" }}>
              <Typography.Title level={4}>
                Deseja realmente deletar a empresa {name} e todos os seus
                registros?
              </Typography.Title>

              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  Senha Admin
                </Typography.Text>
                <Input
                  type="text"
                  placeholder="Senha"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </Col>
            </Col>
            {/* 
            <div style={{ margin:"5px" }}>
      
              <button className="confirm-button"   onClick={handlePasswordSubmit} >Entrar</button>
      
              </div> */}
          </Row>
        </Modal>

        <Modal
          title="Atualizar acesso aos Módulos"
          open={modalEditAccessType}
          onCancel={() => handleCancel()}
          onOk={() => handleOk()}
        >
          <Input
            placeholder="Nome"
            style={{ width: "100%", margin: "10px 0" }}
            size="large"
            value={name}
            disabled={true}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setName(e.target.value)
            }
          />

          {/* {listAccessType !== undefined ? ( */}
          <Select
            mode="tags"
            // size={size}
            placeholder="Selecione os módulos"
            // defaultValue={selecteds}
            onChange={handleChange}
            value={selecteds}
            style={{ width: "100%" }}
          >
            <>
              <Option key="PGT" value="PGT">
                PGT
              </Option>
              <Option key="PGC" value="PGC">
                PGC
              </Option>
              <Option key="PGV" value="PGV">
                PGV
              </Option>
              <Option key="REBATE" value="REBATE">
                REBATE
              </Option>
            </>
          </Select>
          {/* ) : null} */}
        </Modal>

        <Modal
          title="Atualizar Usuários"
          open={modalEditUsers}
          onCancel={() => handleCancel()}
          onOk={() => handleOkUser()}
          width={800} // Adjust the width as needed
        >
          <Input
            placeholder="Nome"
            style={{ width: "100%", margin: "10px 0" }}
            size="large"
            value={name}
            disabled={true}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setName(e.target.value)
            }
          />

          <Row>
            <Col span={24}>
              <Table
                rowKey="id"
                size="small"
                columns={columns2}
                dataSource={searchUserData}
                // loading={isLoading}
                // rowSelection={{
                //   type: "radio",
                //   ...userRowSelection,
                // }}
              />
            </Col>
          </Row>

          {/* {listAccessType !== undefined ? ( */}

          {/* ) : null} */}
        </Modal>
      </>
    </Breadcrumb>
  );
}
