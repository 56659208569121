import {
  Card,
  Col,
  Row,
  Table,
  Form,
  Select,
  Button,
  Input,
  message,
  Modal,
  Typography,
  Breadcrumb as BreadcrumbAntd,
  Tooltip,
} from "antd";
import { useCallback, createRef, useEffect, useState, RefObject } from "react";
import { FiFilter, FiMenu, FiSearch, FiX } from "react-icons/fi";
import { Breadcrumb } from "../../../components/Breadcrumb";
import {
  compareLabelsTables,
  useAppDispatch,
  useAppSelector,
} from "../../../hooks";
import { FormInstance } from "antd/es/form";
import { CommercialStructure } from "../../../components/Filter/CommercialStructure";
import leftIcon from "../../../assets/left.svg";
import ActionPlanService from "../../../services/Pgc/ActionPlan";
import {
  Actions,
  Buttons,
  Container,
  Content,
  Header,
  LeftIcon,
} from "./styles";
import {
  resetFilter,
  setFiltercommercialStucture,
} from "../../SalesPlanning/Reducer";
import SalesForecastService from "../../../services/Global/SalesForecast";
import { clearCommercialStructure } from "../../../entities/Reducer";
import { InputNumberTable } from "../../../components/TableDinamic/InputNumber";
import { InputNumberTableBr } from "../../../components/TableDinamic/InputNumberbr";
import { LoadingOutlined } from "@ant-design/icons";
import Column from "antd/lib/table/Column";
import { useNavigate } from "react-router-dom";
const { Option } = Select;

export function SalesForecast() {
  const [modalVisible, setModalVisible] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const Icon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const [status, setStatus] = useState<string>("");
  const formRef = createRef<FormInstance>() as RefObject<FormInstance<any>>;
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<any>(undefined);
  const [search, setSearch] = useState("");
  const [listTasks, setListTasks] = useState<any>();
  const [volume, setVolume] = useState<any>();
  const [produto, setProduto] = useState<any>();
  const [product_group_id, setProduct_group_id] = useState<any>();
  const [level4, setLevel4] = useState<any>();
  const [Cliente, setCliente] = useState<any>();
  const [Category, setCategory] = useState<any>();
  const [selectCategory, setselectCategory] = useState<any>();
  const [Query, setQuery] = useState<any>();

  const [year, setYear] = useState<any>();
  const [resultData, setResultData] = useState<any>(undefined);
  const [dataTotal, setDataTotal] = useState<any>(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [area, setArea] = useState<number | undefined>(undefined);
  const [newStatus, setNewStatus] = useState<string>("");
  const [disbled, setDisbled] = useState(false);
  const [modalText, setModalText] = useState<string>("");

  if (area !== undefined) {
    if (isNaN(area)) {
      setArea(0);
    }
  }

  const commercialStructure = useAppSelector(
    (state) => state.salesPlanning.filter
  );
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (status === "F") {
      setNewStatus("Reabrir");
      setDisbled(true);
      setModalText("Deseja reabrir a Previsão  de vendas?");
    } else {
      setNewStatus("Finalizar");
      setDisbled(false);
      setModalText("Deseja finalizar a Previsão  de vendas ?");
    }
  }, [status]);

  useEffect(() => {
    return function clean() {
      dispatch(resetFilter());
      dispatch(clearCommercialStructure());
    };
  }, [dispatch]);

  const [filter, setFilter] = useState(false);

  const getCategory = useCallback(async () => {
    try {
      const response = await SalesForecastService.GetCategory(companyId);
      setselectCategory(response);
    } catch (error) {
      console.log(error);
    }
  }, [companyId]);

  async function BugetLevel4(level4: any) {
    setCliente(undefined);
    setResultData(undefined);
    try {
      setLoading(true);
      const response = await SalesForecastService.getBudgetLevel4(
        companyId,
        level4
      );
      if (response.length > 0) {
        setData(response);
        getCategory();
      } else {
        setData([]);
        setResultData(undefined);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function BugetLevel4Category(level4: any) {
    setCliente(undefined);
    setResultData(undefined);
    try {
      setLoading(true);
      const response = await SalesForecastService.getBudgetLevel4Category(
        companyId,
        level4,
        Category
      );
      if (response.length > 0) {
        setData(response);
        getCategory();
      } else {
        setData([]);
        setResultData(undefined);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function SalesForecastState(level4: any) {
    try {
      setLoading(true);
      const response = await SalesForecastService.getBudgetStatus(
        companyId,
        level4
      );
      setStatus(response.pgc_budget);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function formatReal(int: number) {
    var tmp = int + "00";
    tmp = tmp.replace(".", ",").replace(/([0-9]{2})$/g, ",$1");
    if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    return tmp;
  }

  function formatReal2(int: number) {
    var tmp = int + "";
    tmp = tmp.replace(".", ",").replace(/([0-9]{3})$/g, ".$1");
    if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    return tmp;
  }

  const showModal = () => {
    setVisible(true);
  };

  async function Budget() {
    try {
      setLoading(true);
      const response = await SalesForecastService.getBudget(
        companyId,
        Cliente,
        Category,
        level4
      );
      setYear(response.data[0].base_line_parameter);
      setDataTotal(response.total);
      const formattedTasks = response.data.map((rawTask: any) => {
        return {
          price_base_line: rawTask.price_base_line,
          price_year1: rawTask.price_year1,
          id: rawTask.id,
          product: rawTask.produto,
          total_base_line: rawTask.total_base_line,
          total_year1: rawTask.total_year1,
          variation: rawTask.variation
            ? parseFloat(rawTask.variation).toFixed(2)
            : "0",
          volume_base_line: rawTask.volume_base_line,
          volume_year1: rawTask.volume_year1,
          base_line_parameter: rawTask.base_line_parameter,
          key: rawTask.id,
        };
      });
      const formattedTasks2 = response.data.map((rawTask: any) => {
        return {
          price_base_line: rawTask.price_base_line,
          price_year1: rawTask.price_year1,
          id: rawTask.id,
          product: rawTask.produto,
          total_base_line: rawTask.total_base_line,
          total_year1: rawTask.total_year1,
          variation: rawTask.variation
            ? parseFloat(rawTask.variation).toFixed(2)
            : "0",
          volume_base_line: rawTask.volume_base_line,
          volume_year1: rawTask.volume_year1,
          base_line_parameter: rawTask.base_line_parameter,
          key: rawTask.id,
        };
      });
      setListTasks(
        formattedTasks.sort(function (a: any, b: any) {
          if (a.customer_id < b.customer_id) {
            return -1;
          } else {
            return 1;
          }
        }) as []
      );

      setResultData(formattedTasks2);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function PostBudget(data: any) {
    if (data.value && data.value.toString() !== data.initialValue.toString()) {
      try {
        setLoading(true);
        let body = {
          customer_group_id: Cliente,
          product_group_id: data.records.id,
          level4_id: level4,
          volume: data.value,
        };
        await SalesForecastService.PostBudget(companyId, body);
      } catch (error) {
        console.log(error);
        message.error("Erro ao salvar", error);
      } finally {
        Budget();
      }
    }
  }

  function renderInput(record: number, records: object, field: string) {
    return (
      <InputNumberTable
        onSave={PostBudget}
        disbled={
          field === "volume_base_line" || field === "variation" ? true : disbled
        }
        records={records}
        name={field}
        initialValue={record}
      />
    );
  }

  function renderInputV(record: string, records: object, field: string) {
    return (
      <InputNumberTableBr
        onSave={PostBudget}
        disbled={field === "volume_base_line"}
        records={records}
        name={field}
        initialValue={record}
      />
    );
  }

  useEffect(() => {
    filterTable();

    async function filterTable() {
      if (!search) {
        setResultData(listTasks);
      } else {
        let newList = await listTasks.filter((task: any) => {
          return task.product.toLowerCase().includes(search.toLowerCase());
        });
        setResultData(newList);
      }
    }
  }, [search]);

  useEffect(() => {
    updateTotal();
  }, [resultData]);

  async function onFinish(obj: {
    level0: number;
    level1: number;
    level2: number;
    level3: number;
    level4: number;
  }) {
    setListTasks([]);
    dispatch(setFiltercommercialStucture(obj));
    setLevel4(obj.level4);
    BugetLevel4(obj.level4);
    SalesForecastState(obj.level4);
    setFilter(false);
    setQuery(obj);
  }

  function start() {
    setModalVisible(false);
    Budget();
  }

  async function handleCancel() {
    setModalVisible(false);
    setVisible(false);
    setVolume(undefined);
    setProduct_group_id(undefined);
    setProduto(undefined);
  }

  const handleOk = () => {
    setModalText("Alterando a Previsão de Vendas");
    setConfirmLoading(true);
    PutBudgetStatus();
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
      if (status === "F") {
        message.success("Reaberto");
      } else {
        message.success("Finalizado");
      }
    }, 1000);
  };

  async function PutBudgetStatus() {
    try {
      const body = {
        status: newStatus === "Reabrir" ? "A" : "F",
        level4_id: level4,
      };
      await SalesForecastService.putBudgetStatus(companyId, body);
    } catch (err) {
      message.error(err.response.data.message);
    } finally {
      SalesForecastState(level4);
    }
  }

  useEffect(() => {
    if (Cliente) {
      Budget();
    }
  }, [Cliente]);

  useEffect(() => {
    if (Category != undefined) {
      setListTasks([]);
      BugetLevel4Category(Query.level4);
      SalesForecastState(Query.level4);
      setFilter(false);
      setVisible(false);
      setVolume(undefined);
      setProduct_group_id(undefined);
      setProduto(undefined);
      setDataTotal(undefined);
    }
  }, [Category]);

  async function onCategory(value: any) {
    setCategory(value);
    setListTasks([]);
    setLevel4(Query.level4);
    BugetLevel4(Query.level4);
    SalesForecastState(Query.level4);
    setFilter(false);
    setVisible(false);
    setVolume(undefined);
    setProduct_group_id(undefined);
    setProduto(undefined);
    setDataTotal(undefined);
  }

  const updateTotal = () => {
    if (resultData) {
      const totalVolumeBaseLine = resultData.reduce(
        (sum: number, item: any) => sum + item.volume_base_line,
        0
      );
      const totalVolumeYear1 = resultData.reduce(
        (sum: number, item: any) => sum + item.volume_year1,
        0
      );
      const totalVariation = resultData.reduce(
        (sum: number, item: any) => sum + parseFloat(item.variation),
        0
      );

      setDataTotal({
        volume_base_line: totalVolumeBaseLine,
        volume_year1: totalVolumeYear1,
        variacao: totalVariation,
      });
    }
  };

  var actions = [
    {
      children: newStatus,
      className: level4,
    },
  ];
  const { Title } = Typography;
  return (
    <Container>
      
      <Header background={undefined}>
        <BreadcrumbAntd separator=">">
          <BreadcrumbAntd.Item>
            <LeftIcon onClick={() => navigate(-1)} src={leftIcon} />{" "}
            {"Voltar"}
          </BreadcrumbAntd.Item>
        </BreadcrumbAntd>
        
        <Actions>
          {actions?.map((item, index) => (
            <>
              {status !== "" ? (
                <Button
                  key={index}
                  onClick={showModal}
                  type={newStatus === "Finalizar" ? "primary" : "default"}
                  {...item}
                />
              ) : (
                <></>
              )}
            </>
          ))}
        </Actions>
        <Modal
          title={status === "F" ? "Reabrir" : "Finalizar"}
          open={visible}
          okText={"Sim"}
          cancelText={"Não"}
          onOk={handleOk}
          confirmLoading={confirmLoading}
          okButtonProps={{
            disabled: false,
          }}
          onCancel={handleCancel}
        >
          <p>{modalText}</p>
        </Modal>
      </Header>
      <Content>
      <Row align="middle" style={{ marginBottom: 16 }}>
        {/* Breadcrumb on the left */}
      

        {/* Title in the center */}
        <Col  style={{ textAlign: "center", flexGrow: 1, background:"none" }}>
          <Title level={4} style={{ paddingBottom:"5px"  }}>Previsão de Vendas</Title>
        </Col>
      </Row>
        <Row gutter={[16, 16]}>
          {filter && (
            <Col span={4}>
              <Card bordered={false}>
                <Form
                  ref={formRef}
                  onFinish={onFinish}
                  initialValues={commercialStructure}
                >
                  <CommercialStructure formRef={formRef} level={4} />
                  <Button htmlType="submit" type="primary" block>
                    Buscar
                  </Button>
                </Form>
              </Card>
            </Col>
          )}
          <Col span={filter ? 20 : 24}>
            <Card bordered={false}>
              <Row justify="space-between" align="middle" gutter={[16, 16]}>
                <Col>
                  <Buttons>
                    {filter ? (
                      <Tooltip title="Fechar Menu">
                      <FiX
                        color="#00CC66"
                        size={20}
                        onClick={() => setFilter(!filter)}
                      /></Tooltip>
                    ) : (
                      <Tooltip title="Abrir Menu">
                      <FiMenu
                        color="#00CC66"
                        size={20}
                        onClick={() => setFilter(!filter)}
                      /></Tooltip>
                    )}
                    {data !== undefined ? (
                      <Select
                        style={{ width: 250, margin: "10px 0" }}
                        size="large"
                        popupMatchSelectWidth={false}
                        showSearch
                        placeholder="Selecionar Categoria"
                        optionFilterProp="children"
                        //onChange={e=>onCategory(e.target.value)}
                        onChange={(value) => setCategory(value)}
                        //onChange={(value) => onCategory(value)}
                        value={Category}
                      >
                        <Option value={0}>{"Todas as Opções"}</Option>
                        {selectCategory?.map((initiative: any) => (
                          <Option value={initiative.id}>
                            {initiative.name}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <></>
                    )}
                    {data !== undefined ? (
                      <Select
                        style={{ width: 250, margin: "10px 0" }}
                        size="large"
                        popupMatchSelectWidth={false}
                        showSearch
                        placeholder="Selecionar Cliente"
                        optionFilterProp="children"
                        onChange={(value) => setCliente(value)}
                        value={Cliente}
                      >
                        {data?.map((initiative: any) => (
                          <Option value={initiative.id}>
                            {initiative.group_name}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <></>
                    )}
                  </Buttons>
                </Col>
                <Col span={6}>
                  <Input
                    placeholder="Pesquisar"
                    allowClear
                    prefix={<FiSearch color="#00CC66" />}
                    suffix={<FiFilter color="#00CC66" />}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Col>
                <Col span={24}>
                  <Table
                    size="small"
                    rowKey="key"
                    dataSource={resultData}
                    summary={() => {
                      return (
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0}>
                            <div>
                              <p style={{ fontWeight: "bold" }}>Total Geral</p>
                            </div>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={1} align="right">
                            <div>
                              <p style={{ fontWeight: "bold" }}>
                                {dataTotal
                                  ? dataTotal?.volume_base_line.toLocaleString()
                                  : ""}
                              </p>
                            </div>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={2} align="right">
                            <div>
                              <p style={{ fontWeight: "bold" }}>
                                {dataTotal?.volume_year1.toLocaleString()}
                              </p>
                            </div>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={3} align="left">
                            <div>
                              <p style={{ fontWeight: "bold" }}>
                                {dataTotal?.variacao
                                  ? parseFloat(dataTotal?.variacao).toFixed(2)
                                  : dataTotal
                                  ? "0"
                                  : ""}
                              </p>
                            </div>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      );
                    }}
                  >
                    <Column
                      title="Produto"
                      dataIndex="product"
                      sorter={compareLabelsTables("product")}
                      width={100}
                    />
                    <Column
                      title={`Base Line ${year !== undefined ? year : ""}`}
                      dataIndex="volume_base_line"
                      align="left"
                      sorter={
                        (a: any, b: any) =>
                          a.volume_base_line - b.volume_base_line
                        // a.volume_base_line.localeCompare(b.volume_base_line)
                      }
                      width={100}
                      render={
                        (record: number, records: object) =>
                          //record.toLocaleString()
                          //(record))
                          //  (record.toLocaleString())
                          renderInputV(
                            record.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }),
                            records,
                            "volume_base_line"
                          )
                        // formatReal(parseFloat(renderInputV(formatReal(record), records, "volume_base_line")))
                      }
                    />
                    <Column
                      title="Previsão de Vendas (Kg/Lt/Sc)"
                      dataIndex="volume_year1"
                      sorter={(a: any, b: any) =>
                        a.volume_year1 - b.volume_year1
                      }
                      width={70}
                      render={(record: number, records: object) =>
                        renderInput(record, records, "volume_year1")
                      }
                    />
                    <Column
                      title="Variação (%)"
                      dataIndex="variation"
                      sorter={(a: any, b: any) => a.variation - b.variation}
                      width={100}
                    />
                  </Table>
                </Col>
              </Row>
              <Modal
                open={modalVisible}
                onOk={start}
                onCancel={handleCancel}
                okText={"Salvar"}
                // width={1000}
                //  okButtonProps={{ disabled: disableSave() }}
              >
                <Card bordered={false}>
                  <Row gutter={[16, 16]} justify="space-between" align="middle">
                    <Col span={18}>
                      <Typography.Title level={4}>
                        {"Editar Previsão de Vendas"}
                      </Typography.Title>
                      <Input
                        allowClear
                        placeholder="Produto"
                        disabled
                        onChange={(e) => setProduto(e.target.value)}
                        value={produto}
                      />
                      <Input
                        allowClear
                        placeholder="Volume"
                        type="number"
                        style={{ marginTop: "10px" }}
                        onChange={(e) => setVolume(e.target.value)}
                        value={volume}
                      />
                    </Col>
                  </Row>
                </Card>
              </Modal>
            </Card>
          </Col>
        </Row>
      </Content>
    </Container>
  );
}
