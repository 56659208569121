import React from "react";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";
const container = document.getElementById("root");

if (container) {
  const root = createRoot(container);
  root.render(<App />);
} else {
  console.error("Root container not found");
}

reportWebVitals();
