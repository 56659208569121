import { Breadcrumb } from "../../../components/Breadcrumb";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Table,
  Typography,
  notification,
  Tooltip,
} from "antd";
import { createRef, RefObject, useCallback, useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import {
  useAppDispatch,
  useAppSelector,
  useButtonActiveYears,
  useParameterName,
} from "../../../hooks";
import { BusinessType } from "../../../components/Filter/BusinessType";
import TopDownProductService from "../../../services/SalesPlanning/TopDownProduct";
import { Buttons, Header } from "./styles";
import { InputNumberTable } from "../../../components/TableDinamic/InputNumber";
import { FiFilter, FiMenu, FiSearch, FiX } from "react-icons/fi";
import { CommercialStructure } from "../../../components/Filter/CommercialStructure";
import { clearCommercialStructure } from "../../../entities/Reducer";
import { resetFilter, setFiltercommercialStucture } from "../Reducer";
import CompanyCommercialStructureService from "../../../services/Company/CommercialStructure";
import type { FormInstance } from "antd/es/form";
import { formatBR } from "../../../utils";

interface TotalProps {
  cagr: number;
  total_base_line: number;
  total_year1: number;
  total_year2: number;
  total_year3: number;
  total_year4: number;
  total_year5: number;
  volume_base_line: number;
  volume_year1: number;
  volume_year2: number;
  volume_year3: number;
  volume_year4: number;
  volume_year5: number;
}

const { Column } = Table;
export function TopDownProduct() {
  const dispatch = useAppDispatch();
  const [isSaveCompleted, setIsSaveCompleted] = useState(false);
  const parameters = useAppSelector((state) => state.entities.parameter);
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const [status, setStatus] = useState<string>();
  // document.getElementsByClassName('ant-breadcrumb-link')[1].style.display = 'none'
  if (status === "F") {
    var newStatus = "Reabrir";
    var disbled = true;
  } else {
    var newStatus = "Finalizar";
    var disbled = false;
  }

  const [level1, setLevel1] = useState<any>();
  const businessTypeId = useAppSelector(
    (state) => state.salesPlanning.filter.businessType
  );

  const commercialStructure = useAppSelector(
    (state) => state.salesPlanning.filter
  );
  const years = useParameterName("QTD_YEARS");
 const formRef = createRef<FormInstance>() as RefObject<FormInstance<any>>;
  const { businessType } = useAppSelector(
    (state) => state.salesPlanning.filter
  );
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(true);
  const [total, setTotal] = useState<TotalProps | undefined>();
  const [year0, setYear0] = useState<string | undefined>("");
  const [year0Active, setYear0Active] = useState<boolean>(true);
  const [year1, setYear1] = useState<string | undefined>("");
  const [year1Active, setYear1Active] = useState<boolean>(
    useButtonActiveYears(2)
  );
  const [year2, setYear2] = useState<string | undefined>("");
  const [year2Active, setYear2Active] = useState<boolean>(
    useButtonActiveYears(3)
  );
  const [year3, setYear3] = useState<string | undefined>("");
  const [year3Active, setYear3Active] = useState<boolean>(
    useButtonActiveYears(4)
  );
  const [year4, setYear4] = useState<string | undefined>("");
  const [year4Active, setYear4Active] = useState<boolean>(
    useButtonActiveYears(5)
  );
  const [year5, setYear5] = useState<string | undefined>("");
  const [year5Active, setYear5Active] = useState<boolean>(
    useButtonActiveYears(6)
  );
  const Icon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    setYear0(
      parameters.find((value) => value.name === "YEAR_BASE_LINE_LABEL")?.value
    );
    setYear1(parameters.find((value) => value.name === "YEAR1_LABEL")?.value);
    setYear2(parameters.find((value) => value.name === "YEAR2_LABEL")?.value);
    setYear3(parameters.find((value) => value.name === "YEAR3_LABEL")?.value);
    setYear4(parameters.find((value) => value.name === "YEAR4_LABEL")?.value);
    setYear5(parameters.find((value) => value.name === "YEAR5_LABEL")?.value);
  }, [parameters]);

  useEffect(() => {
    return function clean() {
      dispatch(resetFilter());
      dispatch(clearCommercialStructure());
    };
  }, [dispatch]);

  const loadTableProduct = useCallback(
    async (id: number, businessTypeIds: number, showLoading: boolean) => {
      try {
        const response = await TopDownProductService.getTableProduct(
          companyId,
          id,
          businessTypeIds
        );
        setData(response.records);
        setTotal(response.total);
        setLoading(showLoading);
      } catch (err) {
        message.error(err.response.data.message);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  async function onSave(data: any) {
    if (data.value && data.value.toString() !== data.initialValue.toString()) {
      const body = {
        level1_id: commercialStructure.level1,
        product_id: data.records.product_id,
        field_changed: data.name,
        new_value: data.value,
      };
      await TopDownProductService.saveTableProduct(companyId, body);
      setIsSaveCompleted(true);
      //  loadTableProduct(2, 0, false);
    }
  }

  function renderInput(record: number, records: object, field: string) {
    return (
      <InputNumberTable
        onSave={onSave}
        disbled={disbled}
        records={records}
        name={field}
        initialValue={record}
      />
    );
  }

  useEffect(() => {
    if (isSaveCompleted) {
      const obj = {
        level0: commercialStructure.level0,
        businessType: commercialStructure.businessType,
        level1: commercialStructure.level1,
        level2: commercialStructure.level2,
      };
      onFinish(obj);
      notification.success({
        message: "Salvo com Sucesso",
        description: "Aguarde alguns segundos e irá atualizar",
        duration: 3, // Auto close after 3 seconds
      });
      setIsSaveCompleted(false); // Reset the save status
    }
  }, [isSaveCompleted, commercialStructure, dispatch]);

  async function onFinish(obj: {
    level0: number;
    businessType: number;
    level1: number;
    level2: number;
  }) {
    dispatch(setFiltercommercialStucture(obj));
    loadTableProduct(obj.level1, obj.businessType, true);
    setLevel1([obj.level0, obj.level1]);
    topdownProduct([obj.level0, obj.level1]);
    setFilter(false);
  }
  async function topdownProduct(obj: any) {
    try {
      const response =
        await CompanyCommercialStructureService.getAllCommercialStrucuteLevel1(
          companyId,
          obj?.toString().split(",").shift()
        );
      for (let i = 0; i < response.length; i++) {
        if (response[i].level1_id == obj?.toString().split(",").pop()) {
          getTopdownProductStatus(response[i].level1_external_name);
        }
      }
    } catch (err) {
      message.error(err.response.data.message);
    }
  }
  async function getTopdownProductStatus(external_n: any) {
    try {
      const response = await TopDownProductService.getTableProductStatus(
        companyId,
        external_n
      );
      setStatus(undefined);
      setStatus(response.pgv_topdown_product);
    } catch (err) {
      message.error(err.response.data.message);
    } finally {
    }
  }
  async function onChange(search: string) {
    try {
      setLoading(true);
      const response = await TopDownProductService.searchTableProduct(
        companyId,
        commercialStructure.level1,
        search,
        businessType
      );
      setData(response.records);
      setTotal(response.total);
    } catch (err) {
      message.error(err.response.data.message);
    } finally {
      setLoading(false);
    }
  }
  const { Title } = Typography;
  return (
    <Breadcrumb
      path="Projeções Produtos"
      actions={[
        {
          children: newStatus,
          type: newStatus === "Finalizar" ? "primary" : "default",
          className: level1,
        },
      ]}
    >
      <>
        <Row align="middle" style={{ marginBottom: 16 }}>
          {/* Breadcrumb on the left */}

          {/* Title in the center */}
          <Col style={{ textAlign: "center", flexGrow: 1, background: "none" }}>
            <Title level={4} style={{ paddingBottom: "5px" }}>
              Projeções Produtos
            </Title>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Row gutter={16}>
              {filter && (
                <Col
                  className="animate__backInRight"
                  xl={4}
                  xs={24}
                  sm={12}
                  style={{ marginBottom: 16 }}
                >
                  <Card bordered={false}>
                    <Form
                      initialValues={commercialStructure}
                      onFinish={onFinish}
                      ref={formRef}
                    >
                      <CommercialStructure formRef={formRef} level={1} />
                      <BusinessType />
                      <Button htmlType="submit" type="primary" block>
                        Buscar
                      </Button>
                    </Form>
                  </Card>
                </Col>
              )}
              <Col xl={filter ? 20 : 24} xs={24}>
                <Card bordered={false}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Header>
                        <Buttons>
                          {filter ? (
                            <Tooltip title="Fechar Menu">
                              <FiX
                                color="#00CC66"
                                size={20}
                                onClick={() => setFilter(!filter)}
                                style={{ marginRight: 16 }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Abrir Menu">
                              <FiMenu
                                color="#00CC66"
                                size={20}
                                onClick={() => setFilter(!filter)}
                                style={{ marginRight: 16 }}
                              />
                            </Tooltip>
                          )}
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              marginBottom: 16,
                            }}
                          >
                            <Button
                              disabled
                              onClick={() => setYear0Active(!year0Active)}
                              type={year0Active ? "primary" : "default"}
                              style={{
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                                display:
                                  years?.value && parseInt(years.value) >= 1
                                    ? "inline"
                                    : "none",
                              }}
                            >
                              {year0}
                            </Button>
                            <Button
                              onClick={() => setYear1Active(!year1Active)}
                              type={year1Active ? "primary" : "default"}
                              style={{
                                borderRadius: 0,
                                display:
                                  years?.value && parseInt(years.value) >= 2
                                    ? "inline"
                                    : "none",
                              }}
                            >
                              {year1}
                            </Button>
                            <Button
                              onClick={() => setYear2Active(!year2Active)}
                              type={year2Active ? "primary" : "default"}
                              style={{
                                borderRadius: 0,
                                display:
                                  years?.value && parseInt(years.value) >= 3
                                    ? "inline"
                                    : "none",
                              }}
                            >
                              {year2}
                            </Button>
                            <Button
                              onClick={() => setYear3Active(!year3Active)}
                              type={year3Active ? "primary" : "default"}
                              style={{
                                borderRadius: 0,
                                display:
                                  years?.value && parseInt(years.value) >= 4
                                    ? "inline"
                                    : "none",
                              }}
                            >
                              {year3}
                            </Button>
                            <Button
                              onClick={() => setYear4Active(!year4Active)}
                              type={year4Active ? "primary" : "default"}
                              style={{
                                borderRadius: 0,
                                display:
                                  years?.value && parseInt(years.value) >= 5
                                    ? "inline"
                                    : "none",
                              }}
                            >
                              {year4}
                            </Button>
                            <Button
                              onClick={() => setYear5Active(!year5Active)}
                              type={year5Active ? "primary" : "default"}
                              style={{
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                display:
                                  years?.value && parseInt(years.value) > 5
                                    ? "inline"
                                    : "none",
                              }}
                            >
                              {year5}
                            </Button>
                          </div>
                        </Buttons>
                        <Input
                          allowClear
                          onChange={(e) => onChange(e.target.value)}
                          placeholder="Pesquisar"
                          prefix={<FiSearch color="#00CC66" />}
                          suffix={<FiFilter color="#00CC66" />}
                          style={{ width: 350 }}
                          disabled={!commercialStructure.level1}
                        />
                      </Header>
                    </Col>

                    <Col span={24}>
                      <Table
                        rowKey="product_id"
                        loading={{ indicator: Icon, spinning: loading }}
                        scroll={{ y: 430, x: "100vw" }}
                        size="small"
                        pagination={false}
                        dataSource={data}
                        summary={() => {
                          return (
                            <Table.Summary fixed>
                              <Table.Summary.Row
                                style={{ background: "#f5f5f5" }}
                              >
                                <Table.Summary.Cell index={0} colSpan={4}>
                                  <b>Total</b>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} align="right">
                                  <Typography.Text>
                                    <b>
                                      {total?.volume_base_line &&
                                        formatBR(total?.volume_base_line)}
                                    </b>
                                  </Typography.Text>
                                </Table.Summary.Cell>
                                {year1Active && (
                                  <Table.Summary.Cell index={2} align="right">
                                    <Typography.Text>
                                      <b>
                                        {total?.volume_year1 &&
                                          formatBR(total?.volume_year1)}
                                      </b>
                                    </Typography.Text>
                                  </Table.Summary.Cell>
                                )}
                                {year2Active && (
                                  <Table.Summary.Cell index={3} align="right">
                                    <Typography.Text>
                                      <b>
                                        {total?.volume_year2 &&
                                          formatBR(total?.volume_year2)}
                                      </b>
                                    </Typography.Text>
                                  </Table.Summary.Cell>
                                )}
                                {year3Active && (
                                  <Table.Summary.Cell index={4} align="right">
                                    <Typography.Text>
                                      <b>
                                        {total?.volume_year3 &&
                                          formatBR(total?.volume_year3)}
                                      </b>
                                    </Typography.Text>
                                  </Table.Summary.Cell>
                                )}
                                {year4Active && (
                                  <Table.Summary.Cell index={5} align="right">
                                    <Typography.Text>
                                      <b>
                                        {" "}
                                        {total?.volume_year4 &&
                                          formatBR(total?.volume_year4)}
                                      </b>
                                    </Typography.Text>
                                  </Table.Summary.Cell>
                                )}
                                {year5Active && (
                                  <Table.Summary.Cell index={6} align="right">
                                    <Typography.Text>
                                      <b>
                                        {total?.volume_year5 &&
                                          formatBR(total?.volume_year5)}
                                      </b>
                                    </Typography.Text>
                                  </Table.Summary.Cell>
                                )}
                                <Table.Summary.Cell index={1} align="right">
                                  <Typography.Text>
                                    <b>
                                      {total?.total_base_line &&
                                        formatBR(total?.total_base_line)}
                                    </b>
                                  </Typography.Text>
                                </Table.Summary.Cell>
                                {year1Active && (
                                  <Table.Summary.Cell index={8} align="right">
                                    <Typography.Text>
                                      <b>
                                        {total?.total_year1 &&
                                          formatBR(total?.total_year1)}
                                      </b>
                                    </Typography.Text>
                                  </Table.Summary.Cell>
                                )}
                                {year2Active && (
                                  <Table.Summary.Cell index={9} align="right">
                                    <Typography.Text>
                                      <b>
                                        {total?.total_year2 &&
                                          formatBR(total?.total_year2)}
                                      </b>
                                    </Typography.Text>
                                  </Table.Summary.Cell>
                                )}
                                {year3Active && (
                                  <Table.Summary.Cell index={10} align="right">
                                    <Typography.Text>
                                      <b>
                                        {total?.total_year3 &&
                                          formatBR(total?.total_year3)}
                                      </b>
                                    </Typography.Text>
                                  </Table.Summary.Cell>
                                )}
                                {year4Active && (
                                  <Table.Summary.Cell index={11} align="right">
                                    <Typography.Text>
                                      <b>
                                        {total?.total_year4 &&
                                          formatBR(total?.total_year4)}
                                      </b>
                                    </Typography.Text>
                                  </Table.Summary.Cell>
                                )}
                                {year5Active && (
                                  <Table.Summary.Cell index={12} align="right">
                                    <Typography.Text>
                                      <b>
                                        {total?.total_year5 &&
                                          formatBR(total?.total_year5)}
                                      </b>
                                    </Typography.Text>
                                  </Table.Summary.Cell>
                                )}
                              </Table.Summary.Row>
                            </Table.Summary>
                          );
                        }}
                      >
                        <Column
                          title="Segmento"
                          dataIndex="segment_name"
                          width={100}
                          sorter={(a: any, b: any) =>
                            a.segment_name.localeCompare(b.segment_name)
                          }
                        />
                        <Column
                          title="Produto"
                          dataIndex="product_name"
                          width={100}
                          sorter={(a: any, b: any) =>
                            a.product_name.localeCompare(b.product_name)
                          }
                        />
                        <Column title="SKU" dataIndex="sku_name" width={100} />
                        <Column
                          title="Tipo de Produto"
                          dataIndex="type_name"
                          width={100}
                          sorter={(a: any, b: any) =>
                            a.type_name.localeCompare(b.type_name)
                          }
                        />
                        {year0Active ? (
                          <Column
                            title={`Vol. (Kg/Lt) ${year0}`}
                            align="right"
                            dataIndex="volume_base_line"
                            width={120}
                            sorter={(a: any, b: any) =>
                              a.volume_base_line - b.volume_base_line
                            }
                            render={(record: number) => formatBR(record)}
                          />
                        ) : (
                          <></>
                        )}
                        {year1Active ? (
                          <Column
                            className="color"
                            title={`Vol. (Kg/Lt) ${year1}`}
                            align="right"
                            dataIndex="volume_year1"
                            width={120}
                            sorter={(a: any, b: any) =>
                              a.volume_year1 - b.volume_year1
                            }
                            render={(record: number, records: object) =>
                              renderInput(record, records, "volume_year1")
                            }
                          />
                        ) : (
                          <></>
                        )}
                        {year2Active ? (
                          <Column
                            title={`Vol. (Kg/Lt) ${year2}`}
                            align="right"
                            dataIndex="volume_year2"
                            width={120}
                            sorter={(a: any, b: any) =>
                              a.volume_year2 - b.volume_year2
                            }
                            render={(record: number, records: object) =>
                              renderInput(record, records, "volume_year2")
                            }
                          />
                        ) : (
                          <></>
                        )}
                        {year3Active ? (
                          <Column
                            className="color"
                            title={`Vol. (Kg/Lt) ${year3}`}
                            align="right"
                            dataIndex="volume_year3"
                            width={120}
                            sorter={(a: any, b: any) =>
                              a.volume_year3 - b.volume_year3
                            }
                            render={(record: number, records: object) =>
                              renderInput(record, records, "volume_year3")
                            }
                          />
                        ) : (
                          <></>
                        )}
                        {year4Active ? (
                          <Column
                            title={`Vol. (Kg/Lt) ${year4}`}
                            align="right"
                            dataIndex="volume_year4"
                            width={120}
                            sorter={(a: any, b: any) =>
                              a.volume_year4 - b.volume_year4
                            }
                            render={(record: number, records: object) =>
                              renderInput(record, records, "volume_year4")
                            }
                          />
                        ) : (
                          <></>
                        )}
                        {year5Active ? (
                          <Column
                            className="color"
                            title={`Vol. (Kg/Lt) ${year5}`}
                            align="right"
                            dataIndex="volume_year5"
                            width={120}
                            sorter={(a: any, b: any) =>
                              a.volume_year5 - b.volume_year5
                            }
                            render={(record: number, records: object) =>
                              renderInput(record, records, "volume_year5")
                            }
                          />
                        ) : (
                          <></>
                        )}
                        {year0Active ? (
                          <Column
                            title={`Total (R$) ${year0}`}
                            align="right"
                            dataIndex="total_base_line"
                            width={120}
                            sorter={(a: any, b: any) =>
                              a.total_base_line - b.total_base_line
                            }
                            render={(record: number) => formatBR(record)}
                          />
                        ) : (
                          <></>
                        )}
                        {year1Active ? (
                          <Column
                            className="color"
                            title={`Total (R$) ${year1}`}
                            align="right"
                            dataIndex="total_year1"
                            width={120}
                            sorter={(a: any, b: any) =>
                              a.total_year1 - b.total_year1
                            }
                            render={(record: number) => formatBR(record)}
                          />
                        ) : (
                          <></>
                        )}
                        {year2Active ? (
                          <Column
                            title={`Total (R$) ${year2}`}
                            align="right"
                            dataIndex="total_year2"
                            width={120}
                            sorter={(a: any, b: any) =>
                              a.total_year2 - b.total_year2
                            }
                            render={(record: number) => formatBR(record)}
                          />
                        ) : (
                          <></>
                        )}
                        {year3Active ? (
                          <Column
                            className="color"
                            title={`Total (R$) ${year3}`}
                            align="right"
                            dataIndex="total_year3"
                            width={120}
                            sorter={(a: any, b: any) =>
                              a.total_year3 - b.total_year3
                            }
                            render={(record: number) => formatBR(record)}
                          />
                        ) : (
                          <></>
                        )}
                        {year4Active ? (
                          <Column
                            title={`Total (R$) ${year4}`}
                            align="right"
                            dataIndex="total_year4"
                            width={120}
                            sorter={(a: any, b: any) =>
                              a.total_year4 - b.total_year4
                            }
                            render={(record: number) => formatBR(record)}
                          />
                        ) : (
                          <></>
                        )}
                        {year5Active ? (
                          <Column
                            className="color"
                            title={`Total (R$) ${year5}`}
                            align="right"
                            dataIndex="total_year5"
                            width={120}
                            sorter={(a: any, b: any) =>
                              a.total_year5 - b.total_year5
                            }
                            render={(record: number) => formatBR(record)}
                          />
                        ) : (
                          <></>
                        )}
                      </Table>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    </Breadcrumb>
  );
}
