/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-eval */
import { Button, Card, Col, ConfigProvider, message, Row, Drawer } from "antd";
import { useAppSelector } from "../../hooks";
import { useState, useEffect } from "react";
import PowerBi_all from "../../services/Global/PowerBi";
import { BottomUPDashboardPGV } from "./BottomUp_PGV/index";
import { BottomUPDashboardPGC } from "./BottomUp_PGC/index";
import { ActionPlanDashboard } from "./PGV/ActionPlan/index";
import { ActionPlanDashboardPGC } from "./PGC/ActionPlan";
import "./index.css";
import { Breadcrumb } from "../../components/Breadcrumb";
import Maps from "./Maps";
import { FiMenu } from "react-icons/fi";

export function PowerBi_Dashboard() {
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const [data, setData] = useState<any>([]);
  const [url, setUrl] = useState<any>(undefined);
  const [Menu, setMenu] = useState<boolean>(true);
  const [selected, setSelected] = useState<string | null>(null);
  useEffect(() => {
    if (companyId) loadLinks();
  }, [companyId]);

  async function loadLinks() {
    const response = await PowerBi_all.getPowerBi(companyId);
    setData(response);
  }
  function abiriRelaroio_Iframe(url: any) {
    selectDashboard("");
    if (url.length > 50) {
      setMenu(false);
      setUrl(url);
    } else {
      setUrl(undefined);
      message.warning("Relatório está indisponível no momento!");
    }
  }

  function selectDashboard(Name: string) {
    setMenu(false);
    setSelected(Name);
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Card: {
            headerBg: "#23d978",
          },
          Table: {
            headerBg: "#23d978",
            headerColor: "#000",
            footerBg: "#23d978",
          },
          Drawer: {
            colorBgElevated: "#FFFFFFd9",
          },
        },
      }}
    >
      <Breadcrumb separator="" path="Dashboard" child={["Dashboard"]}>
        <Row>
          <div>
            <Button
              type="primary"
              className="menu-toggle-card"
              style={{ position: "absolute", top: 65, left: 45, zIndex: 999 }}
              onClick={() => setMenu(!Menu)}
            >
              <FiMenu color="#FFF" size={40} />
            </Button>
          </div>

          <Drawer
            title={<span>Menu DashBoard</span>}
            closable
            destroyOnClose
            placement="left"
            onClose={() => setMenu(!Menu)}
            open={Menu}
            key={"left"}
          >
            <Col span={24}>
              {data.length > 0 ? (
                <Card bordered={true} className="card-container">
                  {data.map(
                    (item: { id: number; name: string; value: string }) => (
                      <Button
                        key={item.id}
                        type="primary"
                        onClick={() => abiriRelaroio_Iframe(item.value)}
                        className="button-style"
                      >
                        {item.name}
                      </Button>
                    )
                  )}
                </Card>
              ) : (
                <div>
                  <div style={{ backgroundColor: "#FFFFFF00", margin: 20 }}>
                    <Button
                      key={2}
                      type="primary"
                      className="button-style"
                      onClick={() => selectDashboard("BOTTOM_UP_PGV")}
                    >
                      <span className="span">
                        Projeções de Vendas Filial e Produto
                      </span>
                    </Button>
                  </div>
                  <div style={{ backgroundColor: "#FFFFFF00", margin: 20 }}>
                    <Button
                      key={2}
                      type="primary"
                      className="button-style"
                      onClick={() => selectDashboard("BOTTOM_UP_PGC")}
                    >
                      <span className="span">
                        Projeções de Cliente Filial e Produto
                      </span>
                    </Button>
                  </div>

                  <div style={{ backgroundColor: "#FFFFFF00", margin: 20 }}>
                    <Button
                      key={3}
                      type="primary"
                      className="button-style"
                      onClick={() => selectDashboard("PLANO_DE_ACAO_PGV")}
                    >
                      <span className="span">Plano de Ações - PGV</span>
                    </Button>
                  </div>

                  <div style={{ backgroundColor: "#FFFFFF00", margin: 20 }}>
                    <Button
                      key={4}
                      type="primary"
                      className="button-style"
                      onClick={() => selectDashboard("PLANO_DE_ACAO_PGC")}
                    >
                      <span className="span">Plano de Ações - PGC</span>
                    </Button>
                  </div>
                  {/*<div style={{ backgroundColor: "#FFFFFF00", margin: 20 }}>
                    <Button
                      key={4}
                      type="primary"
                      className="button-style"
                      onClick={() => selectDashboard("MAPS")}
                    >
                      <span className="span">MAPS</span>
                    </Button>
                  </div>*/}
                </div>
              )}
            </Col>
          </Drawer>

          {url !== undefined ? (
            <Col span={24}>
              <>
                <div className="iframe-container">
                  <iframe
                    title="% Faturamento por Tipo Acesso"
                    className="iframe-style"
                    width="100%"
                    allowFullScreen={true}
                    src={url}
                  />
                </div>
              </>
            </Col>
          ) : selected === "PLANO_DE_ACAO_PGV" ? (
            <ActionPlanDashboard />
          ) : selected === "PLANO_DE_ACAO_PGC" ? (
            <ActionPlanDashboardPGC />
          ) : selected === "BOTTOM_UP_PGV" ? (
            <BottomUPDashboardPGV />
          ) : selected === "BOTTOM_UP_PGC" ? (
            <BottomUPDashboardPGC />
          ) : selected === "MAPS" ? (
            <Maps />
          ) : null}
        </Row>
      </Breadcrumb>
    </ConfigProvider>
  );
}
