import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { createRef, RefObject, useCallback, useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import {
  FiEdit2,
  FiFilter,
  FiMenu,
  FiPlus,
  FiSearch,
  FiTrash,
  FiX,
} from "react-icons/fi";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { Buttons, StyledDatePicker } from "./styles";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import ActionPlanService from "../../../services/SalesPlanning/ActionPlan";
import { Actions } from "../../Setup/Parameter/styles";
import moment from "moment";
import { CommercialStructure } from "../../../components/Filter/CommercialStructure";
import { clearCommercialStructure } from "../../../entities/Reducer";
import { resetFilter, setFiltercommercialStucture } from "../Reducer";
import { FormInstance } from "antd/lib";
interface TasksProps {
  id: number;
  description: string;
  status?: string;
  initiative_id: number;
  initiative_name: string;
  level3_id: number;
  level3_name: string;
  initial_date: string;
  final_date: string;
  action_value: string;
}

interface Level3Props {
  id: number;
  level3_external_name: string;
}

interface InitiativeProps {
  id: number;
  company_id: number;
  name: string;
  thematic_name: string;
}

const { Option } = Select;

export function ActionPlan() {
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const [menu, setMenu] = useState(true);
  const [modalNewAction, setModalNewAction] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);
 const formRef = createRef<FormInstance>() as RefObject<FormInstance<any>>;
  const [listTasks, setListTasks] = useState<TasksProps[]>([]);

  const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null);
  const [description, setDescription] = useState<string>("");
  const [status, setStatus] = useState<string | undefined>(undefined);
  const [initialDate, setInitialDate] = useState<any>(null);
  const [finalDate, setFinalDate] = useState<any>(null);
  const [Level3, setLevel3] = useState<any>(null);
  console.log("🚀 ~ ActionPlan ~ Level3:", Level3);
  const [listInitiatives, setListInitiatives] = useState<InitiativeProps[]>([]);
  const [selectedInitiativeId, setSelectedInitiativeId] = useState<
    number | null
  >(null);
  const Icon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const [listLevel3, setListLevel3] = useState<Level3Props[]>([]);
  const [selectedLevel3Id, setSelectedLevel3Id] = useState<number | null>(null);

  const [searchData, setSearchData] = useState<TasksProps[]>([]);
  const [search, setSearch] = useState("");

  const [filteredStatus, setFilteredStatus] = useState<string | null>(null);

  const [level0Name, setlevel0Name] = useState<any>("");
  const [level0Value, setlevel0Value] = useState<any>("");

  const handleChangeAward = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const formattedValue = Number(value) / 100; // Convert to decimal value
    console.log(formattedValue);
    setlevel0Name(formattedValue);
    setlevel0Value(
      formattedValue.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      })
    ); // Format as currency
  };

  const getListTasks = useCallback(async () => {
    try {
      setLoading(true);
      const response: any[] = await ActionPlanService.findLevel3(
        companyId,
        commercialStructure.level3
      );
      const formattedTasks = response.map((rawTask) => {
        return {
          id: rawTask.id,
          description: rawTask.description,
          initiative_id: rawTask.initiative_id,
          initiative_name: rawTask.initiative_name ?? "",
          level3_id: rawTask.level3_id,
          level3_name: rawTask.level3_external_name ?? "",
          status: rawTask.status,
          initial_date: rawTask.initial_date,
          final_date: rawTask.final_date,
          action_value: rawTask.action_value,
        };
      });
      setListTasks(formattedTasks as []);
      //setSearchData(formattedTasks);
    } catch (e) {
      console.error("Falha ao buscar tarefas: ");
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [companyId]);

  const getListInitiatives = useCallback(async () => {
    const response: InitiativeProps[] =
      await ActionPlanService.findAllInitiatives(companyId);
    setListInitiatives(response);
  }, [companyId]);

  const getListLevel3 = useCallback(async () => {
    const response: Level3Props[] = await ActionPlanService.findAllLevel3(
      companyId
    );
    setListLevel3(response);
  }, [companyId, Level3]);

  useEffect(() => {
    getListInitiatives();
    getListLevel3();
  }, [getListInitiatives, getListLevel3, Level3]);

  const columns = [
    {
      title: "Descriçao",
      dataIndex: "description",
      key: "description",
      sorter: (a: any, b: any) => a.description.localeCompare(b.description),
    },
    {
      title: "Iniciativa",
      dataIndex: "initiative_name",
      key: "initiative_name",
      sorter: (a: any, b: any) =>
        a.initiative_name.localeCompare(b.initiative_name),
    },
    {
      title: "Data de início",
      dataIndex: "initial_date",
      key: "initial_date",
      sorter: (a: any, b: any) => a.initial_date.localeCompare(b.initial_date),
      render: (date: string | null) => {
        return date ? date.split("-").reverse().join("/") : "";
      },
    },
    {
      title: "Data de entrega",
      dataIndex: "final_date",
      key: "final_date",
      sorter: (a: any, b: any) => a.final_date.localeCompare(b.final_date),
      render: (date: string | null) => {
        return date ? date.split("-").reverse().join("/") : "";
      },
    },
    {
      title: "Valor Ação",
      dataIndex: "action_value",
      key: "action_value",
      sorter: (a: any, b: any) => a.action_value - b.action_value,
      render: (text: string | null) => {
        return text
          ? Number(text).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          : "";
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a: any, b: any) => a.status.localeCompare(b.status),
      render: (text: string) => {
        return <Tag color={getTagColor(text)}>{getTagText(text)}</Tag>;
      },
    },
    {
      title: "Responsável",
      dataIndex: "level3_name",
      key: "level3_name",
      sorter: (a: any, b: any) => a.level3_name.localeCompare(b.level3_name),
    },
    {
      title: "Ações",
      dataIndex: "uf",
      key: "uf",
      width: 150,
      align: "center" as const,
      render: (_: null, record: TasksProps) => {
        return (
          <Actions>
            <Tooltip title="Editar">
              <Button
                type="text"
                icon={
                  <FiEdit2
                    size={18}
                    color="#00CC66"
                    onClick={() => handleClickEdit(record)}
                  />
                }
              />
            </Tooltip>
            <Tooltip title="Excluir">
              <Popconfirm
                title="Tem certeza que deseja excluir essa tarefa?"
                onConfirm={() => confirmDelete(record)}
                okText="Excluir"
                okButtonProps={{ danger: true }}
                cancelText="Cancelar"
              >
                <Button type="text" icon={<FiTrash size={18} color="#00CC66" />} />
              </Popconfirm>
            </Tooltip>
          </Actions>
        );
      },
    },
  ];

  function getTagText(text: string) {
    switch (text) {
      case "PENDENTE":
        return "Pendente";
      case "EM ANDAMENTO":
        return " Em Andamento";
      case "CANCELADO":
        return "Cancelado";
      default:
        return "Finalizado";
    }
  }
  function getTagColor(text: string) {
    switch (text) {
      case "PENDENTE":
        return "gold";
      case "EM ANDAMENTO":
        return "purple";
      case "FINALIZADO":
        return "blue";
      default:
        return "red";
    }
  }
  const dispatch = useAppDispatch();
  function handleClickEdit(task: TasksProps) {
    console.log(task);
    setSelectedTaskId(task.id ?? null);
    setDescription(task.description);
    setStatus(task.status);
    setSelectedLevel3Id(task.level3_id);
    setSelectedInitiativeId(task.initiative_id);
    setInitialDate(task.initial_date ? moment(task.initial_date) : null);
    setFinalDate(task.final_date ? moment(task.final_date) : null);

    const formattedValue =
      Number(
        task.action_value ? String(task.action_value).replace(/\D/g, "") : 0
      ) / 100;
    setlevel0Name(formattedValue);
    setlevel0Value(
      formattedValue.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      })
    );
    handleShowModalNewAction();
  }

  async function confirmDelete(task: TasksProps) {
    try {
      await ActionPlanService.deleteTask(companyId, task.id);
    } catch (e) {
      console.error("Falha ao excluir Plano de Ação ");
      message.error("Falha ao excluir Plano de Ação");
      console.error(e);
    }
    await getListTasks();
  }

  function handleShowModalNewAction() {
    // const formattedValue = Number(0) / 100; // Convert to decimal value
    // setlevel0Value(formattedValue.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }));
    setModalNewAction(true);
  }

  async function handleSave() {
    setModalNewAction(false);
    if (selectedTaskId) {
      try {
        await ActionPlanService.updateTask(companyId, {
          id: selectedTaskId,
          description:
            description[0].toUpperCase() + description.slice(1).toLowerCase(),
          status,
          level3_id: selectedLevel3Id,
          initiative_id: selectedInitiativeId,
          initial_date: initialDate,
          final_date: finalDate,
          action_value: level0Name,
        });
      } catch (e) {
        console.log("Falha ao atualizando Plano de Ação ");
        console.error(e);
      } finally {
        await getListTasks();
      }
    } else {
      try {
        await ActionPlanService.createTask(companyId, {
          description:
            description[0].toUpperCase() + description.slice(1).toLowerCase(),
          status,
          level3_id: selectedLevel3Id,
          initiative_id: selectedInitiativeId,
          initial_date: initialDate,
          final_date: finalDate,
          action_value: level0Name,
        });
      } catch (e) {
        console.log("Falha ao criar Plano de Ação");
        console.error(e);
      } finally {
        loadTableProduct(Level3, true);
      }
    }

    closeModal();
  }

  function clearSelecteds() {
    setSelectedTaskId(null);
    setSelectedInitiativeId(null);
    setSelectedLevel3Id(null);
    setInitialDate(null);
    setFinalDate(null);
    setDescription("");
    setlevel0Name(null);
    setlevel0Value(null);
    setStatus(undefined);
  }

  function closeModal() {
    setModalNewAction(false);
    clearSelecteds();
  }

  useEffect(() => {
    filterTable();

    function filterTable() {
      if (!search && !filteredStatus) {
        setSearchData(listTasks);
      } else {
        const array = listTasks.filter(
          (record) =>
            (!filteredStatus ||
              (record["status"] &&
                record["status"]
                  ?.toUpperCase()
                  .indexOf(filteredStatus.toUpperCase()) > -1)) &&
            (!search ||
              record["initiative_name"]
                .toLowerCase()
                .indexOf(search.toLowerCase()) > -1)
        );
        setSearchData(array);
      }
    }
  }, [filteredStatus, search, listTasks]);

  function disableSave(): boolean {
    return (
      !selectedInitiativeId ||
      !selectedLevel3Id ||
      !description ||
      description.trim().length === 0
    );
  }

  function handleChangeStatus(e: any) {
    const { value } = e.target;
    if (value === filteredStatus) {
      setFilteredStatus(null);
    } else {
      setFilteredStatus(value);
    }
  }
  function handleRemoveStatus() {
    setFilteredStatus(null);
  }

  function disabledDate(current: any) {
    if (initialDate) {
      return current && current < initialDate;
    } else {
      return false;
    }
  }
  const loadTableProduct = useCallback(
    async (id: number, showLoading: boolean) => {
      try {
        setStatus(undefined);
        setLoading(showLoading);
        const response = await ActionPlanService.findLevel3(companyId, id);
        setLevel3(id);
        setSelectedLevel3Id(id);
        const formattedTasks = response.map((rawTask: any) => {
          return {
            id: rawTask.id,
            description: rawTask.description,
            initiative_id: rawTask.initiative_id,
            initiative_name: rawTask.initiative_name ?? "",
            level3_id: rawTask.level3_id,
            level3_name: rawTask.level3_external_name ?? "",
            status: rawTask.status,
            initial_date: rawTask.initial_date,
            final_date: rawTask.final_date,
            action_value: rawTask.action_value,
          };
        });
        setListTasks(formattedTasks as []);
      } catch (err) {
        message.error("Falha ao buscar dados");
      } finally {
        setLoading(false);
      }
    },
    [companyId]
  );
  const commercialStructure = useAppSelector(
    (state) => state.salesPlanning.filter
  );

  useEffect(() => {
    return function clean() {
      dispatch(resetFilter());
      dispatch(clearCommercialStructure());
    };
  }, [dispatch]);

  async function onFinish(obj: {
    level0: number;
    level1: number;
    level2: number;
    level3: number;
  }) {
    setStatus(undefined);
    dispatch(setFiltercommercialStucture(obj));
    loadTableProduct(obj.level3, true);
    setMenu(false);
  }
  const { Title } = Typography;
  return (
    <Breadcrumb path="Plano de Ação">
      <>
        <Row align="middle" style={{ marginBottom: 16 }}>
          {/* Breadcrumb on the left */}

          {/* Title in the center */}
          <Col style={{ textAlign: "center", flexGrow: 1, background: "none" }}>
            <Title level={4} style={{ paddingBottom: "5px" }}>
              Plano de Ação - PGV
            </Title>
          </Col>
        </Row>
        <Row gutter={8}>
          {menu && (
            <Col xl={4} xs={24} sm={12}>
              <Card bordered={false}>
                <Form
                  ref={formRef}
                  onFinish={onFinish}
                  initialValues={commercialStructure}
                >
                  <CommercialStructure formRef={formRef} level={3} />
                  <Button htmlType="submit" type="primary" block>
                    Buscar
                  </Button>
                </Form>
              </Card>
            </Col>
          )}
          <Col xl={menu ? 20 : 24} xs={24}>
            <Card bordered={false}>
              <Row justify="space-between" gutter={[16, 16]}>
                <Col xl={8} xs={24}>
                  <Buttons>
                    {menu ? (
                      <FiX
                        color="#00CC66"
                        size={30}
                        onClick={() => setMenu(!menu)}
                        style={{ marginRight: 16 }}
                      />
                    ) : (
                      <FiMenu
                        color="#00CC66"
                        size={30}
                        onClick={() => setMenu(!menu)}
                        style={{ marginRight: 16 }}
                      />
                    )}
                    <Button
                      icon={<FiPlus />}
                      type="primary"
                      onClick={handleShowModalNewAction}
                      disabled={Level3 === null}
                    >
                      Novo
                    </Button>
                    <Input
                      allowClear
                      value={search}
                      placeholder="Pesquisar"
                      prefix={<FiSearch color="#00CC66" />}
                      suffix={<FiFilter color="#00CC66" />}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </Buttons>
                </Col>
                <Col>
                  <Radio.Group buttonStyle="solid" value={filteredStatus}>
                    <Radio.Button onClick={handleChangeStatus} value="PENDENTE">
                      PENDENTE
                    </Radio.Button>
                    <Radio.Button
                      onClick={handleChangeStatus}
                      value="EM ANDAMENTO"
                    >
                      EM ANDAMENTO
                    </Radio.Button>
                    <Radio.Button
                      onClick={handleChangeStatus}
                      value="CANCELADO"
                    >
                      CANCELADO
                    </Radio.Button>
                    <Radio.Button
                      onClick={handleChangeStatus}
                      value="FINALIZADO"
                    >
                      FINALIZADO
                    </Radio.Button>
                    {filteredStatus !== null ? (
                      <Button
                        style={{
                          backgroundColor: "#fc5f5f",
                          color: "#000",
                        }}
                        onClick={handleRemoveStatus}
                      >
                        X
                      </Button>
                    ) : (
                      ""
                    )}
                  </Radio.Group>
                </Col>
                <Col span={24}>
                  <Table
                    rowKey="id"
                    size="small"
                    columns={columns}
                    // pagination={false}
                    // scroll={{ y: 430, x: "100vw" }}
                    dataSource={searchData}
                    loading={{ indicator: Icon, spinning: isLoading }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Modal
            open={modalNewAction}
            okButtonProps={{ disabled: disableSave() }}
            okText={"Salvar"}
            onOk={handleSave}
            onCancel={closeModal}
            maskClosable={false}
            title={selectedTaskId ? "Atualizar Ação" : "Nova Ação"}
          >
            <Row justify="center" gutter={20}>
              <Col span={12}>
                <Input
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  placeholder="Descrição"
                  value={
                    description !== ""
                      ? description[0].toUpperCase() +
                        description.slice(1).toLowerCase()
                      : undefined
                  }
                  onChange={(e) => setDescription(e.target.value)}
                />

                <StyledDatePicker
                  format="DD/MM/YYYY"
                  placeholder="Data Inicial"
                  size="large"
                  value={initialDate}
                  onChange={(date: any) => setInitialDate(date)}
                />

                <Input
                  //disabled={selectedLevel !== 0}
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  placeholder="Valor Ação"
                  value={level0Value}
                  //onChange={(e) => setlevel0Name(e.target.value)}
                  onChange={handleChangeAward}
                />
              </Col>

              <Col span={12}>
                <Select
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  placeholder="Status"
                  onChange={(value) => setStatus(value)}
                  value={status}
                >
                  <Option value={"PENDENTE"}>Pendente</Option>
                  <Option value={"CANCELADO"}>Cancelado</Option>
                  <Option value={"EM ANDAMENTO"}>Em Andamento</Option>
                  <Option value={"FINALIZADO"}>Finalizado</Option>
                </Select>

                <StyledDatePicker
                  format="DD/MM/YYYY"
                  placeholder="Data Final"
                  size="large"
                  value={finalDate}
                  onChange={(date: any) => setFinalDate(date)}
                  disabledDate={disabledDate}
                />
                <Input
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  placeholder="Responsável"
                  value={
                    Level3
                      ? listLevel3.find((level3) => level3.id === Level3)
                          ?.level3_external_name
                      : ""
                  }
                  disabled
                />
              </Col>
            </Row>
            <Row justify="center" gutter={20} style={{ marginBottom: 40 }}>
              <Col span={24}>
                <Select
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  showSearch
                  placeholder="Iniciativa"
                  optionFilterProp="children"
                  onChange={(value) => setSelectedInitiativeId(value)}
                  value={selectedInitiativeId}
                >
                  {listInitiatives.map((initiative) => (
                    <Option value={initiative.id}>{initiative.name}</Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Modal>
        </Row>
      </>
    </Breadcrumb>
  );
}
