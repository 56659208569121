import Http from "../../../utils/http";

class PowerBi_all extends Http {
  public constructor() {
    super();
  }

  public getPowerBi = async (companyId: number) => {
    return this.instance.get(`/company/${companyId}/parameter/type/3`);
  };

  public getDataMapa = async () => {
    const response = await this.instance.get(`/global/base`);
    return response;
  };

  public getButtomUpFindRegional = async (
    companyId: number,
    level1_id?: number,
    user_id?: number
  ) => {
    return this.instance.get(
      `/company/${companyId}/pgv/bottom-up/dashboard/regional/find`,
      { params: { level1_id: level1_id,user_id } }
    );
  };

  public GetButtomUpRegional = async (
    companyId: number,
    years: string[],
    filterRegional: number,
    level1_id: number,
    Business_type_id?: number
  ) => {
    const yearsParam = years.join(",");
    return this.instance.get(
      `/company/${companyId}/pgv/bottom-up/dashboard/regional`,
      {
        params: {
          years: yearsParam,
          filterRegional: filterRegional,
          level1_id: level1_id,
          business_type_id: Business_type_id,
        },
      }
    );
  };

  public GetButtomUpLevel1 = async (companyId: number, idUser: number) => {
    return this.instance.get(
      `/company/${companyId}/pgv/bottom-up/dashboard/level1`,
      { params: { idUser: idUser } }
    );
  };

  public GetButtomUpFilial = async (
    companyId: number,
    years: string[],
    filterRegional: number,
    filterFilial?: number,
    business_type_id?: number
  ) => {
    const yearsParam = years.join(",");
    return this.instance.get(
      `/company/${companyId}/pgv/bottom-up/dashboard/filial`,
      {
        params: {
          years: yearsParam,
          filterRegional: filterRegional,
          filterFilial: filterFilial,
          business_type_id: business_type_id,
        },
      }
    );
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PowerBi_all();
