/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-eval */
import {
  Button,
  Card,
  Col,
  Row,
  Segmented,
  Select,
  Statistic,
  Table,
} from "antd";
import { compareLabelsTables, useAppSelector } from "../../../hooks";
import { useState, useEffect, JSX } from "react";
import PowerBi_all from "../../../services/Global/PowerBi";
import BottomUpService from "../../../services/SalesPlanning/BottomUp";
import ReactEcharts from "echarts-for-react";
import "./index.css";
//import { formatBR } from "../../../utils";
import CompanyBusinessTypeService from "../../../services/Company/BusinessType";
import CompanyCommercialStructureService from "../../../services/Company/CommercialStructure";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
} from "@ant-design/icons";
const Option = Select.Option;
export function BottomUPDashboardPGV() {
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const parameters = useAppSelector((state) => state.entities.parameter);
  const idUser = useAppSelector((state) => state.auth.user.id);
  const [year0, setYear0] = useState<string | undefined>("");
  const [year1, setYear1] = useState<string | undefined>("");
  const [year2, setYear2] = useState<string | undefined>("");
  const [year3, setYear3] = useState<string | undefined>("");
  const [year4, setYear4] = useState<string | undefined>("");
  const [year5, setYear5] = useState<string | undefined>("");
  const [valores, setValores] = useState<any>("R$");
  const [qdt_year, setQdt_year] = useState<string | undefined>("0");
  const [OptionsDiretoria, setOptionsDiretoria] = useState<any>([]);
  const [years, setYears] = useState<any>(["year0", "year1"]);
  const [DataTipoNegocio, setDataTipoNegocio] = useState<any>([]);
  const [TipoNegocio, setTipoNegocio] = useState<number>(0);
  const [DataProjecaodevendas, setDataProjecaodevendas] = useState<any>([]);
  const [OptionsRegional, setOptionsRegional] = useState<
    { id: number; name: string } | any
  >([]);
  const [somaAno0, setSomaAno0] = useState<any>(0);
  const [somaAno1, setSomaAno1] = useState<any>(0);
  const [somaAno2, setSomaAno2] = useState<any>(0);
  const [somaAno3, setSomaAno3] = useState<any>(0);
  const [somaAno4, setSomaAno4] = useState<any>(0);
  const [somaAno5, setSomaAno5] = useState<any>(0);
  const [filterRegional, setFilterRegional] = useState<number>(0);
  const [filterDiretoria, setFilterDiretoria] = useState<number | null>(null);
  const [filterFilialModal, setFilterFilialModal] = useState<number>(0);
  const [valoresProdutos, setValoresProdutos] = useState<any>([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isFullScreen2, setIsFullScreen2] = useState(false);
  const [isFullScreen3, setIsFullScreen3] = useState(false);
  const [DataFilialTable, setDataFilialTable] = useState<any>([]);
  const [timeAtualizarTelaAuto, setTimeAtualizarTelaAuto] =
    useState<string>("0");
  const [contador, setContador] = useState<any>();
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };
  const toggleFullScreen2 = () => {
    setIsFullScreen2(!isFullScreen2);
  };

  const toggleFullScreen3 = () => {
    setIsFullScreen3(!isFullScreen3);
  };
  const handleFilter = async () => {
    GetTipoNegocio();
    GetDataLevel1();
    if (filterRegional !== 0 && filterRegional === undefined) {
      GetDataBottomUp(filterRegional, filterDiretoria, false, years, 0);
    }
    if (filterRegional !== 0 && filterRegional !== undefined) {
      GetDataBottomUp(filterRegional, filterDiretoria, false, years, 0);
    }
  };

  const GetDataLevel1 = async () => {
    const response = await PowerBi_all.GetButtomUpLevel1(
      companyId,
      Number(idUser)
    );
    if (response.length === 1) {
      setOptionsDiretoria(response);
      await GetDataBottomUpFindRegional(response[0]?.id, Number(idUser));
      setFilterDiretoria(response[0]?.id);
      // await GetDataButtomUpFilial(0, 0);
    } else {
      setOptionsDiretoria(response);
      setFilterDiretoria(0);
      await GetDataBottomUpFindRegional(0, Number(idUser));
    }
  };

  const configurarAtualizacaoAutomatica = () => {
    if (timeAtualizarTelaAuto !== "0") {
      const interval = setInterval(() => {
        handleFilter();
      }, Number.parseInt(timeAtualizarTelaAuto) * 1000);
      return () => clearInterval(interval);
    }
  };
  const totalBaseLine = DataFilialTable.reduce(
    (acc: any, curr: any) => acc + (curr.baseLine || 0),
    0
  );
  const totalProjection = DataFilialTable.reduce(
    (acc: any, curr: any) => acc + (curr.projection || 0),
    0
  );
  const totalProjection1 = DataFilialTable.reduce(
    (acc: any, curr: any) => acc + (curr.projection1 || 0),
    0
  );

  const LimparFiltros = () => {
    GetDataBottomUp(0);
    setFilialOptions([]);
    setFilterRegional(0);
    setDataFilial([]);
    setDataProjecaodevendas([]);
    setFilterFilialModal(0);
    setValoresProdutos([]);
    setFilterDiretoria(null);
    setContador(null);
    setDataFilialTable([]);
    GetDataBottomUps(0, filterFilialModal);
  };

  useEffect(() => {
    handleFilter();
    GetDataBottomUps(0, filterFilialModal);
    GetDataBottomUp(filterRegional, filterDiretoria, false, years);
    if (filterRegional !== undefined) {
      GetDataButtomUpFilial(
        filterRegional,
        filterFilialModal,
        years,
        TipoNegocio
      );
    }
  }, [years]);

  useEffect(() => {
    setYears(yearsOptions(Number(qdt_year)));
  }, [qdt_year]);

  useEffect(configurarAtualizacaoAutomatica, [timeAtualizarTelaAuto]);

  useEffect(() => {
    if (timeAtualizarTelaAuto === "0") {
      setContador(null); // Reseta o contador quando "Não" é selecionado
      return;
    }

    // Converte o tempo selecionado para número
    const tempoInicial = parseInt(timeAtualizarTelaAuto, 10);

    setContador(tempoInicial);

    // Inicia a contagem decrescente
    const intervalId = setInterval(() => {
      setContador((prev: any) => {
        if (prev > 1) {
          return prev - 1;
        } else {
          return tempoInicial; // Reinicia o contador
        }
      });
    }, 1000);

    // Limpa o intervalo se o componente for desmontado ou o tempo mudar
    return () => clearInterval(intervalId);
  }, [timeAtualizarTelaAuto]);
  const handleRegionChange = async (value: any) => {
    await GetDataBottomUp(value, filterDiretoria, true, years, TipoNegocio);
    setFilterRegional(value);
    await GetDataButtomUpFilial(value, 0, years, TipoNegocio);
    setFilterFilialModal(0);
  };

  const handleDiretoriaChange = async (value: any) => {
    await GetDataBottomUp(filterRegional, value, true, years, TipoNegocio);
    setFilterDiretoria(value);
    GetDataBottomUpFindRegional(value, Number(idUser));
    setFilialOptions([]);
    setFilterRegional(0);

    //setDataFilial([]);
  };

  const handleFilialChangeModal = async (value: any) => {
    await GetDataButtomUpFilial(filterRegional, value, years, TipoNegocio);
    setFilterFilialModal(value);
    GetDataBottomUps(TipoNegocio, value);
  };

  async function GetDataBottomUps(
    Business_type_id?: number,
    level3_id?: number
  ) {
    setValoresProdutos([]);
    const response = await BottomUpService.getTableProductDash(
      companyId,
      Business_type_id,
      level3_id
    );

    // Transformar os dados no formato desejado
    const transformedData = response.records
      .map((item: any) => {
        // Mapeamento dos anos e valores correspondentes
        const years = [
          { year_label: "year0", total_volume: item.total_base_line },
          { year_label: "year1", total_volume: item.total_year1 },
          { year_label: "year2", total_volume: item.total_year2 },
          { year_label: "year3", total_volume: item.total_year3 },
          { year_label: "year4", total_volume: item.total_year4 },
          { year_label: "year5", total_volume: item.total_year5 },
        ];

        // Criar o array de objetos com os dados transformados
        return years.map((yearData) => ({
          name: item.product_name,
          year_label: yearData.year_label,
          year0: yearData.year_label === "year0" ? yearData.total_volume : 0,
          year1: yearData.year_label === "year1" ? yearData.total_volume : 0,
          year2: yearData.year_label === "year2" ? yearData.total_volume : 0,
          year3: yearData.year_label === "year3" ? yearData.total_volume : 0,
          year4: yearData.year_label === "year4" ? yearData.total_volume : 0,
          year5: yearData.year_label === "year5" ? yearData.total_volume : 0,
          total_volume: yearData.total_volume,
        }));
      })
      .flat(); // Usando .flat() para achatar o array de arrays em um único array

    // Agrupar os dados pelo 'name' e somar os volumes por ano
    const groupedData = transformedData.reduce((acc: any, curr: any) => {
      const key = curr.name;
      if (!acc[key]) {
        acc[key] = {
          name: curr.name,
          total_volume: 0,
          year0: 0,
          year1: 0,
          year2: 0,
          year3: 0,
          year4: 0,
          year5: 0,
        };
      }

      acc[key].total_volume = parseFloat(
        acc[key].total_volume + curr.total_volume
      );
      acc[key].year0 = parseFloat(acc[key].year0 + curr.year0);
      acc[key].year1 = parseFloat(acc[key].year1 + curr.year1);
      acc[key].year2 = parseFloat(acc[key].year2 + curr.year2);
      acc[key].year3 = parseFloat(acc[key].year3 + curr.year3);
      acc[key].year4 = parseFloat(acc[key].year4 + curr.year4);
      acc[key].year5 = parseFloat(acc[key].year5 + curr.year5);
      return acc;
    }, {});

    // Obter os valores agrupados como um array
    const finalData = Object.values(groupedData);

    // Retornar ou configurar os valores do produto
    setValoresProdutos(finalData.splice(0, 10));
  }

  useEffect(() => {
    setYear0(
      parameters.find((value: any) => value.name === "YEAR_BASE_LINE_LABEL")
        ?.value
    );
    setYear1(parameters.find((value) => value.name === "YEAR1_LABEL")?.value);
    setYear2(parameters.find((value) => value.name === "YEAR2_LABEL")?.value);
    setYear3(parameters.find((value) => value.name === "YEAR3_LABEL")?.value);
    setYear4(parameters.find((value) => value.name === "YEAR4_LABEL")?.value);
    setYear5(parameters.find((value) => value.name === "YEAR5_LABEL")?.value);
    const qtdYears =
      parameters.find((value) => value.name === "QTD_YEARS")?.value || "1";
    setQdt_year(qtdYears);
  }, [parameters]);

  const [DataFilial, setDataFilial] = useState<any>([]);

  const [filialOptions, setFilialOptions] = useState([]);
  const formatValue = (value: any) => {
    if (isNaN(value)) {
      return "NaN";
    }
    value = Number(value);
    if (value === 0) {
      return "0";
    }
    if (value >= 1e12) {
      return `${(value / 1e12).toFixed(2)}T`;
    } else if (value >= 1e9) {
      return `${(value / 1e9).toFixed(2)}B`;
    } else if (value >= 1e6) {
      return `${(value / 1e6).toFixed(2)}M`;
    } else if (value >= 1e3) {
      return `${(value / 1e3).toFixed(2)}K`;
    } else {
      return value.toFixed(2);
    }
  };
  const columns = [
    {
      title: "Filial",
      dataIndex: "name",
      key: "name",
      width: "40%",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: "Base Line",
      dataIndex: "baseLine",
      key: "baseLine",
      width: "90px",
      sorter: (a: any, b: any) => a.baseLine - b.baseLine,
      render: (text: any, record: any) => {
        return (
          <span>{record.baseLine ? formatValue(record.baseLine) : "-"}</span>
        );
      },
    },
    {
      title: "Proj Y+1",
      dataIndex: "projection",
      key: "projection",
      width: "90px",

      sorter: (a: any, b: any) => a.projection - b.projection,
      render: (text: any, record: any) => {
        return (
          <span>
            {record.projection ? formatValue(record.projection) : "-"}
          </span>
        );
      },
    },
    {
      title: "Proj Y+2",
      dataIndex: "projection1",
      key: "projection1",
      width: "90px",
      sorter: (a: any, b: any) => a.projection1 - b.projection1,
      render: (text: any, record: any) => {
        return (
          <span>
            {record.projection1 && record.projection1 > 0
              ? formatValue(record.projection1)
              : "-"}
          </span>
        );
      },
    },
    {
      title: "Variação",
      dataIndex: "variation",
      key: "variation",
      width: "80px",
      render: (text: any, record: any) => {
        const variation =
          record.baseLine !== null && record.projection !== null
            ? ((record.projection - record.baseLine) / record.baseLine) * 100
            : 0;
        return (
          <span>
            {variation > 0 ? (
              <ArrowUpOutlined style={{ color: "green" }} />
            ) : variation < 0 ? (
              <ArrowDownOutlined style={{ color: "red" }} />
            ) : null}
            {` ${variation.toFixed(1)}%`}
          </span>
        );
      },
    },
  ];
  interface Column {
    title: string;
    dataIndex: string;
    key: string;
    render?: (text: any, record: any) => JSX.Element;
  }

  const filteredColumns: Column[] = columns.filter(
    (column: Column) =>
      column.key !== "projection1" ||
      DataFilialTable.some((record: any) => record.projection1 > 0)
  );
  async function GetDataButtomUpFilial(
    filtro: number,
    filterFilial?: number,
    year?: any,
    Business_type_id?: number
  ) {
    const response = await PowerBi_all.GetButtomUpFilial(
      companyId,
      year ? year : yearsOptions(Number(qdt_year)),
      filtro,
      filterFilial,
      Business_type_id
    );
    setDataFilialTable([]);
    setDataFilial([]);
    const updatedData = response.map((item: any) => {
      let newYearLabel = item.year_label;

      // Substitui o year_label com base nos anos, se estiverem presentes no array years
      if (year && year.includes(item.year_label)) {
        if (item.year_label === "year0") newYearLabel = year0;
        if (item.year_label === "year1") newYearLabel = year1;
        if (item.year_label === "year2") newYearLabel = year2;
        // if (item.year_label === "year3") newYearLabel = year3;
        // if (item.year_label === "year4") newYearLabel = year4;
        // if (item.year_label === "year5") newYearLabel = year5;
      }

      // Abrevia o valor de total_volume para "K", "M", etc.
      const abbreviatedTotalVolume = item.total_volume;

      return {
        ...item,
        year_label: newYearLabel,
        value: abbreviatedTotalVolume,
      };
    });

    const groupedData = updatedData.reduce((acc: any, item: any) => {
      if (!year) {
        return acc;
      }
      const existing = acc.find((i: any) => i.id === item.id);
      if (existing) {
        if (year.includes("year0") && item.year_label === year0)
          existing.baseLine = item.value;
        if (year.includes("year1") && item.year_label === year1)
          existing.projection = item.value;
        if (year.includes("year2") && item.year_label === year2)
          existing.projection1 = item.value;
        //  if (year.includes("year3") && item.year_label === year3)
        //   existing.projection2 = item.value;
        //  if (year.includes("year4") && item.year_label === year4)
        //    existing.projection3 = item.value;
        //  if (year.includes("year5") && item.year_label === year5)
        //     existing.projection4 = item.value;
      } else {
        acc.push({
          id: item.id,
          name: item.name,
          baseLine:
            year.includes("year0") && item.year_label === year0
              ? item.value
              : null,
          projection:
            year.includes("year1") && item.year_label === year1
              ? item.value
              : null,
          projection1:
            year.includes("year2") && item.year_label === year2
              ? item.value
              : null,
          /*  projection2:
            year.includes("year3") && item.year_label === year3
              ? item.value
              : null,
          projection3:
            year.includes("year4") && item.year_label === year4
              ? item.value
              : null,
          projection4:
            year.includes("year5") && item.year_label === year5
              ? item.value
              : null,*/
        });
      }
      return acc;
    }, []);
    setDataFilialTable(groupedData);

    setDataFilial(updatedData);

    const seen = new Set();
    const uniqueFilialOptions = updatedData.filter((item: any) => {
      if (!seen.has(item.name)) {
        seen.add(item.name); // Marca o nome como visto
        return true;
      }
      return false;
    });
    if (filialOptions.length === 0) {
      setFilialOptions(uniqueFilialOptions);
    }
    //setFilialOptions(uniqueFilialOptions);
    filterFilial !== 0 ||
      (filterFilial !== undefined &&
        filtro !== 0 &&
        setFilialOptions(uniqueFilialOptions));
  }

  const yearsOptions = (qdt_year: number) => {
    let yearss = [];
    for (let i = 0; i <= qdt_year - 1; i++) {
      yearss.push(`year${i}`);
    }
    return yearss;
  };

  const handleChange = async (value: any) => {
    setYears(value);
    setDataProjecaodevendas([]);
    await GetDataBottomUp(
      filterRegional,
      filterDiretoria,
      false,
      value,
      TipoNegocio
    );
    if (filterRegional !== undefined) {
      await GetDataButtomUpFilial(
        filterRegional,
        filterFilialModal,
        value,
        TipoNegocio
      );
    }
  };
  const handleChangeTipoNegocio = async (value: any) => {
    setTipoNegocio(value);
    await GetDataBottomUp(filterRegional, filterDiretoria, false, years, value);
    GetDataBottomUps(value, filterFilialModal);
    await GetDataButtomUpFilial(
      filterRegional,
      filterFilialModal,
      years,
      value
    );
  };
  async function GetDataBottomUpFindRegional(level1: number, idUser: number) {
    const response = await PowerBi_all.getButtomUpFindRegional(
      companyId,
      level1,
      idUser
    );
    setOptionsRegional(response);
    if (response.length === 1) {
      setFilterRegional(response[0]?.id);
      await GetDataBottomUp(
        response[0]?.id,
        filterDiretoria,
        true,
        years,
        TipoNegocio
      );
      await GetDataButtomUpFilial(response[0]?.id, 0, years, TipoNegocio);
    }
  }

  async function GetDataBottomUp(
    filtro?: any,
    filterDiretoria?: any,
    valid?: boolean,
    years?: any,
    Business_type_id?: number
  ) {
    const response = await PowerBi_all.GetButtomUpRegional(
      companyId,
      years ? years : yearsOptions(Number(qdt_year)),
      filtro !== undefined ? filtro : filterRegional,
      filterDiretoria,
      Business_type_id
    );
    valid &&
      (await GetDataButtomUpFilial(
        filtro !== undefined ? filtro : filterRegional,
        filterFilialModal,
        years,
        Business_type_id
      ));
    // Agrupar e somar os valores de total_volume por name e year_label
    const groupedData = response.reduce((acc: any[], item: any) => {
      const existing = acc.find(
        (entry: any) =>
          entry.year_label === item.year_label && entry.name === item.name
      );
      if (existing) {
        existing.total_volume += item.total_volume; // Soma o volume para os mesmos name e year_label
      } else {
        acc.push({
          id: item.id,
          name: item.name,
          year_label: item.year_label, // Mantém o valor original de year_label
          total_volume: item.total_volume,
        });
      }
      return acc;
    }, []);

    // Atualiza os dados com o valor de total_volume abreviado
    const sumVolumeByYear = (data: any[], yearLabel: any) => {
      return data
        .filter((item) => item.year_label === yearLabel) // Filtra os itens pelo year_label
        .reduce((acc, item) => acc + item.total_volume, 0); // Soma os valores de total_volume
    };
    const updatedData = groupedData.map((item: any) => {
      let newYearLabel = item.year_label;

      // Substitui o year_label com base nos anos
      if (item.year_label === "year0") newYearLabel = year0;
      if (item.year_label === "year1") newYearLabel = year1;
      if (item.year_label === "year2") newYearLabel = year2;
      if (item.year_label === "year3") newYearLabel = year3;
      if (item.year_label === "year4") newYearLabel = year4;
      if (item.year_label === "year5") newYearLabel = year5;
      // Abrevia o valor de total_volume para "K", "M", etc.
      const abbreviatedTotalVolume = item.total_volume;

      return {
        ...item,
        year_label: newYearLabel,
        value: abbreviatedTotalVolume.toFixed(2),
      };
    });

    // Setar os dados atualizados
    const somaAno0 = sumVolumeByYear(response, "year0");
    const somaAno1 = sumVolumeByYear(response, "year1");
    const somaAno2 = sumVolumeByYear(response, "year2");
    const somaAno3 = sumVolumeByYear(response, "year3");
    const somaAno4 = sumVolumeByYear(response, "year4");
    const somaAno5 = sumVolumeByYear(response, "year5");

    // Armazena os valores somados nas variáveis correspondentes
    setSomaAno0(somaAno0);
    setSomaAno1(somaAno1);
    setSomaAno2(somaAno2);
    setSomaAno3(somaAno3);
    setSomaAno4(somaAno4);
    setSomaAno5(somaAno5);

    setDataProjecaodevendas(updatedData);
  }
  async function GetTipoNegocio() {
    const response = await CompanyBusinessTypeService.getCompanyBusinessType(
      companyId
    );
    setDataTipoNegocio(response);
    if (response.length === 1) {
      setTipoNegocio(response[0]?.id);
    }
  }

  const configPorcentagemBottomUP = {
    title: {
      text: "Produto",
      x: "center",
    },
    color: ["#00cc66", "#0075ba", "#fb2f2e", "#8d66c1", "#ffcc00"],
    tooltip: {
      trigger: "item",
      formatter: (params: any) => {
        const formattedValue = formatValue(params.value);
        const year = params.seriesIndex === 0 ? "Base Line" : "Projeção";
        return `${params.seriesName} <br/>${params.name} : ${formattedValue} (${params.percent}%)<br/>Ano: ${year}`;
      },
    },
    legend: {
      show: false,
      orient: "horizontal",
      left: "scroll",
      top: "bottom",
      data: valoresProdutos.map((item: any) => item.name),
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true, title: "Salvar" },
        magicType: { show: false, type: ["line", "bar"] },
        dataView: {
          show: true,
          readOnly: false,
          title: "Visualização de Dados",
          lang: ["Visualização de Dados", "Fechar", "Atualizar"],
        },
      },
    },
    series: [
      {
        name: "Produto",
        type: "pie",
        center: ["50%", "50%"],
        radius: ["20%", "40%"],
        label: {
          formatter: (params: any) => {
            return `${params.name}`;
          },
          position: "inner",
        },
        data: valoresProdutos
          .sort((a: any, b: any) => b.year0 - a.year0)
          .map((item: any) => ({
            name: item.name,
            value: item.year0,
          })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
      {
        name: "Produto",
        type: "pie",
        center: ["50%", "50%"],
        radius: ["45%", "60%"],
        label: {
          formatter: (params: any) => {
            return `${params.name}`;
          },
        },
        data: valoresProdutos
          .sort((a: any, b: any) => b.year0 - a.year0)
          .map((item: any) => ({
            name: item.name,
            value: item.year1,
          })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  const configRegional: any = {
    title: {
      text: "Regional",
      x: "center",
    },
    legend: { show: true, left: "left", top: "25px" },
    grid: { containLabel: true },
    xAxis: {
      type: "category",
      inverse: true,
      data: Array.from(
        new Set(DataProjecaodevendas.map((item: any) => item.name as string))
      ),
    },
    yAxis: { show: false },
    tooltip: {
      trigger: "axis",
      axisPointer: { type: "shadow" },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true, title: "Salvar" },
        magicType: { show: true, type: ["stack", "line", "bar"] },
        dataView: {
          show: true,
          readOnly: false,
          title: "Visualização de Dados",
          lang: ["Visualização de Dados", "Fechar", "Atualizar"],
        },
      },
    },
    series: [
      ...Array.from(
        new Set(DataProjecaodevendas.map((item: any) => item.year_label))
      ).map((year) => ({
        realtimeSort: true,
        name: year as string,
        type: "bar",
        data: DataProjecaodevendas.filter(
          (item: any) => item.year_label === year
        ).sort((a: any, b: any) => b.total_volume - a.total_volume), // Ordena os dados pelo valor
        label: {
          show: true,
          position: "top",
          formatter: (params: any) => {
            const formattedValue = formatValue(params.value);
            return `${formattedValue}`;
          },
        },
        radius: "50%",
      })),
    ],
  };

  const configFilial: any =
    DataFilial.length > 0
      ? {
          title: {
            text: "Filial",
            x: "center",
          },
          legend: { show: false, left: "left", top: "25px" },
          grid: { containLabel: true },
          yAxis: {
            type: "category",
            inverse: true,
            data: DataFilialTable.sort(
              (a: any, b: any) => b.projection1 - a.projection1
            ).map((item: any) => item.name), // Ordena os nomes das filiais
          },
          xAxis: { show: false },
          tooltip: {
            trigger: "axis",
            axisPointer: { type: "shadow" },
          },
          toolbox: {
            show: true,
            feature: {
              saveAsImage: { show: true, title: "Salvar" },
              magicType: { show: true, type: ["line", "bar"] },
              dataView: {
                show: true,
                readOnly: false,
                title: "Visualização de Dados",
                lang: ["Visualização de Dados", "Fechar", "Atualizar"],
              },
            },
          },
          series: [
            {
              name: "Base Line",
              type: "bar",
              data: DataFilialTable.sort(
                (a: any, b: any) => b.projection1 - a.projection1
              ).map((item: any) => item.baseLine),
              label: {
                show: true,
                position: "right",
                formatter: (params: any) => {
                  const formattedValue = formatValue(params.value);
                  return `${formattedValue}`;
                },
              },
            },
            {
              name: "Projeção Y+1",
              type: "bar",
              data: DataFilialTable.sort(
                (a: any, b: any) => b.projection1 - a.projection1
              ).map((item: any) => item.projection),
              label: {
                show: true,
                position: "right",
                formatter: (params: any) => {
                  const formattedValue = formatValue(params.value);
                  return `${formattedValue}`;
                },
              },
            },
            ...[1].map((i) => {
              const projectionKey = `projection${i}`;
              if (DataFilialTable.some((item: any) => item[projectionKey])) {
                return {
                  name: `Projeção Y+${i + 1}`,
                  type: "bar",
                  data: DataFilialTable.sort(
                    (a: any, b: any) => b.projection1 - a.projection1
                  ).map((item: any) => item[projectionKey]),
                  label: {
                    show: true,
                    position: "right",
                    formatter: (params: any) => {
                      const formattedValue = formatValue(params.value);
                      return `${formattedValue}`;
                    },
                  },
                };
              }
              return {
                name: `Projeção +${i}`,
                type: "bar",
                data: [],
                label: {
                  show: true,
                  position: "right",
                  formatter: (params: any) => {
                    return "";
                  },
                },
              };
            }),
          ],
        }
      : {};

  return (
    <Row style={{ minWidth: 1200 }}>
      <Col span={24}>
        <main className="main-container">
          <div className="sales-projection-header">
            <Col span={24} style={{ width: "100%" }}>
              <Row>
                <Col xl={8} xs={24} sm={12} />
                <Col xl={9} xs={24} sm={12}>
                  <div className="header-title">
                    <span>Base Line vs Projeção de Vendas</span>
                  </div>
                  <div className="header-title-sub">
                    <span>Modúlo: Projeção de Vendas Botton up</span>
                  </div>
                </Col>
                <Col xl={7} xs={24} sm={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <b style={{ color: "#fff" }}>Atualizar: </b>
                    <Select
                      defaultValue="0"
                      style={{ width: 120 }}
                      value={timeAtualizarTelaAuto}
                      onChange={(e) => {
                        setTimeAtualizarTelaAuto(e);
                      }}
                    >
                      <Option value="0">Não</Option>
                      <Option value="5">5 Sec</Option>
                      <Option value="10">10 Sec</Option>
                      <Option value="20">20 Sec</Option>
                    </Select>
                  </div>
                </Col>
              </Row>
            </Col>
          </div>
        </main>
        <div className="report-container">
          <Row style={{ width: "100%" }}>
            {timeAtualizarTelaAuto !== "0" && (
              <div className="divtimer">
                <div className="timer">Atualiza em {contador} segundos</div>
              </div>
            )}
          </Row>

          <Row style={{ width: "100%", paddingTop: 5 }}>
            <Col xl={3} xs={3} sm={6}>
              <Card
                title="Limpar Filtros"
                size="small"
                bordered={false}
                className="card-filter"
              >
                <div className="report-card-content">
                  <Button
                    type="primary"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                    size="large"
                    onClick={() => LimparFiltros()}
                  >
                    Limpar
                  </Button>
                </div>
              </Card>
              <Card
                title="Valores"
                size="small"
                bordered={false}
                className="card-filter"
              >
                <div className="report-card-content">
                  <Segmented options={["R$", "KG/LT"]} onChange={setValores} />
                </div>
              </Card>
              <Card
                title="Ano"
                size="small"
                bordered={false}
                className="card-filter"
              >
                <div className="report-card-content">
                  <Select
                    mode="multiple"
                    placeholder="Selecione o ano"
                    onChange={handleChange}
                    maxCount={3}
                    value={years.slice(0, 3)}
                    style={{ width: "100%" }}
                    options={yearsOptions(Number(qdt_year)).map(
                      (year: string, index: number) => ({
                        value: year,
                        label: eval(`year${index}`),
                        disabled: index < 2,
                      })
                    )}
                  />
                </div>
              </Card>
              <Card
                title="Negócio"
                size="small"
                bordered={false}
                className="card-filter"
              >
                <div className="report-card-content">
                  <Select
                    placeholder="Negócio"
                    onChange={handleChangeTipoNegocio}
                    value={TipoNegocio}
                    defaultValue={TipoNegocio}
                    style={{ width: "100%" }}
                    options={[
                      { value: 0, label: "Todos" },
                      ...DataTipoNegocio.map((item: any) => ({
                        value: item.id,
                        label: item.name,
                      })),
                    ]}
                  />
                </div>
              </Card>

              <Card
                title="Diretoria"
                size="small"
                bordered={false}
                className="card-filter"
              >
                <div className="report-card-content">
                  <Select
                    placeholder="Diretoria"
                    onChange={handleDiretoriaChange}
                    value={filterDiretoria}
                    defaultValue={filterDiretoria}
                    style={{ width: "100%" }}
                    options={[
                      { value: 0, label: "Todos" },
                      ...OptionsDiretoria.sort((a: any, b: any) =>
                        compareLabelsTables("level1_external_name")(a, b)
                      ).map((item: any) => ({
                        value: item.id,
                        label: item.level1_external_name,
                      })),
                    ]}
                  />
                </div>
              </Card>

              <Card
                title="Regional"
                size="small"
                bordered={false}
                className="card-filter"
              >
                <div className="report-card-content">
                  <Select
                    placeholder="Regional"
                    onChange={handleRegionChange}
                    value={filterRegional}
                    style={{ width: "100%" }}
                    options={[
                      { value: 0, label: "Todos" },
                      ...OptionsRegional.sort((a: any, b: any) =>
                        compareLabelsTables("name")(a, b)
                      ).map((item: any) => ({
                        value: item.id,
                        label: item.name,
                      })),
                    ]}
                  />
                </div>
              </Card>

              {filterRegional !== 0 && (
                <Card
                  title="Filial"
                  size="small"
                  bordered={false}
                  className="card-filter"
                >
                  <div className="report-card-content">
                    <Select
                      placeholder="Filial"
                      defaultValue={{
                        label: "Todos",
                        value: 0,
                      }}
                      onChange={handleFilialChangeModal}
                      value={filterFilialModal}
                      style={{ width: "100%" }}
                      options={[
                        { value: 0, label: "Selecionar" },
                        ...filialOptions
                          .sort((a, b) => compareLabelsTables("name")(a, b))
                          .map((item: any) => ({
                            label: item.name,
                            value: item.id,
                          })),
                      ]}
                    />
                  </div>
                </Card>
              )}
            </Col>
            <Col
              xl={
                DataFilialTable.length > 0
                  ? filterFilialModal !== 0
                    ? 21
                    : 11
                  : 21
              }
              xs={filterFilialModal !== 0 ? 21 : 15}
            >
              {totalBaseLine !== 0 && totalProjection !== 0 && (
                <Row style={{ width: "100%" }}>
                  {filterFilialModal !== 0 && (
                    <Col
                      xl={
                        DataFilialTable.length > 0
                          ? DataFilialTable.length === 1
                            ? 15
                            : 24
                          : 24
                      }
                      xs={12}
                    >
                      <div
                        className="report-grid"
                        style={{
                          flexWrap: "wrap",
                          gap: "10px",
                        }}
                      >
                        <Card
                          bordered={true}
                          size="small"
                          title="Base Line"
                          className="card-statistic"
                        >
                          <div className="report-card-content">
                            <Statistic
                              valueStyle={{ color: "#3f8600" }}
                              value={formatValue(totalBaseLine)}
                            />
                          </div>
                        </Card>
                        <Card
                          bordered={true}
                          size="small"
                          title="Projeção Y+1"
                          className="card-statistic"
                        >
                          <div className="report-card-content">
                            <Statistic
                              valueStyle={{ color: "#3f8600" }}
                              value={formatValue(totalProjection)}
                            />
                          </div>
                        </Card>
                        {totalProjection1 !== 0 && (
                          <Card
                            bordered={true}
                            size="small"
                            title="Projeção Y+2"
                            className="card-statistic"
                          >
                            <div className="report-card-content">
                              <Statistic
                                valueStyle={{ color: "#3f8600" }}
                                value={formatValue(totalProjection1)}
                              />
                            </div>
                          </Card>
                        )}
                        <Card
                          title="BL vs Y+1"
                          size="small"
                          bordered={true}
                          style={{
                            margin: "5px",
                          }}
                        >
                          <div className="report-card-content">
                            <Statistic
                              valueStyle={{
                                color:
                                  totalProjection - totalBaseLine > 0
                                    ? "green"
                                    : totalProjection - totalBaseLine < 0
                                    ? "red"
                                    : "yellow",
                              }}
                              value={
                                totalBaseLine !== 0
                                  ? `${(
                                      ((totalProjection - totalBaseLine) /
                                        totalBaseLine) *
                                      100
                                    ).toFixed(2)}%`
                                  : "0%"
                              }
                              prefix={
                                totalProjection - totalBaseLine > 0 ? (
                                  <ArrowUpOutlined style={{ color: "green" }} />
                                ) : totalProjection - totalBaseLine < 0 ? (
                                  <ArrowDownOutlined style={{ color: "red" }} />
                                ) : null
                              }
                            />
                          </div>
                        </Card>
                        {totalProjection1 !== 0 && (
                          <Card
                            title="BL vs Y+2"
                            size="small"
                            bordered={true}
                            style={{
                              margin: "5px",
                            }}
                          >
                            <div className="report-card-content">
                              <Statistic
                                valueStyle={{
                                  color:
                                    totalProjection1 - totalBaseLine > 0
                                      ? "green"
                                      : totalProjection1 - totalBaseLine < 0
                                      ? "red"
                                      : "yellow",
                                }}
                                value={
                                  totalBaseLine !== 0
                                    ? `${(
                                        ((totalProjection1 - totalBaseLine) /
                                          totalBaseLine) *
                                        100
                                      ).toFixed(2)}%`
                                    : "0%"
                                }
                                prefix={
                                  totalProjection1 - totalBaseLine > 0 ? (
                                    <ArrowUpOutlined
                                      style={{ color: "green" }}
                                    />
                                  ) : totalProjection1 - totalBaseLine < 0 ? (
                                    <ArrowDownOutlined
                                      style={{ color: "red" }}
                                    />
                                  ) : null
                                }
                              />
                            </div>
                          </Card>
                        )}
                      </div>
                    </Col>
                  )}
                  {filterFilialModal !== 0 && (
                    <Col xl={DataFilialTable.length > 0 ? 9 : 0} xs={12}>
                      {DataFilialTable.length > 0 && (
                        <Table
                          columns={filteredColumns}
                          size="small"
                          pagination={false}
                          dataSource={DataFilialTable}
                          sortDirections={["descend", "ascend"]}
                          scroll={{ y: 100 * 5 }}
                          footer={() => {
                            return (
                              <table style={{ width: "100%" }}>
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        fontWeight: "bold",
                                        width: "38%",
                                      }}
                                    >
                                      Total
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {formatValue(totalBaseLine)}
                                    </td>
                                    <td
                                      style={{
                                        textAlign:
                                          totalProjection1 > 0
                                            ? "end"
                                            : "center",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {formatValue(totalProjection)}
                                    </td>
                                    {totalProjection1 > 0 && (
                                      <td
                                        style={{
                                          textAlign: "end",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {formatValue(totalProjection1)}
                                      </td>
                                    )}
                                    <td
                                      style={{
                                        textAlign:
                                          totalProjection1 > 0
                                            ? "end"
                                            : "center",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {totalProjection1 > 0 ? (
                                        totalProjection +
                                          totalProjection1 -
                                          totalBaseLine >
                                        0 ? (
                                          <ArrowUpOutlined
                                            style={{ color: "green" }}
                                          />
                                        ) : totalProjection +
                                            totalProjection1 -
                                            totalBaseLine <
                                          0 ? (
                                          <ArrowDownOutlined
                                            style={{ color: "red" }}
                                          />
                                        ) : null
                                      ) : totalProjection - totalBaseLine >
                                        0 ? (
                                        <ArrowUpOutlined
                                          style={{ color: "green" }}
                                        />
                                      ) : totalProjection - totalBaseLine <
                                        0 ? (
                                        <ArrowDownOutlined
                                          style={{ color: "red" }}
                                        />
                                      ) : null}

                                      {totalProjection1 > 0
                                        ? `${(
                                            ((totalProjection +
                                              totalProjection1 -
                                              totalBaseLine) /
                                              totalBaseLine) *
                                            100
                                          ).toFixed(2)}%`
                                        : `${(
                                            ((totalProjection - totalBaseLine) /
                                              totalBaseLine) *
                                            100
                                          ).toFixed(2)}%`}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            );
                          }}
                        />
                      )}
                    </Col>
                  )}
                </Row>
              )}
              <Row style={{ width: "100%" }}>
                <Row style={{ width: "100%" }} gutter={[8, 8]}>
                  <Col
                    xl={
                      DataFilial.length > 0
                        ? filterFilialModal !== 0
                          ? 16
                          : 11
                        : 24
                    }
                    xs={12}
                  >
                    <Card
                      bordered={true}
                      style={{ width: "100%", marginBottom: 6 }}
                    >
                      <div
                        className={
                          isFullScreen3
                            ? "iframe-container fullscreen"
                            : "iframe-container"
                        }
                      >
                        <ReactEcharts
                          option={configPorcentagemBottomUP}
                          style={{
                            height: isFullScreen3 ? "100vh" : 400,
                            width: isFullScreen3 ? "100vw" : "100%",
                            padding: isFullScreen3 ? 50 : 0,
                          }}
                        />
                        <Button
                          style={
                            isFullScreen3
                              ? {
                                  marginTop: 50,
                                  marginLeft: "-50px",
                                  width: 32,
                                }
                              : {
                                  width: 32,
                                  padding: "4px 15px",
                                  position: "absolute",
                                  right: 10,
                                }
                          }
                          icon={
                            isFullScreen3 ? (
                              <FullscreenExitOutlined />
                            ) : (
                              <FullscreenOutlined />
                            )
                          }
                          onClick={toggleFullScreen3}
                        />
                      </div>
                    </Card>

                    <Card bordered={true}>
                      <div
                        className={
                          isFullScreen
                            ? "iframe-container fullscreen"
                            : "iframe-container"
                        }
                      >
                        {DataProjecaodevendas.length > 0 && (
                          <ReactEcharts
                            option={configRegional}
                            style={{
                              height: isFullScreen
                                ? "100vh"
                                : DataFilial.length > 0
                                ? 400
                                : 600,
                              width: isFullScreen ? "100vw" : "100%",
                              padding: isFullScreen ? 50 : 0,
                            }}
                          />
                        )}
                        <Button
                          style={
                            isFullScreen
                              ? {
                                  marginTop: 50,
                                  marginLeft: "-50px",
                                  width: 32,
                                }
                              : {
                                  width: 32,
                                  padding: "4px 15px",
                                  position: "absolute",
                                  right: 10,
                                }
                          }
                          icon={
                            isFullScreen ? (
                              <FullscreenExitOutlined />
                            ) : (
                              <FullscreenOutlined />
                            )
                          }
                          onClick={toggleFullScreen}
                        />
                      </div>
                    </Card>
                  </Col>
                  <Col
                    xl={
                      DataFilial.length > 0
                        ? filterFilialModal !== 0
                          ? 8
                          : 13
                        : 0
                    }
                    xs={12}
                  >
                    <Card bordered={true}>
                      <div
                        className={
                          isFullScreen2
                            ? "iframe-container fullscreen"
                            : "iframe-container"
                        }
                      >
                        {DataFilial.length > 0 && (
                          <ReactEcharts
                            option={configFilial}
                            style={{
                              height: isFullScreen2 ? "100vh" : 830,
                              width: isFullScreen2 ? "100vw" : "100%",
                              padding: isFullScreen2 ? 50 : 0,
                            }}
                          />
                        )}
                        <Button
                          style={
                            isFullScreen2
                              ? {
                                  marginTop: 50,
                                  marginLeft: "-50px",
                                  width: 32,
                                }
                              : {
                                  width: 32,
                                  padding: "4px 15px",
                                  position: "absolute",
                                  right: 10,
                                }
                          }
                          icon={
                            isFullScreen2 ? (
                              <FullscreenExitOutlined />
                            ) : (
                              <FullscreenOutlined />
                            )
                          }
                          onClick={toggleFullScreen2}
                        />
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Row>
            </Col>
            {filterFilialModal === 0 && (
              <Col xl={DataFilialTable.length > 0 ? 10 : 0} xs={6}>
                <Col
                  xl={
                    DataFilialTable.length > 0
                      ? DataFilialTable.length === 1
                        ? 15
                        : 24
                      : 24
                  }
                  xs={24}
                >
                  <div
                    className="report-grid"
                    style={{
                      flexWrap: "wrap",
                      gap: "10px",
                    }}
                  >
                    <Card
                      bordered={true}
                      size="small"
                      title="Base Line"
                      className="card-statistic"
                    >
                      <div className="report-card-content">
                        <Statistic
                          valueStyle={{ color: "#3f8600" }}
                          value={formatValue(totalBaseLine)}
                        />
                      </div>
                    </Card>
                    <Card
                      bordered={true}
                      size="small"
                      title="Projeção Y+1"
                      className="card-statistic"
                    >
                      <div className="report-card-content">
                        <Statistic
                          valueStyle={{ color: "#3f8600" }}
                          value={formatValue(totalProjection)}
                        />
                      </div>
                    </Card>
                    {totalProjection1 !== 0 && (
                      <Card
                        bordered={true}
                        size="small"
                        title="Projeção Y+2"
                        className="card-statistic"
                      >
                        <div className="report-card-content">
                          <Statistic
                            valueStyle={{ color: "#3f8600" }}
                            value={formatValue(totalProjection1)}
                          />
                        </div>
                      </Card>
                    )}
                    <Card
                      title="BL vs Y+1"
                      size="small"
                      bordered={true}
                      style={{
                        margin: "5px",
                      }}
                    >
                      <div className="report-card-content">
                        <Statistic
                          valueStyle={{
                            color:
                              totalProjection - totalBaseLine > 0
                                ? "green"
                                : totalProjection - totalBaseLine < 0
                                ? "red"
                                : "yellow",
                          }}
                          value={
                            totalBaseLine !== 0
                              ? `${(
                                  ((totalProjection - totalBaseLine) /
                                    totalBaseLine) *
                                  100
                                ).toFixed(2)}%`
                              : "0%"
                          }
                          prefix={
                            totalProjection - totalBaseLine > 0 ? (
                              <ArrowUpOutlined style={{ color: "green" }} />
                            ) : totalProjection - totalBaseLine < 0 ? (
                              <ArrowDownOutlined style={{ color: "red" }} />
                            ) : null
                          }
                        />
                      </div>
                    </Card>
                    {totalProjection1 !== 0 && (
                      <Card
                        title="BL vs Y+2"
                        size="small"
                        bordered={true}
                        style={{
                          margin: "5px",
                        }}
                      >
                        <div className="report-card-content">
                          <Statistic
                            valueStyle={{
                              color:
                                totalProjection1 - totalBaseLine > 0
                                  ? "green"
                                  : totalProjection1 - totalBaseLine < 0
                                  ? "red"
                                  : "yellow",
                            }}
                            value={
                              totalBaseLine !== 0
                                ? `${(
                                    ((totalProjection1 - totalBaseLine) /
                                      totalBaseLine) *
                                    100
                                  ).toFixed(2)}%`
                                : "0%"
                            }
                            prefix={
                              totalProjection1 - totalBaseLine > 0 ? (
                                <ArrowUpOutlined style={{ color: "green" }} />
                              ) : totalProjection1 - totalBaseLine < 0 ? (
                                <ArrowDownOutlined style={{ color: "red" }} />
                              ) : null
                            }
                          />
                        </div>
                      </Card>
                    )}
                  </div>
                </Col>
                {DataFilialTable.length > 0 && (
                  <Table
                    columns={filteredColumns}
                    size="small"
                    pagination={false}
                    dataSource={DataFilialTable}
                    scroll={{ y: 130 * 5 }}
                    footer={() => {
                      return (
                        <table style={{ width: "100%" }}>
                          <tbody>
                            <tr>
                              <td style={{ fontWeight: "bold", width: "38%" }}>
                                Total
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                }}
                              >
                                {formatValue(totalBaseLine)}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                }}
                              >
                                {formatValue(totalProjection)}
                              </td>
                              {totalProjection1 > 0 && (
                                <td
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {formatValue(totalProjection1)}
                                </td>
                              )}
                              <td
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                }}
                              >
                                {totalProjection1 > 0 ? (
                                  totalProjection +
                                    totalProjection1 -
                                    totalBaseLine >
                                  0 ? (
                                    <ArrowUpOutlined
                                      style={{ color: "green" }}
                                    />
                                  ) : totalProjection +
                                      totalProjection1 -
                                      totalBaseLine <
                                    0 ? (
                                    <ArrowDownOutlined
                                      style={{ color: "red" }}
                                    />
                                  ) : null
                                ) : totalProjection - totalBaseLine > 0 ? (
                                  <ArrowUpOutlined style={{ color: "green" }} />
                                ) : totalProjection - totalBaseLine < 0 ? (
                                  <ArrowDownOutlined style={{ color: "red" }} />
                                ) : null}

                                {totalProjection1 > 0
                                  ? `${(
                                      ((totalProjection +
                                        totalProjection1 -
                                        totalBaseLine) /
                                        totalBaseLine) *
                                      100
                                    ).toFixed(2)}%`
                                  : `${(
                                      ((totalProjection - totalBaseLine) /
                                        totalBaseLine) *
                                      100
                                    ).toFixed(2)}%`}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      );
                    }}
                  />
                )}
              </Col>
            )}
          </Row>
        </div>
      </Col>
    </Row>
  );
}
