import { Alert, message } from "antd";
import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from "axios";

const url = window.location.origin;

declare module "axios" {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

abstract class Http {
  protected readonly instance: AxiosInstance;

  public constructor() {
    const config = {
      baseURL:
        url === "http://localhost:3000"
          ? process.env.REACT_APP_API_URL
          : "backendcropland",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=utf-8",
      },
      timeout: 300000,
    };

    this.instance = axios.create(config);
    this._initializeRequestInterceptor();
    this._initializeResponseInterceptor();
  }

  private _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this._handleResponse,
      this._handleError
    );
  };

  private _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(
      this._handleRequest as any,
      this._handleError
    );
  };

  private _handleRequest = (config: AxiosRequestConfig) => {
    const token = window.localStorage.token;
    const historico = localStorage.getItem("historico");
    console.log("histo" + historico);

    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    if (historico) {
      config.headers["historico"] = historico;
    }
    localStorage.setItem("historico", "");

    return config;
  };

  private _handleResponse = (response: AxiosResponse) => {
    const { config } = response;
    if (config.method === "delete") {
      message.success("Deletado com sucesso");
    } else if (config.method === "put") {
      message.success("Salvo com sucesso");
    } else if (
      config.method === "post" &&
      !config.url?.includes("login") &&
      !config.url?.includes("campaign") &&
      !config.url?.includes("elect-product") &&
      !config.url?.includes("my-extract")
    ) {
      message.success("Atualizado com sucesso");
    }
    return response.data;
  };

  protected _handleError = (error: any) => {
    if (error.response.status === 403) {
      localStorage.clear();
      window.location.href = "/";
    }
    console.log(error);
    const isDashboard = window.location.pathname.includes("/Dashboard");
    if (!isDashboard) {
      if (error.response.data.error.name === "SequelizeUniqueConstraintError") {
        message.error("Dados já cadastrados");
      } else {
        message.error("Ocorreu um erro ao processar a solicitação");
      }
    }
    return Promise.reject(error);
  };
}

export default Http;
