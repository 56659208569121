import { useEffect, useState, useCallback } from "react";
import { Row, Col, Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import ParameterService from "../../../services/Global";
import { useAppSelector, useAppDispatch } from "../../../hooks";
import { Loading } from "../../../components/Loading";
import { selectedCompany } from "../../Parameters/Reducer";
import { logout } from "../../../modules/Auth/Reducer";
import { decrement } from "../../../modules/Parameters/Reducer";
import { persistor } from "../../../store";
import croplandIcon from "../../../assets/logo.svg";
import { Container, Card, Icon, Title, SubTitle } from "./styles";

interface CompanyProps {
  id: number;
  name: string;
  logo: string;
}
interface CompaniesProps {
  id: number;
  user_id: number;
  company: CompanyProps;
}

export function SelectCompany() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const userId = useAppSelector((state) => state.auth.user.id);
  const [companies, setCompanies] = useState<CompaniesProps[]>([]);

  const parameters = useAppSelector((state) => state.auth.user.access);

  console.log(parameters);

  useEffect(() => {
    const cookie = getCookieConsentValue();
    if (cookie === "false") {
      //exclir o cookie
      logoutRedirect();

      return;
    }

    loadCompanies();

    async function loadCompanies() {
      try {
        setLoading(true);
        const response = await ParameterService.getCompanies(userId);
        setCompanies(response);
      } catch (err) {
        message.error(err.response.data.message);
      } finally {
        setLoading(false);
      }
    }
  }, [userId]);

  const handleCompany = useCallback(
    (company: CompanyProps) => {
      dispatch(selectedCompany(company));
      navigate("/select-module");
    },
    [dispatch, navigate]
  );

  useEffect(() => {
    if (companies.length === 1) {
      handleCompany(companies[0].company);
    }
  }, [handleCompany, companies]);

  const logoutRedirect = () => {
    message.error("Você recusou os cookies");
    dispatch(decrement());
    dispatch(logout());
    persistor.purge();
    navigate("/");
    //funçar exclir o cookie
    localStorage.clear();
    sessionStorage.clear();
    if ("caches" in window) {
      caches.keys().then(function (cacheNames) {
        cacheNames.forEach(function (cacheName) {
          caches.delete(cacheName);
        });
      });
    }
    const deleteCookie = (name: string) => {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    };

    // Exemplo de uso para excluir um cookie chamado "meuCookie"
    deleteCookie("CroplandCookie");
  };

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <Row gutter={10}>
          <Col span={24}>
            <Title>Empresas</Title>

            <SubTitle>Selecione a empresa</SubTitle>
            {parameters === 10 && (
              <Button
                type="primary"
                style={{ left: 10 }}
                onClick={() => navigate("/Owner_empresas")}
              >
                <img
                  width={40}
                  style={{ marginLeft: "-20px", display: "none" }}
                  src=""
                  alt=""
                />
                Owner
              </Button>
            )}
          </Col>
          {companies.map((value) => (
            <Col xs={24} sm={12} md={12} lg={8} key={value.id}>
              <Card>
                <Icon
                  src={value.company.logo ? value.company.logo : croplandIcon}
                />
                {value.company.name}
                <Button
                  onClick={() => handleCompany(value.company)}
                  type="primary"
                  children="Acessar"
                />
              </Card>
            </Col>
          ))}
        </Row>
      )}
      <CookieConsent
        location="top"
        buttonText="Aceitar"
        acceptOnScrollPercentage={50}
        declineButtonText="Recusar / Sair!"
        cookieName="CroplandCookie"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
        overlay
        acceptOnScroll={false}
        onAccept={() => {
          message.success(`Você aceitou os cookies `, 2);
        }}
        enableDeclineButton
        onDecline={() => {
          logoutRedirect();
        }}
      >
        Este site usa cookies para melhorar sua experiência.{" "}
        <span style={{ fontSize: "10px" }}>
          {" "}
          <a href="/privacidade">Politicas de Privacidade</a>
        </span>
      </CookieConsent>
    </Container>
  );
}
