import { useState } from "react";
import { Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
//import { Auth } from "aws-amplify";

import { ForgotPassword, SigninButton } from "./styles";
import { FormAuth } from "../../../components/Auth/FormAuth";
import { useAppDispatch } from "../../../hooks";
import { signin } from "../Reducer";
import UserService from "../../../services/Global/User";
import { useMessage } from "../../../hooks/useMessage";

interface FormLoginProps {
  email: string;
  password: string;
}

interface FormNewPassword {
  newPassword: string;
  confirmPassword: string;
}

interface FormConfirmSignUp {
  code: string;
}

export function Signin() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [user, setUSer] = useState({});
  const [showConfirmPAssword, setShowConfirmPAssword] = useState(false);
  const [showConfirmSignUp, setShowConfirmSignup] = useState(false);
  const [email, setEmail] = useState("");
  const { showMessage, contextHolder } = useMessage();
  async function findUser(email: string) {
    try {
      const user = await UserService.findByEmail(email.replace(/ /g, ""));
      dispatch(signin(user));
      navigate("/");
    } catch (err) {
      showMessage("error", err.response.data.message);
      setLoading(false);
    }
  }

  async function onFinish(data: FormLoginProps) {
    try {
      setLoading(true);

      const response = await UserService.Login(data.email, data.password);

      if (response.signInUserSession) {
        localStorage.clear();
        localStorage.setItem(
          "token",
          response.signInUserSession.idToken.jwtToken
        );
      }

      if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
        setUSer(response);
        setShowConfirmPAssword(true);
        setLoading(false);
      } else {
        await findUser(data.email);
      }
    } catch (err) {
      console.log(err);
      if (err.message === "User is not confirmed.") {
        setShowConfirmSignup(true);
      }
      showMessage(
        "error",
        "usuário e/ou senha invalido favor validar os dados e tentar novamente."
      );
      setLoading(false);
    }
  }

  async function handleCompletePassword(data: FormNewPassword) {
    if (data.newPassword === data.confirmPassword) {
      await UserService.ChangePassword(email, data.newPassword);
      showMessage("success", "Senha alterada com sucesso");
      localStorage.clear();
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      showMessage("error", "Senhas não conferem");
    }
  }

  async function handleConfirmSignup(data: FormConfirmSignUp) {
    // await Auth.confirmSignUp(email, data.code);
    await findUser(email.replace(/ /g, ""));
  }

  function renderLoginForm() {
    return (
      <Form onFinish={onFinish}>
        <Form.Item name="email" rules={[{ required: true }]}>
          <Input
            size="large"
            placeholder="E-mail"
            value={email.replace(/ /g, "")}
            onChange={(e) =>
              setEmail(e.target.value.replace(/ /g, "").toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true }]}>
          <Input.Password size="large" placeholder="Senha" />
        </Form.Item>
        <ForgotPassword onClick={() => navigate("/forgot-password")}>
          Esqueceu sua senha?
        </ForgotPassword>
        <Form.Item>
          <SigninButton loading={loading} htmlType="submit" size="large" block>
            Acessar
          </SigninButton>
        </Form.Item>
      </Form>
    );
  }

  function renderConfirmPassword() {
    return (
      <Form onFinish={handleCompletePassword}>
        <Form.Item
          name="newPassword"
          rules={[{ required: true, min: 8, max: 16 }]}
        >
          <Input.Password size="large" placeholder="Nova Senha" />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          rules={[{ required: true, min: 8, max: 16 }]}
        >
          <Input.Password size="large" placeholder="Confirmar Senha" />
        </Form.Item>
        <Form.Item>
          <SigninButton loading={loading} htmlType="submit" size="large" block>
            Alterar senha
          </SigninButton>
        </Form.Item>
      </Form>
    );
  }

  function renderConfirmSignUp() {
    return (
      <Form onFinish={handleConfirmSignup}>
        <Form.Item name="code" rules={[{ required: true }]}>
          <Input size="large" placeholder="Código de verificação" />
        </Form.Item>
        <Form.Item>
          <SigninButton loading={loading} htmlType="submit" size="large" block>
            Verificar conta
          </SigninButton>
        </Form.Item>
      </Form>
    );
  }

  function renderData() {
    if (showConfirmSignUp) {
      return renderConfirmSignUp();
    }
    if (showConfirmPAssword) {
      return renderConfirmPassword();
    } else {
      return renderLoginForm();
    }
  }

  return (
    <FormAuth
      title="Bem vindo"
      subTitle="Informe seus dados de acesso para continuar"
    >
      {contextHolder}
      {renderData()}
    </FormAuth>
  );
}
