import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Select, Form, message } from "antd";
import {
  useAppDispatch,
  useAppSelector,
  useParameterName,
  compareLabels,
} from "../../../../hooks";
import CompanyCommercialStructureService from "../../../../services/Company/CommercialStructure";
import { setCommercialStructureLevel4 } from "../../../../entities/Reducer";
import { FormInstance } from "antd/es/form";
interface Level3Props {
  formRef: RefObject<FormInstance<any>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}
interface Level3ValueProps {
  label: string;
  value: number;
}

export function Level3({ formRef, loading, setLoading }: Level3Props) {
  const dispatch = useAppDispatch();
  const recordsLevel3 = useAppSelector(
    (state) => state.entities.commercialStructure.level3
  );
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const labelLevel3 = useParameterName("LEVEL3_LABEL");
  const [level3, setLevel3] = useState<Level3ValueProps[]>([]);

  useEffect(() => {
    if (recordsLevel3) {
      const array = recordsLevel3.map((level) => {
        return {
          label: level?.level3_external_name,
          value: level?.level3_id,
        };
      });
      setLevel3(array);
    } else {
      setLevel3([]);
    }
  }, [recordsLevel3]);
  useEffect(() => {
    level3.length === 1 && onChange(recordsLevel3[0].level3_id);
  }, [level3]);
  async function onChange(value: number) {
    try {
      if (value) {
        setLoading(true);
        const response =
          await CompanyCommercialStructureService.getAllCommercialStrucuteLevel4(
            companyId,
            value
          );
        dispatch(setCommercialStructureLevel4(response));
        formRef.current?.setFieldsValue({
          level0: Number(localStorage.getItem("level0")),
          level3: value,
          level4: response.length === 1 ? response[0]?.level4_id : null,
        });
      } else {
        dispatch(setCommercialStructureLevel4([]));
        formRef.current?.setFieldsValue({
          level3: null,
          level4: null,
        });
      }
    } catch (err) {
      message.error(err.response.data.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Form.Item name="level3" rules={[{ required: true, message: "" }]}>
      <Select
        disabled={!level3.length}
        loading={loading}
        onChange={onChange}
        placeholder={labelLevel3?.value}
        defaultValue={
          recordsLevel3.length === 1 ? recordsLevel3[0]?.level3_id : undefined
        }
        allowClear
      >
        {level3.sort(compareLabels).map((type) => (
          <Select.Option key={type.value} value={type.value}>
            {type.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}
