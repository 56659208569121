import { Button, Card, Col, message, Row } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Breadcrumb } from "../../../components/Breadcrumb";
import { Loading } from "../../../components/Loading";
import { useAppSelector } from "../../../hooks";
import PgcService from "../../../services/Pgc";
import { Content, Title } from "./styles";
import AcesseType from "../../../services/Rebate/AcesseType";

interface DashboardProps {
  [name: string]: {
    permission?: boolean;
    rebate_setup?: boolean;
    rebate_extract?: boolean;
    rebate_setup_campaigns?: boolean;
    rebate_setup_campaigntype?: boolean;
    rebate_setup_eligibleproduct?: boolean;
    status: string;
    label: string;
    color: string;
    percent: number;
    period_open: boolean;
    period_past: boolean;
    initial_date: string;
    final_date: string;
  };
}

export function Setup_rebate() {
  const navigate = useNavigate();
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const [permission, setPermission] = useState<any>([]);
  const [parameter, setParameter] = useState<any>(false);
  const [user, setUser] = useState<any>(false);
  const [commercialStructure, setCommercialStructure] = useState<any>(false);
  const [commercialStructureUser, setCommercialStructureUser] =
    useState<any>(false);
  const [cultivation, setCultivation] = useState<any>(false);
  const [groupProduct, setGroupProduct] = useState<any>(false);
  const [product, setProduct] = useState<any>(false);
  const [accessType, setAccessType] = useState<any>(false);
  const [createClient, setCreateClient] = useState<any>(false);
  const [updateSeller, setUpdateSeller] = useState<any>(false);
  const [dashboard, setDashboard] = useState<DashboardProps>({});
  const [businessType, setBusinessType] = useState<any>(false);
  const user_id = useAppSelector((state) => state.auth.user.id);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    loadPermission();
  }, [companyId]);
  async function loadPermission() {
    setLoading(true);
    try {
      setLoading(true);
      const response = await AcesseType.getAcessType(companyId, user_id);
      setDashboard(response);
    } catch (err) {
      message.error(err.response.data.message);
    } finally {
      setLoading(false);
    }
  }
  return (
    <Breadcrumb path="Setup">
      {loading ? (
        <Loading />
      ) : (
        <Row >
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col xl={6} sm={24} xs={24} key={11}>
                <Card bordered={false}>
                  <Content>
                    <Title>Tipo de Campanha</Title>
                    <Button
                      disabled={!dashboard[0]?.rebate_setup_campaigntype}
                      type="primary"
                      onClick={() => navigate("/rebate/campaign-type")}
                    >
                      Acessar
                    </Button>
                  </Content>
                </Card>
              </Col>

              <Col xl={6} sm={24} xs={24} key={8}>
                <Card bordered={false}>
                  <Content>
                    <Title>Campanhas</Title>
                    <Button
                      disabled={!dashboard[0]?.rebate_setup_campaigns}
                      type="primary"
                      onClick={() => navigate("/rebate/setup/campaign")}
                    >
                      Acessar
                    </Button>
                  </Content>
                </Card>
              </Col>

              <Col xl={6} sm={24} xs={24} key={9}>
                <Card bordered={false}>
                  <Content>
                    <Title>Eleger Produtos</Title>
                    <Button
                      disabled={!dashboard[0]?.rebate_setup_eligibleproduct}
                      type="primary"
                      onClick={() => navigate("/rebate/elect-product")}
                    >
                      Acessar
                    </Button>
                  </Content>
                </Card>
              </Col>

              {/* {accessType ? (
            <Col xl={6} sm={24} xs={24} key={9}>
              <Card bordered={false}>
                <Content>
                  <Title>Metas</Title>
                  <Button
                    disabled={!accessType}
                    type="primary"
                    onClick={() => navigate("/rebate/setup/metas")}
                  >
                    Acessar
                  </Button>
                </Content>
              </Card>
            </Col>
          ) : null} */}
            </Row>
          </Col>
        </Row>
      )}
    </Breadcrumb>
  );
}
