import { Breadcrumb } from "../../../components/Breadcrumb";
import { 
  Col, 
  Row, 
  ColHeader, 
  ItemsContainer, 
  Input ,
  Select,
  StructureItemContainerse} from "./styles";
import { Button, message, Modal, Typography, InputNumber } from "antd";
import { FiPlus } from "react-icons/fi";
import { StructureItem, StructureItemProduct, StructureItemAward, StructureItemMeta } from "./StructureItem";
import { CommercialStructureProps,CompanyCampaign,Industry } from "../../../entities/DTO";

import { useEffect, useState, useCallback  } from "react";
import CommercialStructureService from "../../../services/Company/CommercialStructure";
import ElectProductService from "../../../services/ElectProduct";
import { useAppSelector } from "../../../hooks";
import { Loading } from "../../../components/Loading";

interface TasksProps {
  id: number;
  industry_id:number;
  campaign_name:string;
  campaign_id:number;
  product: {
    id: number;
    company_id: number;
    industry_id: number;
    business_type_id: number;
    segment_name: string;
    sub_segment_name: string;
    brand_name: string;
    product_name: string;
    product_external_code: string | null;
    sku_external_code: string | null;
    sku_name: string;
    price_base_line: number;
    price_year1: number;
    price_year2: number;
    price_year3: number;
    price_year4: number;
    price_year5: number;
    type_name: string;
    KG_L: string | null;
    company_product_group_id: number;
    createdAt: string;
    updatedAt: string;
  };
}

interface CompanyAward {
 
  id:number;
  award: number;
  meta:number ;

  
}

interface Product {
  id: number;
  company_id: number;
  industry_id: number;
  business_type_id: number;
  segment_name: string;
  sub_segment_name: string;
  brand_name: string;
  product_name: string;
  product_external_code: null | string;
  sku_external_code: null | string;
  sku_name: string;
  price_base_line: number;
  price_year1: number;
  price_year2: number;
  price_year3: number;
  price_year4: number;
  price_year5: number;
  type_name: string;
  KG_L: null | string;
  company_product_group_id: number;

}

const { Option } = Select;


export function ElectProduct() {
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const parameters = useAppSelector((state) => state.entities.parameter);

  const [level0Label, setLevel0Label] = useState<string | null | undefined>(
    null
  );
  const [level0List, setLevel0List] = useState<CommercialStructureProps[]>([]);
  const [isLevel0ListLoading, setLevel0ListLoading] = useState<boolean>(false);
  //campaign
  const [isCampagnLoading, setCampagnLoading] = useState<boolean>(false);
  const [isIndustryLoading, setIndustryLoading] = useState<boolean>(false);
  const [isProductsLoading, setProductsLoading] = useState<boolean>(false);
  const [isAwardsLoading, setAwardsLoading] = useState<boolean>(false);
  const [isMetaLoading, setMetaLoading] = useState<boolean>(false);
  const [CampaignList, setCampaignList] = useState<CompanyCampaign[]>([]);
  const [CampaignproductList, setCampaignproductList] = useState<TasksProps[]>([]);
  //const [campaignAwardsList, setCampaignAwardsList] = useState<CompanyAward[]>([]);
  const [campaignAwardsList, setCampaignAwardsList] = useState<number | null>(null);
  const [campaignMetaList, setCampaignMetaList] = useState<number | null>(null);
 // const [CampaignAwardsList, setCampaignAwardsList] = useState<CompanyAward | null>(null);
  const [IndustryproductList, setIndustryproductList] = useState<Product[]>([]);
  const [listInitiatives, setListInitiatives] = useState<Product[]>([]);
  const [IndustryList, setIndustryList] = useState<string | null>(null);
  const [IndustryId, setIndustryId] = useState<number | null>(null);
  // const [selectedCampaign, setselectedCampaign] =
  //   useState<CompanyCampaign | null>(null);

    const [selectedProduct, setselectedProduct] =
    useState<any>();

    const [selectedAward, setselectedAward] =
    useState<any>([]);


    const [selectedMeta, setselectedMeta] =
    useState<any>([]);


    const [selectedCampaignse, setselectedCampaignse] =
    useState<number | null>(null);

    const [level0Name, setlevel0Name] = useState<any>(0);
    const [level0Value, setlevel0Value] = useState<any>(0);
    const [level1Value, setlevel1Value] = useState<any>(0);
    const [level1Name, setlevel1Name] = useState<any>("");
  //   const [selectedInitiativeId, setSelectedInitiativeId] = useState<
  //   number | null
  // >(null);

  const [selectedInitiativeId, setSelectedInitiativeId] = useState<any>([]);
  const [selectedCampaignId, setSelectedCampaignId] = useState<any>([]);

  const [selectedCampaign, setselectedCampaign] =
    useState<any>([]);

 

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalAward, setShowModalAward] = useState<boolean>(false);
  const [showModalMeta, setShowModalMeta] = useState<boolean>(false);
  const [selectedLevel, setSelectedLevel] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<number | null>(null);

  let status = 'A'; 

  
  const handleChangeAward = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const formattedValue = Number(value) / 100; // Convert to decimal value
    console.log(formattedValue)
    setlevel0Name(formattedValue);
    setlevel0Value(formattedValue.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })); // Format as currency
  };


  const handleChangeMeta = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const formattedValue = Number(value); // Convert to decimal value
    console.log(formattedValue)
    setlevel1Name(formattedValue);
    setlevel1Value(formattedValue); // Format as currency
  };



  async function handleClickCampaign(campaign: CompanyCampaign) {


    if (selectedCampaign?.campaign_id === campaign.campaign_id) {
      setselectedCampaign(null);
    } else {
      setselectedCampaign(campaign);
      
      
    }
  }

  async function handleClickProduct(product: TasksProps | null | undefined) {
    //console.log("prudoto"+product?.id)

    setCampaignAwardsList(null);
    setCampaignMetaList(null)


      if(product?.id){
        console.log(product?.id+"WTFWTFWTF")
      setselectedProduct(product);

   
      
      await getProductsInfo(selectedCampaignId,product?.id);
      }
      //await getLevel1List(product.campaign_id);
    
  }

  async function handleClickAward(award: number | null) {
    
  }

  async function handleClickMeta(award: number | null) {
   
  }


  async function handleSelectCampaign(campaign: CompanyCampaign) {
  
        setIndustryList(null)
        setIndustryId(null)
        setSelectedInitiativeId(null)

        setCampaignAwardsList(null);
        setselectedProduct(null)
        setselectedProduct(undefined)

      

    if (selectedCampaign?.campaign_id === campaign.campaign_id) {
      setSelectedCampaignId(campaign.campaign_id);
      setselectedCampaign(campaign);
      await getProductList(campaign.campaign_id);
      await getClientList(campaign.industry_id);

      await getProductIndustryList(campaign.industry_id);
      
    } else {
      setSelectedCampaignId(campaign.campaign_id);
      setselectedCampaign(campaign);
      await getProductList(campaign.campaign_id);
      await getClientList(campaign.industry_id);

      await getProductIndustryList(campaign.industry_id);
      

    }
  }

  async function getProductList(campaignid: number) {
    try {
      //console.log("0000000000"+campaignid);
      setProductsLoading(true);
      const response: TasksProps[] =
        await ElectProductService.getCampaignProducts(
          campaignid,
          
        );
       // console.log("aasdasdasds"+[...response]);
        setCampaignproductList([...response]);
    } catch (e) {
      console.error("Falha ao buscar lista de produtos");
      console.error(e);
      message.error("Falha ao buscar lista de Produtos");
    } finally {
      setProductsLoading(false);
    }
  }



  async function getProductsInfo(campaignid: number,productid: number | undefined) {
    try {
      
      setAwardsLoading(true);
      setMetaLoading(true);
    //  console.log("aasdasdasds");
      const response: CompanyAward =
        await ElectProductService.getProductsInfo(
          campaignid,
          productid
          
        );
      //  console.log("award"+response.award)
        if (response &&  response.award !== undefined && response.award !== null) {
          setCampaignAwardsList(response.award);

          if(selectedCampaign.tp_meta === 'S'){
          setCampaignMetaList(response.meta)
          }

        } else {
          setCampaignAwardsList(null);
        }
    } catch (e) {
      if (e.response && e.response.status === 400) {
        // Handle 400 error specifically
        handleSelectCampaign(selectedCampaign);
      } else {
        console.error("Falha ao buscar lista de premios");
        console.error(e);
        message.error("Falha ao buscar lista de premios");
      }
    } finally {
      setAwardsLoading(false);
      setMetaLoading(false);
    }
  }

  

  async function getClientList(Industry_id: number) {
    try {
     // console.log("che"+Industry_id);
      setIndustryLoading(true);
      const response: Industry =
        await ElectProductService.getIndustry(
          Industry_id,
          
        );
      //  console.log("as"+response.name);
        setIndustryList(response.trading_name);
        setIndustryId(response.id);

    } catch (e) {
      console.error("Falha ao buscar lista de level 2");
      console.error(e);
      message.error("Falha ao buscar lista de Diretorias");
    } finally {
      setIndustryLoading(false);
    }
  }


  
 

  async function getCampaignList() {
    try {
      setCampagnLoading(true);
      const response: CompanyCampaign[] =
        await ElectProductService.getCampaignTeste(
          companyId, {status: status}
        );
      setCampaignList([...response]);
    } catch (e) {
      console.error("Falha ao buscar lista de Campanhas");
      console.error(e);
      message.error("Falha ao buscar lista de Campanhas");
    } finally {
      setCampagnLoading(false);
    }
  }

  



  useEffect(() => {
    getCampaignList();
  }, [companyId]);

  useEffect(() => {
    setLevel0Label(
      parameters.find((value) => value.name === "LEVEL0_LABEL")?.value
    );
  
  }, [parameters]);

  function renderCampaignList() {
    if (isCampagnLoading) {
      return <Loading />;
    }

   // console.log(CampaignList)

    return CampaignList.map((item, i) => (
      <StructureItem
        isActive={selectedCampaign?.campaign_id === item.campaign_id}
        handleClick={() => handleClickCampaign(item)}
        key={i}
        campaign={item}
        level={0}
        handleUpdate={() => handleUpdateCampaign(item)}
        showUpdate={
          selectedCampaign?.campaign_id === item.campaign_id &&
          CampaignList.length === 0 &&
          !isCampagnLoading
        }
        showDelete={
          selectedCampaign?.campaign_id === item.campaign_id &&
          CampaignList.length === 0 &&
          !isCampagnLoading
        }
        handleDelete={async () => await handleDeleteCampaign(item.campaign_id, 0)}
      />
    ));
  }

  // function renderProductList() {
  //   if (isProductsLoading) {
  //     return <Loading />;
  //   }

  //   //console.log(CampaignproductList)

  //   return CampaignproductList.map((item, i) => (
  //     <StructureItemProduct
  //       isActive={selectedProduct?.id === item.id}
  //       handleClick={() => handleClickProduct(item)}
  //       key={i}
  //       product={item}
  //       level={0}
        
  //       // showUpdate={
  //       //   selectedCampaign?.campaign_id === item.campaign_id &&
  //       //   CampaignList.length === 0 &&
  //       //   !isCampagnLoading
  //       // }
  //       showDelete={
  //         //selectedProduct?.campaign_id === item.campaign_id &&
  //         //CampaignproductList.length === 0 &&
  //         !isProductsLoading
  //       }
  //       handleDelete={async () => await handleDeleteProduct(item.id)}
  //     />
  //   ));
  // }

  const renderProductList = useCallback(() => {
    if (isProductsLoading) {
      return <Loading />;
    }
  
    return CampaignproductList.map((item, i) => (
      <StructureItemProduct
        isActive={selectedProduct?.id === item.id}
        handleClick={() => handleClickProduct(item)}
        key={i}
        product={item}
        level={0}
        showDelete={!isProductsLoading}
        handleDelete={async () => await handleDeleteProduct(item.id)}
      />
    ));
  }, [isProductsLoading, CampaignproductList, selectedProduct]);

  function renderIndustryList() {
    if (isIndustryLoading) {
      return <Loading />;
    }

   //console.log("??"+IndustryList)

    return <span>{IndustryList?IndustryList:"" }</span>
  }

  

  function renderCampaignListSelect() {
    if (isCampagnLoading) {
      return <Loading />;
    }

    //console.log(CampaignList)

    return <Select
    placeholder="Selecione "
    value={selectedCampaign?.campaign_id}
    showSearch
    popupMatchSelectWidth={false}
    optionFilterProp="children"
    onChange={(event) => {
      const campaignId = parseInt(event as string);
      const selected = CampaignList.find((c) => c.campaign_id === campaignId!)!;
      if (selectedCampaign?.campaign_id === campaignId) {
        setselectedCampaign(selected);
        //console.log(selectedCampaign)
        // getLevel1List(campaign.campaign_id);
      } else {
        setselectedCampaign(selected);
         handleSelectCampaign(selected)
       // console.log(selectedCampaign)
      }
    }}
  >
   
    {CampaignList.map((campaign) => (
      <Option key={campaign.campaign_id} value={campaign.campaign_id}>
        {campaign.campaign_name}
      </Option>
    ))}
  </Select>
 
  
  }


  function renderAwardListSelect() {
    if (isAwardsLoading) {
      return <Loading />;
    }

   // console.log("??"+campaignAwardsList)

    if(campaignAwardsList){
    return <StructureItemAward
        isActive={selectedAward}
        handleClick={() => handleClickAward(campaignAwardsList)}
        award={campaignAwardsList.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) }
       // meta={(campaignMetaList).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
        level={0}
        handleUpdate={() => handleUpdateAward(campaignAwardsList)}
        showUpdate={
          
          !isAwardsLoading
        }
        showDelete={!isAwardsLoading}
        handleDelete={async () => await handleDeleteAward(selectedProduct?.id)}
        />
           
    }
 
  
  }

  function renderMetaListSelect() {
    if (isMetaLoading) {
      return <Loading />;
    }

   // console.log("??"+campaignAwardsList)

    if(campaignMetaList){
    return <StructureItemMeta
        isActive={selectedMeta}
        handleClick={() => handleClickMeta(campaignMetaList)}
       // meta={(campaignMetaList * selectedProduct?.product?.price_base_line ?? 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
        meta={campaignMetaList+" Vol. (Kg/Lt/Sc)"}
        handleUpdate={() => handleUpdateMeta(campaignMetaList)}
        showUpdate={
          
          !isMetaLoading
        }
        level={0}
        showDelete={!isMetaLoading}
        handleDelete={async () => await handleDeleteMeta(selectedProduct?.id)}
        />
           
    }
 
    
  }

  

  function handleUpdateCampaign(item: CompanyCampaign) {
    setSelectedId(item.campaign_id);
    // setSelectedLevel(Number(item.level_type));

    
    setShowModal(true);
  }

  function handleUpdateAward(award: number) {
    
    setlevel0Value(award.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }));
    // setSelectedLevel(Number(item.level_type));

    
    setShowModalAward(true);
  }

  function handleUpdateMeta(meta: number) {
   // console.log("award"+award)
    setlevel1Value(meta);
    // setSelectedLevel(Number(item.level_type));

    
    setShowModalMeta(true);
  }


  
  async function handleDeleteCampaign(id: number, level: number) {
    try {
      await CommercialStructureService.deleteCommercialStrucuteItem(
        companyId,
        id
      );

     
    } catch (e) {
      console.error("Falha ao salvar item");
      console.error(e);
    }
  }

  async function handleDeleteProduct(ProductId: number) {

    try {
      setselectedProduct(null);
      setselectedProduct(undefined)
     // console.log("delete"+ProductId);
      await ElectProductService.DeleteCompanyProduct(
        selectedCampaignId,
        ProductId
      );

      clearData();
     
    } catch (e) {
      console.error("Falha ao deletar item");
      console.error(e);
    }
  }

  async function handleDeleteAward(ProductId: number | undefined) {

    
    
    let id = ProductId
    try {
     // console.log("delete"+ProductId);
      await ElectProductService.updateAward(selectedCampaignId,
        ProductId, 
        {
          award: null
         
        }
        
      );

      getDataAward();

     
    } catch (e) {
      console.error("Falha ao deletar item");
      console.error(e);
    }
  }

  async function handleDeleteMeta(ProductId: number | undefined) {

    
    
    
    try {
     // console.log("delete"+ProductId);
      await ElectProductService.updateAward(selectedCampaignId,
        ProductId, 
        {
          meta: null
         
        }
        
      );

      getDataAward();

     
    } catch (e) {
      console.error("Falha ao deletar item");
      console.error(e);
    }
  }


  // async function handleDelete(id: number, level: number) {
  //   try {
  //     await CommercialStructureService.deleteCommercialStrucuteItem(
  //       companyId,
  //       id
  //     );

  //     switch (level) {
  //       case 1:
  //         setSelectedLevel1(null);
  //         await getLevel1List(selectedLevel0?.campaign_id ?? 0);
  //         break;
  //       case 2:
  //         await getLevel2List(selectedLevel1?.campaign_id ?? 0);
  //         setSelectedLevel2(null);
  //         break;
  //       case 3:
  //         await getLevel3List(selectedLevel2?.campaign_id ?? 0);
  //         setSelectedLevel3(null);
  //         break;
  //       case 4:
  //         await getLevel4List(selectedLevel3?.campaign_id ?? 0);
  //         setSelectedLevel4(null);
  //         break;
  //       default:
  //         await getLevel0List();
  //         setSelectedLevel0(null);
  //         break;
  //     }
  //   } catch (e) {
  //     console.error("Falha ao salvar item");
  //     console.error(e);
  //   }
  // }

  async function handleSave() {
    try {
      if (selectedInitiativeId) {
       
        await ElectProductService.createCompanyProduct(
          selectedCampaignId, {
            campaign_id: selectedCampaignId,
            product_id: selectedInitiativeId,
          }
          
        );
      } else {
        // await CommercialStructureService.createCommercialStrucuteItem(
        //   companyId,
       
        
      }
      setShowModal(false);
      clearData();

      
    } catch (e) {
      console.error("Falha ao salvar item");
      console.error(e);
    }
  }

  async function handleSaveAward() {
    try {
      console.log("save"+level0Name)
      if (level0Name) {
       
        await ElectProductService.updateAward(selectedCampaignId,
          selectedProduct?.id, 
          {
            award: level0Name
           
          }
          
        );
        
      }
      setShowModalAward(false);
      getDataAward();

      
    } catch (e) {
      console.error("Falha ao salvar item");
      console.error(e);
    }
  }

  async function handleSaveMeta() {
    try {
      console.log("save"+level1Name)
      if (level1Name) {
       
        await ElectProductService.updateAward(selectedCampaignId,
          selectedProduct?.id, 
          {
            meta: level1Name
           
          }
          
        );
        
      }
      setShowModalMeta(false);
      getDataAward();

      
    } catch (e) {
      console.error("Falha ao salvar item");
      console.error(e);
    }
  }

  function buildItemBody(): any {
    return {
      company_id: companyId,
      
    };
  }

  function handleCancel() {
    setShowModal(false);
    clearData();
  }

  function handleCancelAward() {
    setShowModalAward(false);
    
    clearDataAward();
  }

  function handleCancelMeta() {
    setShowModalMeta(false);
    
    clearDataAward();
  }

  // function handleOpenNewModal(level: number) {
  //   setSelectedLevel(level);
  //   setShowModal(true);

  //   if (level === 1) {
  //     setlevel0Id(selectedLevel0?.campaign_id ?? null);
  //     setlevel0Name(selectedLevel0?.level0_external_name ?? "");
  //   } else if (level === 2) {
  //     setlevel0Id(selectedLevel1?.level0_id ?? null);
  //     setlevel0Name(selectedLevel1?.level0_external_name ?? "");

  //     setlevel1Id(selectedLevel1?.campaign_id ?? null);
  //     setlevel1Name(selectedLevel1?.level1_external_name ?? "");
  //   } else if (level === 3) {
  //     setlevel0Id(selectedLevel2?.level0_id ?? null);
  //     setlevel0Name(selectedLevel2?.level0_external_name ?? "");

  //     setlevel1Id(selectedLevel2?.level1_id ?? null);
  //     setlevel1Name(selectedLevel2?.level1_external_name ?? "");

  //     setlevel2Id(selectedLevel2?.campaign_id ?? null);
  //     setlevel2Name(selectedLevel2?.level2_external_name ?? "");
  //   } else if (level === 4) {
  //     setlevel0Id(selectedLevel2?.level0_id ?? null);
  //     setlevel0Name(selectedLevel2?.level0_external_name ?? "");

  //     setlevel1Id(selectedLevel2?.level1_id ?? null);
  //     setlevel1Name(selectedLevel2?.level1_external_name ?? "");

  //     setlevel2Id(selectedLevel2?.campaign_id ?? null);
  //     setlevel2Name(selectedLevel2?.level2_external_name ?? "");

  //     setlevel3Id(selectedLevel3?.campaign_id ?? null);
  //     setlevel3Name(selectedLevel3?.level3_external_name ?? "");
  //   }
  // }

  async function getProductIndustryList(industry_id: number) {
    try {
     // console.log("0000000000"+companyId);
     // setProductsLoading(true);
      const response: Product[] =
        await ElectProductService.getIndustryProducts(
          companyId,
          industry_id
          
        );
        //console.log("aasdasdasds"+[...response]);
        setIndustryproductList([...response]);
    } catch (e) {
      console.error("Falha ao buscar lista de produtos");
      console.error(e);
      message.error("Falha ao buscar lista de Produtos");
    } 
  }

  function handleOpenNewModal() {
    
    setShowModal(true);

    
    
  }

  function handleOpenNewModalAward() {
    
    setShowModalAward(true);

    
    
  }

  function handleOpenNewModalMeta() {
    
    setShowModalMeta(true);

    
    
  }

  function clearData() {
    // setSelectedId(null);
    // setSelectedLevel(0);
    // setlevel0Name("");
    // setlevel1Name("");
    // setlevel2Name("");
    // setlevel3Name("");
    // setlevel4Name("");
    setCampaignAwardsList(null);
    setselectedProduct(null);
    setselectedProduct(undefined)
    handleSelectCampaign(selectedCampaign)
  }

  function getDataAward() {
    // setSelectedId(null);
    // setSelectedLevel(0);
    // setlevel0Name("");
    // setlevel1Name("");
    // setlevel2Name("");
    // setlevel3Name("");
    // setlevel4Name("");
    
    //handleSelectCampaign(selectedCampaign)
    getProductsInfo(selectedCampaignId,selectedProduct?.id)
  }

  function clearDataAward() {
    // setSelectedId(null);
    // setSelectedLevel(0);
    // setlevel0Name("");
    // setlevel1Name("");
    // setlevel2Name("");
    // setlevel3Name("");
    // setlevel4Name("");
    
    handleSelectCampaign(selectedCampaign)
    
  }
  
  // function getModalName() {
  //   switch (selectedLevel) {
  //     case 0:
  //       return level0Label;
  //     case 1:
  //       return level1Label;
  //     case 2:
  //       return level2Label;
  //     case 3:
  //       return level3Label;
  //     default:
  //       return level4Label;
  //   }
  // }

  // function disableSave() {
  //   switch (selectedLevel) {
  //     case 0:
  //       return !level0Name;
  //     case 1:
  //       return !level1Name;
  //     case 2:
  //       return !level2Name;
  //     case 3:
  //       return !level3Name;
  //     default:
  //       return !level4Name;
  //   }
  // }

  return (
    <Breadcrumb path="Eleger Produtos" child={["Elect Product"]}>
      <>
        <Row>
     

       

          <Col>
            <ColHeader>
              <span>CAMPANHA</span>
              {/* {level0Label != null ? (
                <></>
              ) : (
                // <Button
                //   onClick={() => handleOpenNewModal(0)}
                //   type="primary"
                //   icon={<FiPlus />}
                // >
                //   Novo
                // </Button>
              )} */}
            </ColHeader>

            <ItemsContainer>
              
              {renderCampaignListSelect()}
             
              </ItemsContainer>
          </Col>



         

          <Col>
            <ColHeader>
              <span>INDUSTRIA</span>
              {/* {level0Label != null ? (
                <></>
              ) : (
                // <Button
                //   onClick={() => handleOpenNewModal(0)}
                //   type="primary"
                //   icon={<FiPlus />}
                // >
                //   Novo
                // </Button>
              )} */}
            </ColHeader>

            <ItemsContainer>
              
              {renderIndustryList()}
              </ItemsContainer>
          </Col>


          <Col>
            <ColHeader>
              <span>PRODUTOS</span>
              <Button
                onClick={() => handleOpenNewModal()}
                disabled={!IndustryList}
                type="primary"
                icon={<FiPlus />}
              >
                Novo
              </Button>
            </ColHeader>

            <ItemsContainer>{renderProductList()}</ItemsContainer>
          </Col>

          <Col>
            <ColHeader>
              <span>PREMIOS</span>


               {selectedProduct === undefined ? (
                null
                  ) : (
                <Button
                  onClick={() => handleOpenNewModalAward()}
                  disabled={campaignAwardsList !== null}
                  type="primary"
                  icon={<FiPlus />}
                >
                  Adicione
                </Button>
              )}

              {/* <Button
                onClick={() => handleOpenNewModalAward()}
                disabled={campaignAwardsList !== null &&  selectedProduct !== undefined}
                type="primary"
                icon={<FiPlus />}
              >
                Novo
              </Button> */}
          
            </ColHeader>

            <ItemsContainer>
              {renderAwardListSelect()}
             
              </ItemsContainer>
          </Col>

          {selectedCampaign.tp_meta === 'S'   ? (
                <Col style={{width:"19%"}}>
                <ColHeader>
                  <span>METAS</span>
              

                  {selectedProduct !== undefined   ? (
                  <Button
                  onClick={() => handleOpenNewModalMeta()}
                  disabled={campaignMetaList !== null}
                  type="primary"
                  icon={<FiPlus />}
                >
                  Adicione
                </Button>
                 ) : (
                  null
                )}
              
                </ColHeader>
    
                <ItemsContainer>
                  
                 {renderMetaListSelect()}
                  </ItemsContainer>
              </Col>
                  ) : (
                null
              )}
         

        
        </Row>

        <Modal
          open={showModal}
          okText={"Adicionar"}
          onOk={handleSave}
         // okButtonProps={{ disabled: disableSave() }}
          onCancel={handleCancel}
        >
          <Typography.Title level={4}>
            Produtos de {IndustryList}
          </Typography.Title>
          {/* <Input
            //disabled={selectedLevel !== 0}
            size="large"
            placeholder="Nome Presidente"
           // value={level0Name}
            //onChange={(e) => setlevel0Name(e.target.value)}
          /> */}
          <Select
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  popupMatchSelectWidth={false}
                  showSearch
                  placeholder="Produtos"
                  optionFilterProp="children"
                 onChange={(value) => setSelectedInitiativeId(value)}
                  value={selectedInitiativeId}
                >
                  {IndustryproductList.map((initiative) => (
                    <Option value={initiative.id}>{initiative.product_name}</Option>
                  ))}
                </Select>

        </Modal>

        <Modal
          open={showModalAward}
          okText={"Salvar"}
          onOk={handleSaveAward}
         // okButtonProps={{ disabled: disableSave() }}
          onCancel={handleCancelAward}
        >
          {selectedCampaign.tp_meta === 'S'   ? (
          <Typography.Title level={4}>
          Adicionar Premiação a Meta do Produto
          </Typography.Title>
           ) : (
            <Typography.Title level={4}>
            Adicionar Premiação Por Vol. Vendido
            </Typography.Title>
          )}
        
          <Input
            //disabled={selectedLevel !== 0}
            size="large"
            placeholder="Digite"
            value={level0Value}
            //onChange={(e) => setlevel0Name(e.target.value)}
            onChange={handleChangeAward}
          />

        </Modal>

        <Modal
          open={showModalMeta}
          okText={"Adicionar"}
          onOk={handleSaveMeta}
         // okButtonProps={{ disabled: disableSave() }}
          onCancel={handleCancelMeta}
        >
          <Typography.Title level={4}>
          Adicionar Meta ao Produto (Vol. (kg/Lt/Sc))
          </Typography.Title>
          <Input
            //disabled={selectedLevel !== 0}
            size="large"
            placeholder="Digite"
            value={level1Value}
            onChange={handleChangeMeta}
          />
            <br/><span>{level1Value}. Vol. (kg/Lt/Sc) </span>
        </Modal>
      </>
    </Breadcrumb>
  );
}
