import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Table,
  Typography,
  Popconfirm,
  Select,
  DatePicker,
  Tag,
  Tooltip,
} from "antd";

import { useCallback, useEffect, useState } from "react";
import { FiEdit2, FiFilter, FiPlus, FiSearch, FiTrash } from "react-icons/fi";
import { Breadcrumb } from "../../../../components/Breadcrumb";
import Industry from "../../../../services/Industry";
import { Actions, Input } from "./styles";
import { useAppSelector } from "../../../../hooks";
import GlobalIndustryService from "../../../../services/Global/Industry";
import ElectProductService from "../../../../services/ElectProduct";
import moment from "moment";
import dayjs from "dayjs";
const { Option } = Select;
const { RangePicker } = DatePicker;
export function Campaign() {
  const companyId = useAppSelector((state) => state.parameters.company.id);

  const [modalNewParameter, setShowModalNewParameter] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selectedParameterId, setSelectedParameterId] = useState(null);
  const [listCapamign, setListCapamign] = useState([]);
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedInitiativeId, setSelectedInitiativeId] = useState("");
  const [selectedInitiative, setSelectedInitiative] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [dataInicio, setDataInicio] = useState("");
  console.log("🚀 ~ file: index.tsx:45 ~ Campaign ~ dataInicio:", dataInicio);
  const [dataFim, setDataFim] = useState("");
  const [listInitiatives, setListInitiatives] = useState([]);
  const [dataTipo, setDataIndustryId] = useState([]);
  const [dataIndustry, setDataIndustry] = useState([]);
  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };
  const getListParameter = useCallback(async () => {
    try {
      setLoading(true);
      const response = await Industry.getCampaignTeste(companyId, {});
      console.log(response);
      setListCapamign(response);
      setSearchData(response);
      setSearch("");
    } catch (e) {
      console.error("Falha ao buscar parâmetros: ");
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [companyId]);

  const getTipo = useCallback(async () => {
    const response = await ElectProductService.getTypeAll(companyId);
    console.log(response);
    setDataIndustryId(response);
  }, [companyId]);

  useEffect(() => {
    getListParameter();
    getListInitiatives();
    getAllIndustry();
    getTipo();
  }, [companyId]);

  const columns = [
    {
      title: "Nome",
      dataIndex: "campaign_name",
      key: "campaign_name",
      sorter: (a, b) => a.campaign_name.localeCompare(b.campaign_name),
    },
    {
      title: "Industria",
      dataIndex: "industry_id",
      key: "industry_id",
      sorter: (a, b) => a.industry_id.localeCompare(b.industry_id),
      render: (industry_id) => {
        const industry = listInitiatives.find(
          (industry) => industry.id === industry_id
        );
        return industry ? industry.trading_name : "";
      },
    },
    {
      title: "Campanha",
      dataIndex: "tipo",
      key: "tipo",
      sorter: (a, b) => a.tipo.localeCompare(b.tipo),
    },
    {
      title: "Data Inicio",
      dataIndex: "dateinit",
      key: "dateinit",
      sorter: (a, b) => a.dateinit.localeCompare(b.dateinit),
      render: (date) => {
        return date
          ? date.replace(/^(\d{4})-(\d{2})-(\d{2}).*/, "$3/$2/$1")
          : "";
      },
    },
    {
      title: "Data Fim",
      dataIndex: "dateend",
      key: "dateend",
      sorter: (a, b) => a.dateend.localeCompare(b.dateend),
      render: (date) => {
        return date
          ? date.replace(/^(\d{4})-(\d{2})-(\d{2}).*/, "$3/$2/$1")
          : "";
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (text) => {
        return <Tag color={getTagColor(text)}>{getTagText(text)}</Tag>;
      },
    },
    {
      title: "Ações",
      dataIndex: "uf",
      key: "uf",
      width: 150,
      align: "center",
      render: (_, record) => {
        return (
          <Actions>
            <Tooltip title="Editar">
              <Button
                type="text"
                icon={
                  <FiEdit2
                    size={18}
                    color="#00CC66"
                    onClick={() => handleClickEdit(record)}
                  />
                }
              />
            </Tooltip>
          </Actions>
        );
      },
    },
  ];

  async function confirmDelete(parameter) {
    try {
      await Industry.deleteById(companyId, parameter.campaign_id);
    } catch (e) {
      console.error("Falha ao excluir parâmetro: ");
      console.error(e);
    }
    await getListParameter();
  }

  function handleClickEdit(parameter) {
    console.log(
      "🚀 ~ file: index.tsx:180 ~ handleClickEdit ~ parameter:",
      parameter
    );

    setDisabled(true);
    setSelectedParameterId(parameter.campaign_id ?? null);
    setSelectedInitiative(parameter.tipo);
    setName(parameter.campaign_name);
    setSelectedInitiativeId(parameter.industry_id);
    setDataInicio(parameter.dateinit);
    setDataFim(parameter.dateend);
    setSelectedStatus(parameter.status);
    setValue(parameter.campaign_type);
    // setSelectedInitiative(parameter.type_id);
    setShowModalNewParameter(true);
  }
  function agora(data) {
    console.log("🚀 ~ file: index.tsx:184 ~ agora ~ data.length:", data.length);
    if (data.length > 1) {
      const agora = new Date(data);
      agora.setDate(agora.getDate() + 1);
      const novaData = agora.toISOString();
      return novaData;
    } else {
      const novaData = data;
      return novaData;
    }
  }

  function showModalNewParameter() {
    setDisabled(false);
    setShowModalNewParameter(true);
  }

  async function handleSave() {
    try {
      if (selectedParameterId) {
        await Industry.update(companyId, {
          campaign_id: selectedParameterId,
          campaign_name: name,
          industry_id: selectedInitiativeId,
          dateinit: dataInicio,
          dateend: dataFim,
          status: selectedStatus,
          campaign_type: value,
        });
      } else {
        await Industry.create(companyId, {
          campaign_name: name.toUpperCase(),
          industry_id: selectedInitiativeId,
          dateinit: dataInicio,
          dateend: dataFim,
          status: selectedStatus,
          campaign_type: value,
        });
      }
    } catch (e) {
      console.error("Falha ao salvar parâmetro: ");
      console.error(e);
    }

    await getListParameter();
    setShowModalNewParameter(false);
    clearData();
  }

  function clearData() {
    setSelectedParameterId(null);
    setName("");
    setSelectedInitiativeId("");
    setDataInicio("");
    setDataFim("");
    setValue("");
  }

  function handleCancel() {
    setShowModalNewParameter(false);
    setSelectedInitiativeId("");
    setSelectedInitiative(null);
    setDataFim("");
    setDataInicio("");
    setValue("");
    clearData();
  }

  const getAllIndustry = async () => {
    setLoading(true);
    const response = await Industry.getCampaignAll(companyId);
    setDataIndustry(response);
    setLoading(false);
  };

  function searchTable(value) {
    if (value) {
      setSearch(value);
      const array = listCapamign.filter(
        (record) =>
          record["campaign_name"].toLowerCase().indexOf(value.toLowerCase()) >
          -1
      );
      setSearchData(array);
    } else {
      setSearch("");
      setSearchData(listCapamign);
    }
  }
  const onChangeinit = (date, dateString) => {
    if (dateString) {
      console.log(
        "🚀 ~ file: index.tsx:268 ~ onChangeinit ~ dateString:",
        dateString
      );
      const dataAntiga = dateString;
      const partes = dataAntiga[0].split("/");
      const partesf = dataAntiga[1].split("/");
      const dataNovaIni = `${partes[2]}-${partes[1]}-${partes[0]}`;
      const dataNovaFim = `${partesf[2]}-${partesf[1]}-${partesf[0]}`;
      setDataInicio(dataNovaIni);
      setDataFim(dataNovaFim);
    } else {
      setDataInicio("");
      setDataFim("");
    }
  };

  const getListInitiatives = useCallback(async () => {
    const response = await GlobalIndustryService.getParameter();
    setListInitiatives(response);
  }, []);

  function getTagText(text) {
    switch (text) {
      case "A":
        return "Ativo";
      case "I":
        return " Inativo";
      case "F":
        return "Finalizado";
      default:
        return "Finalizado";
    }
  }
  function getTagColor(text) {
    switch (text) {
      case "A":
        return "green";
      case "I":
        return "blue";
      case "F":
        return "red";
      default:
        return "red";
    }
  }

  return (
    <Breadcrumb path="Campanhas" child={["Parâmetro"]}>
      <>
        <Card bordered={false}>
          <Row gutter={[16, 16]}>
            <Col span={18}>
              <Button
                type="primary"
                icon={<FiPlus />}
                onClick={showModalNewParameter}
              >
                Novo
              </Button>
            </Col>
            <Col span={6}>
              <Input
                allowClear
                value={search}
                onChange={(e) => searchTable(e.target.value)}
                placeholder="Pesquisar"
                prefix={<FiSearch color="#00CC66" />}
                suffix={<FiFilter color="#00CC66" />}
              />
            </Col>
            <Col span={24}>
              <Table
                rowKey="id"
                size="small"
                columns={columns}
                dataSource={searchData}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Card>

        <Modal
          open={modalNewParameter}
          okText={"Salvar"}
          cancelText={"Cancelar"}
          width={900}
          okButtonProps={{
            disabled:
              !name ||
              !selectedInitiativeId ||
              !value ||
              !dataInicio ||
              !dataFim ||
              !selectedStatus,
          }}
          onOk={handleSave}
          onCancel={handleCancel}
        >
          <Row justify="center">
            <Col span={16} style={{ textAlign: "center" }}>
              <Typography.Title level={4}>
                {selectedParameterId ? "Atualizar" : "Novo"} Campanha
              </Typography.Title>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  <div className="textModel">
                    <b>Nome</b> <b className="required">*</b>
                  </div>
                </Typography.Text>
                <Input
                  size="large"
                  disabled={disabled}
                  placeholder="Nome"
                  value={name != "" ? name.toUpperCase() : name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  <div className="textModel">
                    <b>Tipo de Indústria</b> <b className="required">*</b>
                  </div>
                </Typography.Text>
                <Select
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  popupMatchSelectWidth={false}
                  showSearch
                  disabled={disabled}
                  placeholder="Tipo de Indústria"
                  optionFilterProp="children"
                  onChange={(value) => [setSelectedInitiativeId(value)]}
                  value={selectedInitiativeId}
                >
                  {listInitiatives.map((initiative) => (
                    <Option value={initiative.id}>
                      {initiative.trading_name}
                    </Option>
                  ))}
                </Select>
              </Col>

              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  <div className="textModel">
                    <b>Tipo de Campanha</b> <b className="required">*</b>
                  </div>
                </Typography.Text>
                <Select
                  size="large"
                  placeholder="Tipo de Campanha"
                  value={value}
                  disabled={disabled}
                  onChange={(e) => setValue(e)}
                  style={{ width: "100%" }}
                >
                  {dataTipo.map((item) => (
                    <Option value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Col>

              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  <div className="textModel">
                    <b>Data inicio e Fim</b> <b className="required">*</b>
                  </div>
                </Typography.Text>
                <RangePicker
                  size="large"
                  format="DD/MM/YYYY"
                  placeholder={["Data Inicio", "Data Fim"]}
                  value={
                    dataInicio != "undefined-undefined-" &&
                    dataFim != "undefined-undefined-" &&
                    dataInicio != "" &&
                    dataFim != ""
                      ? [moment(agora(dataInicio)), moment(agora(dataFim))]
                      : null
                  }
                  onChange={onChangeinit}
                />
              </Col>

              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  <div className="textModel">
                    <b>Status</b> <b className="required">*</b>
                  </div>
                </Typography.Text>
                <Select
                  style={{ width: 200, margin: "10px 0" }}
                  size="large"
                  popupMatchSelectWidth={false}
                  showSearch
                  placeholder="Status"
                  optionFilterProp="children"
                  onChange={(value) => setSelectedStatus(value)}
                  value={selectedStatus}
                >
                  <Option value="A">Ativo</Option>
                  <Option value="I">Inativo</Option>
                  <Option value="F">Finalizado</Option>
                </Select>
              </Col>
            </Col>
          </Row>
        </Modal>
      </>
    </Breadcrumb>
  );
}
