import { CommercialStructureProps } from "../../../entities/DTO";
import { ItemActions, ItemLabel, StructureItemContainer } from "./styles";
import { FiEdit2, FiTrash } from "react-icons/fi";
import { Popconfirm, Tooltip } from "antd";
// import { CompanyCampaign } from "../../../entities/DTO";

interface CompanyCampaign {
  id: number;
  campaign_name: string;
}

interface CompanyAward {
  id: number;
  campaign_id: number;
  name: string;
}

interface TasksProps {
  id: number;
  industry_id: number;
  campaign_name: string;
  campaign_id: number;
  product: {
    id: number;
    company_id: number;
    industry_id: number;
    business_type_id: number;
    segment_name: string;
    sub_segment_name: string;
    brand_name: string;
    product_name: string;
    product_external_code: string | null;
    sku_external_code: string | null;
    sku_name: string;
    price_base_line: number;
    price_year1: number;
    price_year2: number;
    price_year3: number;
    price_year4: number;
    price_year5: number;
    type_name: string;
    KG_L: string | null;
    company_product_group_id: number;
    createdAt: string;
    updatedAt: string;
  };
}

interface Props {
  //structure: CommercialStructureProps;
  campaign: CompanyCampaign;
  level: number;
  handleClick: () => void;
  handleUpdate: () => void;
  handleDelete: () => void;
  isActive: boolean;
  showDelete: boolean;
  showUpdate: boolean;
}

interface PropsProduct {
  product: TasksProps;
  level: number;
  handleClick: () => void;

  handleDelete: () => void;
  isActive: boolean;
  showDelete: boolean;
}

interface PropsAward {
  award: CompanyAward;
  level: number;
  handleClick: () => void;

  handleDelete: () => void;
  isActive: boolean;
  showDelete: boolean;
}

export function StructureItem(props: Props) {
  function getName() {
    return `campaign_name`;
  }

  return (
    <StructureItemContainer onClick={props.handleClick} active={props.isActive}>
      <ItemLabel>{props.campaign.campaign_name}</ItemLabel>

      <ItemActions>
        {/* <FiEdit2
          onClick={e => {
            e.stopPropagation();
            props.handleUpdate();
          }}
          size={18}/> */}

        {props.showUpdate && (
          <Tooltip title="Editar">
            <FiEdit2
              onClick={(e) => {
                e.stopPropagation();
                props.handleUpdate();
              }}
              size={18}
            />
          </Tooltip>
        )}

        {props.showDelete && (
          <Tooltip title="Excluir">
            <Popconfirm
              disabled={!props.showDelete}
              title="Tem certeza que deseja excluir este item?"
              onConfirm={() => props.handleDelete()}
              okText="Excluir"
              okButtonProps={{ danger: true }}
              cancelText="Cancelar"
            >
              <FiTrash
                onClick={(e) => {
                  e.stopPropagation();
                }}
                size={18}
              />
            </Popconfirm>
          </Tooltip>
        )}
      </ItemActions>
    </StructureItemContainer>
  );
}

export function StructureItemProduct(props: PropsProduct) {
  function getName() {
    return `product_name`;
  }

  return (
    <StructureItemContainer onClick={props.handleClick} active={props.isActive}>
      <ItemLabel>{props.product.product.product_name}</ItemLabel>

      <ItemActions>
        {/* <FiEdit2
          onClick={e => {
            e.stopPropagation();
            props.handleUpdate();
          }}
          size={18}/> */}

        {/* {props.showUpdate && (
          <FiEdit2
          onClick={e => {
            e.stopPropagation();
            props.handleUpdate();
          }}
          size={18}/>
        )} */}

        {props.showDelete && (
          <Popconfirm
            disabled={!props.showDelete}
            title="Tem certeza que deseja excluir este item?"
            onConfirm={() => props.handleDelete()}
            okText="Excluir"
            okButtonProps={{ danger: true }}
            cancelText="Cancelar"
          >
            <FiTrash
              onClick={(e) => {
                e.stopPropagation();
              }}
              size={18}
            />
          </Popconfirm>
        )}
      </ItemActions>
    </StructureItemContainer>
  );
}

export function StructureItemAward(props: PropsAward) {
  function getName() {
    return `product_name`;
  }

  return (
    <StructureItemContainer onClick={props.handleClick} active={props.isActive}>
      <ItemLabel>{props.award.name}</ItemLabel>

      <ItemActions>
        {/* <FiEdit2
          onClick={e => {
            e.stopPropagation();
            props.handleUpdate();
          }}
          size={18}/> */}

        {/* {props.showUpdate && (
          <FiEdit2
          onClick={e => {
            e.stopPropagation();
            props.handleUpdate();
          }}
          size={18}/>
        )} */}

        {props.showDelete && (
          <Popconfirm
            disabled={!props.showDelete}
            title="Tem certeza que deseja excluir este item?"
            onConfirm={() => props.handleDelete()}
            okText="Excluir"
            okButtonProps={{ danger: true }}
            cancelText="Cancelar"
          >
            <FiTrash
              onClick={(e) => {
                e.stopPropagation();
              }}
              size={18}
            />
          </Popconfirm>
        )}
      </ItemActions>
    </StructureItemContainer>
  );
}
