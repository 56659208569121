import {
  Button,
  Card,
  Col,
  Input,
  message,
  Row,
  Table,
  Typography,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import {
  useAppSelector,
  useButtonActiveYears,
  useParameterName,
} from "../../../../hooks";

import TopDownCompanyService from "../../../../services/SalesPlanning/TopDownCompany";

import { Header, Buttons } from "../styles";
import { InputNumberTable } from "../../../../components/TableDinamic/InputNumber";
import { formatBR, formatPercent } from "../../../../utils";
import { FiFilter, FiSearch } from "react-icons/fi";

const { Column, ColumnGroup } = Table;

export function TableExpansion() {
  const parameters = useAppSelector((state) => state.entities.parameter);
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const years = useParameterName("QTD_YEARS");
  const [datat, setDatat] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [year0, setYear0] = useState<string | undefined>("");
  const [year0Active, setYear0Active] = useState<boolean>(true);
  const [year1, setYear1] = useState<string | undefined>("");
  const [year1Active, setYear1Active] = useState<boolean>(
    useButtonActiveYears(2)
  );
  const [year2, setYear2] = useState<string | undefined>("");
  const [year2Active, setYear2Active] = useState<boolean>(
    useButtonActiveYears(3)
  );
  const [year3, setYear3] = useState<string | undefined>("");
  const [year3Active, setYear3Active] = useState<boolean>(
    useButtonActiveYears(4)
  );
  const [year4, setYear4] = useState<string | undefined>("");
  const [year4Active, setYear4Active] = useState<boolean>(
    useButtonActiveYears(5)
  );
  const [year5, setYear5] = useState<string | undefined>("");
  const [year5Active, setYear5Active] = useState<boolean>(
    useButtonActiveYears(6)
  );
  const Icon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    setYear0(
      parameters.find((value) => value.name === "YEAR_BASE_LINE_LABEL")?.value
    );
    setYear1(parameters.find((value) => value.name === "YEAR1_LABEL")?.value);
    setYear2(parameters.find((value) => value.name === "YEAR2_LABEL")?.value);
    setYear3(parameters.find((value) => value.name === "YEAR3_LABEL")?.value);
    setYear4(parameters.find((value) => value.name === "YEAR4_LABEL")?.value);
    setYear5(parameters.find((value) => value.name === "YEAR5_LABEL")?.value);
  }, [parameters]);

  const loadTableExpansion = useCallback(
    async (load: boolean) => {
      try {
        setLoading(load);
        const response = await TopDownCompanyService.getTableExpansion(
          companyId
        );
        setData(response.records);
      } catch (err) {
        message.error(err.response.data.message);
      } finally {
        setLoading(false);
      }
    },
    [companyId]
  );

  useEffect(() => {
    loadTableExpansion(true);
  }, [loadTableExpansion]);

  async function onSave(data: any) {
    const body = {
      company_id: companyId,
      business_type_id: data.records.business_type_id,
      field_changed: data.name,
      new_value: data.value,
    };
    await TopDownCompanyService.postTableExpansion(companyId, body);
    loadTableExpansion(false);
  }

  async function onChange(search: string) {
    try {
      setLoading(true);
      const response = await TopDownCompanyService.searchTableExpansion(
        companyId,
        search
      );
      setData(response.records);
    } catch (err) {
      message.error(err.response.data.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Card bordered={false}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Header>
            <Buttons>
              <Row>
                <Col>
                  <Button
                    disabled
                    onClick={() => setYear0Active(!year0Active)}
                    type={year0Active ? "primary" : "default"}
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      display:
                        years?.value && parseInt(years.value) >= 1
                          ? "inline"
                          : "none",
                    }}
                  >
                    {year0}
                  </Button>
                  <Button
                    onClick={() => setYear1Active(!year1Active)}
                    type={year1Active ? "primary" : "default"}
                    style={{
                      borderRadius: 0,
                      display:
                        years?.value && parseInt(years.value) >= 2
                          ? "inline"
                          : "none",
                    }}
                  >
                    {year1}
                  </Button>
                  <Button
                    onClick={() => setYear2Active(!year2Active)}
                    type={year2Active ? "primary" : "default"}
                    style={{
                      borderRadius: 0,
                      display:
                        years?.value && parseInt(years.value) >= 3
                          ? "inline"
                          : "none",
                    }}
                  >
                    {year2}
                  </Button>
                  <Button
                    onClick={() => setYear3Active(!year3Active)}
                    type={year3Active ? "primary" : "default"}
                    style={{
                      borderRadius: 0,
                      display:
                        years?.value && parseInt(years.value) >= 4
                          ? "inline"
                          : "none",
                    }}
                  >
                    {year3}
                  </Button>
                  <Button
                    onClick={() => setYear4Active(!year4Active)}
                    type={year4Active ? "primary" : "default"}
                    style={{
                      borderRadius: 0,
                      display:
                        years?.value && parseInt(years.value) >= 5
                          ? "inline"
                          : "none",
                    }}
                  >
                    {year4}
                  </Button>
                  <Button
                    onClick={() => setYear5Active(!year5Active)}
                    type={year5Active ? "primary" : "default"}
                    style={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      display:
                        years?.value && parseInt(years.value) > 5
                          ? "inline"
                          : "none",
                    }}
                  >
                    {year5}
                  </Button>
                </Col>
              </Row>
            </Buttons>
            <Input
              allowClear
              onChange={(e) => onChange(e.target.value)}
              placeholder="Pesquisar"
              prefix={<FiSearch color="#00CC66" />}
              suffix={<FiFilter color="#00CC66" />}
              style={{ width: 350 }}
            />
          </Header>
        </Col>
        <Col span={24}>
          <Table
            rowKey={(record: any) =>
              record.business_type_id +
              record.potential_base_line +
              record.business_type_name
            }
            loading={{ indicator: Icon, spinning: loading }}
            scroll={{ y: "100vw", x: "100vw" }}
            size="small"
            dataSource={data}
          >
            <Column
              title="Negócio"
              dataIndex="business_type_name"
              width={120}
            />

            <ColumnGroup title="Potencial" className="color">
              {year0Active ? (
                <Column
                  className="color"
                  title={`${year0}`}
                  align="right"
                  dataIndex="potential_base_line"
                  width={150}
                  render={(record: number) => formatBR(record)}
                />
              ) : (
                <></>
              )}
              {year1Active ? (
                <Column
                  className="color"
                  title={year1}
                  align="right"
                  dataIndex="potential_year1"
                  width={150}
                  render={(record: number) => formatBR(record)}
                />
              ) : (
                <></>
              )}
              {year2Active ? (
                <Column
                  className="color"
                  title={year2}
                  align="right"
                  dataIndex="potential_year2"
                  width={150}
                  render={(record: number) => formatBR(record)}
                />
              ) : (
                <></>
              )}
              {year3Active ? (
                <Column
                  className="color"
                  title={year3}
                  align="right"
                  dataIndex="potential_year3"
                  width={150}
                  render={(record: number) => formatBR(record)}
                />
              ) : (
                <></>
              )}
              {year4Active ? (
                <Column
                  className="color"
                  title={year4}
                  align="right"
                  dataIndex="potential_year4"
                  width={150}
                  render={(record: number) => formatBR(record)}
                />
              ) : (
                <></>
              )}
              {year5Active ? (
                <Column
                  className="color"
                  title={year5}
                  align="right"
                  dataIndex="potential_year5"
                  width={150}
                  render={(record: number) => formatBR(record)}
                />
              ) : (
                <></>
              )}
            </ColumnGroup>
            <ColumnGroup title="Canal">
              {year0Active ? (
                <Column
                  title={`${year0}`}
                  align="right"
                  dataIndex="channel_base_line"
                  width={150}
                  render={(record: number) => formatBR(record)}
                />
              ) : (
                <></>
              )}
              {year1Active ? (
                <Column
                  title={year1}
                  align="right"
                  dataIndex="channel_year1"
                  width={150}
                  render={(record: number, records: any) => {
                    if (records.business_type_name === "Expansão") {
                      return (
                        <InputNumberTable
                          onSave={onSave}
                          records={records}
                          name="channel_year1"
                          initialValue={record}
                        />
                      );
                    }
                    return <Typography.Text>{record}</Typography.Text>;
                  }}
                />
              ) : (
                <></>
              )}
              {year2Active ? (
                <Column
                  title={year2}
                  align="right"
                  dataIndex="channel_year2"
                  width={150}
                  render={(record: number, records: any) => {
                    if (records.business_type_name === "Expansão") {
                      return (
                        <InputNumberTable
                          onSave={onSave}
                          records={records}
                          name="channel_year2"
                          initialValue={record}
                        />
                      );
                    }
                    return <Typography.Text>{record}</Typography.Text>;
                  }}
                />
              ) : (
                <></>
              )}
              {year3Active ? (
                <Column
                  title={year3}
                  align="right"
                  dataIndex="channel_year3"
                  width={150}
                  render={(record: number, records: any) => {
                    if (records.business_type_name === "Expansão") {
                      return (
                        <InputNumberTable
                          onSave={onSave}
                          records={records}
                          name="channel_year3"
                          initialValue={record}
                        />
                      );
                    }
                    return <Typography.Text>{record}</Typography.Text>;
                  }}
                />
              ) : (
                <></>
              )}
              {year4Active ? (
                <Column
                  title={year4}
                  align="right"
                  dataIndex="channel_year4"
                  width={150}
                  render={(record: number, records: any) => {
                    if (records.business_type_name === "Expansão") {
                      return (
                        <InputNumberTable
                          onSave={onSave}
                          records={records}
                          name="channel_year4"
                          initialValue={record}
                        />
                      );
                    }
                    return (
                      <Typography.Text>{formatPercent(record)}</Typography.Text>
                    );
                  }}
                />
              ) : (
                <></>
              )}
              {year5Active ? (
                <Column
                  title={year5}
                  align="right"
                  dataIndex="channel_year5"
                  width={150}
                  render={(record: number, records: any) => {
                    if (records.business_type_name === "Expansão") {
                      return (
                        <InputNumberTable
                          onSave={onSave}
                          records={records}
                          name="channel_year5"
                          initialValue={record}
                        />
                      );
                    }
                    return <Typography.Text>{record}</Typography.Text>;
                  }}
                />
              ) : (
                <></>
              )}
            </ColumnGroup>
            <ColumnGroup title="Industria" className="color">
              {year0Active ? (
                <Column
                  className="color"
                  title={`${year0}`}
                  align="right"
                  dataIndex="industry_base_line"
                  width={150}
                  render={(record: number) => formatBR(record)}
                />
              ) : (
                <></>
              )}
              {year1Active ? (
                <Column
                  className="color"
                  title={year1}
                  align="right"
                  dataIndex="industry_year1"
                  width={150}
                  render={(record: number, records: any) => {
                    if (records.business_type_name === "Expansão") {
                      return (
                        <InputNumberTable
                          onSave={onSave}
                          records={records}
                          name="industry_year1"
                          initialValue={record}
                        />
                      );
                    }
                    return <Typography.Text>{record}</Typography.Text>;
                  }}
                />
              ) : (
                <></>
              )}
              {year2Active ? (
                <Column
                  className="color"
                  title={year2}
                  align="right"
                  dataIndex="industry_year2"
                  width={150}
                  render={(record: number, records: any) => {
                    if (records.business_type_name === "Expansão") {
                      return (
                        <InputNumberTable
                          onSave={onSave}
                          records={records}
                          name="industry_year2"
                          initialValue={record}
                        />
                      );
                    }
                    return <Typography.Text>{record}</Typography.Text>;
                  }}
                />
              ) : (
                <></>
              )}
              {year3Active ? (
                <Column
                  className="color"
                  title={year3}
                  align="right"
                  dataIndex="industry_year3"
                  width={150}
                  render={(record: number, records: any) => {
                    if (records.business_type_name === "Expansão") {
                      return (
                        <InputNumberTable
                          onSave={onSave}
                          records={records}
                          name="industry_year3"
                          initialValue={record}
                        />
                      );
                    }
                    return <Typography.Text>{record}</Typography.Text>;
                  }}
                />
              ) : (
                <></>
              )}
              {year4Active ? (
                <Column
                  className="color"
                  title={year4}
                  align="right"
                  dataIndex="industry_year4"
                  width={150}
                  render={(record: number, records: any) => {
                    if (records.business_type_name === "Expansão") {
                      return (
                        <InputNumberTable
                          onSave={onSave}
                          records={records}
                          name="industry_year4"
                          initialValue={record}
                        />
                      );
                    }
                    return <Typography.Text>{record}</Typography.Text>;
                  }}
                />
              ) : (
                <></>
              )}
              {year5Active ? (
                <Column
                  className="color"
                  title={year5}
                  align="right"
                  dataIndex="industry_year5"
                  width={150}
                  render={(record: number, records: any) => {
                    if (records.business_type_name === "Expansão") {
                      return (
                        <InputNumberTable
                          onSave={onSave}
                          records={records}
                          name="industry_year5"
                          initialValue={record}
                        />
                      );
                    }
                    return <Typography.Text>{record}</Typography.Text>;
                  }}
                />
              ) : (
                <></>
              )}
            </ColumnGroup>
            <ColumnGroup title="Soc">
              {year0Active ? (
                <Column
                  title={`${year0}`}
                  align="right"
                  dataIndex="soc_year1"
                  width={120}
                  render={(record: number) => `${formatPercent(record)}%`}
                />
              ) : (
                <></>
              )}
              {year1Active ? (
                <Column
                  title={year1}
                  align="right"
                  dataIndex="soc_year1"
                  width={120}
                  render={(record: number) => `${formatPercent(record)}%`}
                />
              ) : (
                <></>
              )}
              {year2Active ? (
                <Column
                  title={year2}
                  align="right"
                  dataIndex="soc_year2"
                  width={120}
                  render={(record: number) => `${formatPercent(record)}%`}
                />
              ) : (
                <></>
              )}
              {year3Active ? (
                <Column
                  title={year3}
                  align="right"
                  dataIndex="soc_year3"
                  width={120}
                  render={(record: number) => `${formatPercent(record)}%`}
                />
              ) : (
                <></>
              )}
              {year4Active ? (
                <Column
                  title={year4}
                  align="right"
                  dataIndex="soc_year4"
                  width={120}
                  render={(record: number) => `${formatPercent(record)}%`}
                />
              ) : (
                <></>
              )}
              {year5Active ? (
                <Column
                  title={year5}
                  align="right"
                  dataIndex="soc_year5"
                  width={120}
                  render={(record: number) => `${formatPercent(record)}%`}
                />
              ) : (
                <></>
              )}
            </ColumnGroup>
            <ColumnGroup title="Cagr" className="color">
              <Column
                className="color"
                title="Canal"
                align="right"
                dataIndex="channel_cagr"
                width={120}
                render={(record: number) => `${formatPercent(record)}%`}
              />
              <Column
                className="color"
                title="Industria"
                align="right"
                dataIndex="industry_cagr"
                width={120}
                render={(record: number) => `${formatPercent(record)}%`}
              />
              <Column
                className="color"
                title="Mercado"
                dataIndex="market_growth"
                width={100}
                align="right"
                render={(record: number) => `${formatPercent(record)}%`}
              />
            </ColumnGroup>
          </Table>
        </Col>
      </Row>
    </Card>
  );
}
