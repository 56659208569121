import { Avatar, Button, Drawer, Dropdown, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import {
  FiBell,
  FiChevronDown,
  FiLogOut,
  FiMaximize2,
  FiMenu,
  FiShield,
} from "react-icons/fi";
import ParameterService from "../../../services/Global";

import { Logo, Container, Notification, Account } from "./styles";
import logo from "../../../assets/logo.svg";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { logout } from "../../../modules/Auth/Reducer";
import { persistor } from "../../../store";
import { decrement } from "../../../modules/Parameters/Reducer";
import { MenuProps } from "antd/lib";
import { BarChartOutlined } from "@ant-design/icons";
import { useState } from "react";
export function Header() {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { name } = useAppSelector((state) => state.auth.user);
  const companyName = useAppSelector((state) => state.parameters.company.name);
  const companyId = useAppSelector((state) => state.parameters.company.id);
  // const companyLogo = useAppSelector((state) => state.parameters.company.logo);
  const sleep = (ms: any) => new Promise((resolve) => setTimeout(resolve, ms));
  const logoutRedirect = async () => {
    try {
      localStorage.setItem("historico", "logout");
      if (companyId) {
        await ParameterService.logout(companyId);
      } else {
        await ParameterService.getglCompanies();
      }
      await sleep(200);
      dispatch(decrement());
      dispatch(logout());
      persistor.purge();
      navigate("/");
    } catch (error) {
      console.error("Error during logout process:", error);
    }
  };

  <div style={{ display: "flex", alignItems: "center" }}>
    <FiMenu
      size={24}
      onClick={() => setDrawerVisible(true)}
      style={{ cursor: "pointer" }}
    />
  </div>;
  const items: MenuProps["items"] = [
    {
      key: "company",
      label: "Trocar empresa",
      icon: <FiMaximize2 />,
    },
    {
      key: "logout",
      label: "Sair",
      icon: <FiLogOut />,
    },
  ];
  const handleMenuClick = ({ key }: { key: string }) => {
    if (key === "company") {
      navigate("/");
    } else if (key === "logout") {
      logoutRedirect();
    }
  };

  return (
    <Container>
      <Logo
        src={logo}
        alt="Logo Cropland"
        onClick={() => navigate("/select-module")}
      />
      <div className="mobile">
        <FiMenu
          size={24}
          onClick={() => setDrawerVisible(true)}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className="desktop-menu">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: 50,
          }}
        >
          {companyName !== undefined ? (
            <>
              <Avatar
                icon={<FiShield size={16} />}
                alt={"Logo " + companyName}
                style={{
                  width: 40,
                  height: 40,
                  marginRight: 5,
                }}
              />
            </>
          ) : null}

          <div style={{ display: "block", userSelect: "none" }}>
            <div>{companyName}</div>
            {/*<div style={{ marginTop: -45 }}>
              {companyCnpj?.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                "$1$2$3/$4-$5"
              )}
            </div>*/}
          </div>
        </div>
        {companyId ? (
          <Button
            type="primary"
            style={{ position: "absolute", left: 250 }}
            onClick={() => (window.location.href = "/Dashboard")}
          >
            <img
              width={40}
              style={{ marginLeft: "-20px", display: "none" }}
              alt="Dashboard"
            />
            Dashboard
          </Button>
        ) : null}

        <Notification>
          <FiBell style={{ marginRight: 10 }} size={18} />
          <Dropdown
            menu={{ items, onClick: handleMenuClick }}
            placement="bottomRight"
            arrow={{ pointAtCenter: true }}
          >
            <Account>
              <Avatar
                style={{
                  backgroundColor: "#c4c4c4",
                  paddingLeft: 8,
                  fontSize: 18,
                }}
              >
                {name ? name[0].toUpperCase() : ""}
              </Avatar>
              {name}
              <FiChevronDown />
            </Account>
          </Dropdown>
        </Notification>
      </div>
      <Drawer
        title="Menu"
        placement="right"
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
      >
        <Menu
          items={[
            {
              key: "dashboard",
              label: "Dashboard",
              icon: <BarChartOutlined />,
              onClick: () => {
                setDrawerVisible(false);
                window.location.href = "/Dashboard";
              },
            },
            {
              key: "company",
              label: "Trocar Empresa",
              icon: <FiMaximize2 />,
              onClick: () => {
                navigate("/");
                setDrawerVisible(false);
              },
            },
            {
              key: "logout",
              label: "Sair",
              icon: <FiLogOut />,
              onClick: () => {
                logoutRedirect();
                setDrawerVisible(false);
              },
            },
          ]}
        />
      </Drawer>
    </Container>
  );
}
