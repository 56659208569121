import React, { useEffect, useState, useRef, useCallback } from "react";
import { MapContainer, GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import PowerBi_all from "../../../services/Global/PowerBi";
import { Select, Layout, Spin } from "antd";
import PQueue from "p-queue";

const { Header, Content } = Layout;
const { Option } = Select;

interface DadosMaps {
  ibge: number;
  uf: string;
  municipio: string;
  fantasia: string;
  cor?: string;
}

const estados = [
  { codigo: "11", nome: "Rondônia", sigla: "RO" },
  { codigo: "12", nome: "Acre", sigla: "AC" },
  { codigo: "13", nome: "Amazonas", sigla: "AM" },
  { codigo: "14", nome: "Roraima", sigla: "RR" },
  { codigo: "15", nome: "Pará", sigla: "PA" },
  { codigo: "16", nome: "Amapá", sigla: "AP" },
  { codigo: "17", nome: "Tocantins", sigla: "TO" },
  { codigo: "21", nome: "Maranhão", sigla: "MA" },
  { codigo: "22", nome: "Piauí", sigla: "PI" },
  { codigo: "23", nome: "Ceará", sigla: "CE" },
  { codigo: "24", nome: "Rio Grande do Norte", sigla: "RN" },
  { codigo: "25", nome: "Paraíba", sigla: "PB" },
  { codigo: "26", nome: "Pernambuco", sigla: "PE" },
  { codigo: "27", nome: "Alagoas", sigla: "AL" },
  { codigo: "28", nome: "Sergipe", sigla: "SE" },
  { codigo: "29", nome: "Bahia", sigla: "BA" },
  { codigo: "31", nome: "Minas Gerais", sigla: "MG" },
  { codigo: "32", nome: "Espírito Santo", sigla: "ES" },
  { codigo: "33", nome: "Rio de Janeiro", sigla: "RJ" },
  { codigo: "35", nome: "São Paulo", sigla: "SP" },
  { codigo: "41", nome: "Paraná", sigla: "PR" },
  { codigo: "42", nome: "Santa Catarina", sigla: "SC" },
  { codigo: "43", nome: "Rio Grande do Sul", sigla: "RS" },
  { codigo: "50", nome: "Mato Grosso do Sul", sigla: "MS" },
  { codigo: "51", nome: "Mato Grosso", sigla: "MT" },
  { codigo: "52", nome: "Goiás", sigla: "GO" },
  { codigo: "53", nome: "Distrito Federal", sigla: "DF" },
];

const getColorByCategory = (category: string) => {
  const colors: { [key: string]: string } = {
    Branco: "#FFFFFF",
    Vermelho: "#FF0000",
    Amarelo: "#FFFF00",
    Azul: "#0000FF",
    Rosa: "#FFC0CB",
    Verde: "#008000",
    Preto: "#000000",
    Cinza: "#808080",
    Laranja: "#FFA500",
    Marrom: "#8B4513",
    Roxo: "#800080",
  };
  return colors[category] || "#FFFFFF";
};

const Maps = () => {
  const [geoData, setGeoData] = useState<GeoJSON.FeatureCollection | null>(
    null
  );
  const [dadosGerais, setDadosGerais] = useState<DadosMaps[]>([]);
  const [selectedUF, setSelectedUF] = useState<string | undefined>(undefined);
  const [selectedMunicipio, setSelectedMunicipio] = useState<
    string | undefined
  >(undefined);
  const mapRef = useRef<L.Map>(null);
  const cache = useRef<Map<string, any>>(new Map());

  const queue = new PQueue({ concurrency: 10 });

  const getDataMaps = async () => {
    const response = await PowerBi_all.getDataMapa();
    setDadosGerais(response);
    return response;
  };

  const fetchGeoData = async (url: string, codigo: string) => {
    if (cache.current.has(codigo)) {
      return cache.current.get(codigo);
    }
    try {
      const response = await fetch(url);
      if (!response.ok) {
        console.error(`Erro ao carregar dados: ${codigo}`);
        return null;
      }
      const data = await response.json();
      cache.current.set(codigo, data);
      return data;
    } catch (error) {
      console.error(`Erro ao carregar dados: ${codigo}`, error);
      return null;
    }
  };

  const fetchMunicipioData = (codigo: string) =>
    fetchGeoData(
      `https://servicodados.ibge.gov.br/api/v3/malhas/municipios/${codigo}?formato=application/vnd.geo+json&qualidade=minima`,
      codigo
    );

  const fetchEstadoData = (codigo: string) =>
    fetchGeoData(
      `https://servicodados.ibge.gov.br/api/v3/malhas/estados/${codigo}?formato=application/vnd.geo+json&qualidade=maxima`,
      codigo
    );

  const loadMunicipiosData = useCallback(
    async (dadosGeral: DadosMaps[]) => {
      try {
        const filteredDadosGeral = selectedUF
          ? dadosGeral.filter((dado) => dado.uf === selectedUF)
          : dadosGeral;

        const estadoCodigosMap = filteredDadosGeral.reduce(
          (acc: any, dado: any) => {
            const estado = estados.find((e) => e.sigla === dado.uf);
            if (estado) acc[estado.codigo] = true;
            return acc;
          },
          {} as { [key: string]: boolean }
        );

        const uniqueEstadoCodigos = Object.keys(estadoCodigosMap);

        const uniqueMunicipioCodigos = Array.from(
          new Set(filteredDadosGeral.map((dado: any) => dado.ibge.toString()))
        );

        const municipioData = await queue.addAll(
          uniqueMunicipioCodigos.map(
            (codigo) => () => fetchMunicipioData(codigo)
          )
        );

        const estadoData = await queue.addAll(
          uniqueEstadoCodigos.map((codigo) => () => fetchEstadoData(codigo))
        );

        const combinedGeoData: GeoJSON.FeatureCollection = {
          type: "FeatureCollection",
          features: [
            ...estadoData.flatMap((d) => d?.features || []),
            ...municipioData.flatMap((d) => d?.features || []),
          ],
        };

        setGeoData(combinedGeoData);

        if (mapRef.current && combinedGeoData.features.length > 0) {
          const bounds = L.geoJSON(combinedGeoData).getBounds();
          mapRef.current.fitBounds(bounds);
        } else {
          loadMunicipiosData(dadosGeral);
        }
      } catch (error) {
        console.error("Erro ao carregar os dados:", error);
      }
    },
    [selectedUF]
  );

  useEffect(() => {
    getDataMaps();
  }, []);

  useEffect(() => {
    if (dadosGerais.length > 0) {
      loadMunicipiosData(dadosGerais);
    }
  }, [selectedUF, dadosGerais, loadMunicipiosData]);

  useEffect(() => {
    if (selectedMunicipio) {
      const municipio = dadosGerais.find(
        (dado) => dado.municipio === selectedMunicipio
      );
      if (municipio) {
        const municipioCodigo = municipio.ibge.toString();
        fetchMunicipioData(municipioCodigo).then((data) => {
          if (data) {
            setGeoData({
              type: "FeatureCollection",
              features: data.features,
            });
            if (mapRef.current) {
              const bounds = L.geoJSON(data).getBounds();
              mapRef.current.fitBounds(bounds);
            }
          }
        });
      }
    }
  }, [selectedMunicipio, dadosGerais]);

  if (!geoData) {
    return <Spin tip="Carregando..." />;
  }

  const onEachFeature = (feature: GeoJSON.Feature, layer: L.Layer) => {
    const codarea = feature?.properties?.codarea;
    if (codarea?.length === 7) {
      const municipioData = dadosGerais.find(
        (dado: any) => dado.ibge.toString() === codarea
      );
      const tooltipContent = municipioData
        ? Object.entries(municipioData)
            .map(([key, value]) => `${key}: ${value}`)
            .join("<br>")
        : "Dados não disponíveis";
      layer.bindTooltip(tooltipContent);
      if (layer instanceof L.Path && municipioData) {
        layer.setStyle({
          color: getColorByCategory(municipioData["cor"] || ""),
        });
      }
    }
    layer.on({
      click: (e) => {
        console.log("Dados do município:", feature.properties);
        if (mapRef.current && feature.geometry) {
          const bounds = L.geoJSON(feature).getBounds();
          mapRef.current.fitBounds(bounds);
        }
        e.target.closePopup();
      },
    });
  };

  const style = (feature: GeoJSON.Feature | undefined) => {
    const codarea = feature?.properties?.codarea;
    if (codarea?.length === 7) {
      const municipioData = dadosGerais.find(
        (dado: any) => dado.ibge.toString() === codarea
      );
      return municipioData
        ? {
            color: getColorByCategory(municipioData["cor"] || ""),
            weight: 1,
          }
        : { color: "orange", weight: 1 };
    }
    return { color: "#000000", weight: 1 };
  };

  const estadosDisponiveis = estados.filter((estado) =>
    dadosGerais.some((dado) => dado.uf === estado.sigla)
  );

  const municipiosDisponiveis = selectedUF
    ? dadosGerais.filter((dado) => dado.uf === selectedUF)
    : [];

  return (
    <Layout style={{ height: "100vh" }}>
      <Header style={{ background: "#fff", padding: 0 }}>
        <Select
          placeholder="Selecione um estado"
          style={{ width: 200, margin: "16px" }}
          onChange={(value: any) => setSelectedUF(value)}
          allowClear
          showSearch
        >
          {estadosDisponiveis
            .sort((a, b) => a.nome.localeCompare(b.nome))
            .map((estado) => (
              <Option key={estado.sigla} value={estado.sigla}>
                {estado.nome}
              </Option>
            ))}
        </Select>
        <Select
          placeholder="Selecione um município"
          style={{ width: 200, margin: "16px" }}
          onChange={(value: any) => setSelectedMunicipio(value)}
          allowClear
          showSearch
          disabled={!selectedUF}
        >
          {municipiosDisponiveis
            .sort((a, b) => a.municipio.localeCompare(b.municipio))
            .map((municipio) => (
              <Option key={municipio.ibge} value={municipio.municipio}>
                {municipio.municipio}
              </Option>
            ))}
        </Select>
      </Header>
      <Content style={{ padding: "0 50px", marginTop: 64 }}>
        <div style={{ background: "#fff", padding: 24, minHeight: 380 }}>
          <MapContainer
            ref={mapRef}
            zoom={12}
            style={{ width: "100%", height: "500px" }}
            zoomControl={false}
            attributionControl={false}
          >
            <GeoJSON
              data={geoData}
              style={style}
              onEachFeature={onEachFeature}
            />
          </MapContainer>
        </div>
      </Content>
    </Layout>
  );
};

export default Maps;
