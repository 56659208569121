import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Select, Form, message } from "antd";
import {
  compareLabels,
  useAppDispatch,
  useAppSelector,
  useParameterName,
} from "../../../../hooks";
import CompanyCommercialStructureService from "../../../../services/Company/CommercialStructure";
import {
  setCommercialStructureLevel2,
  setCommercialStructureLevel3,
  setCommercialStructureLevel4,
} from "../../../../entities/Reducer";
import { FormInstance } from "antd/es/form";

interface Level1Props {
  formRef: RefObject<FormInstance<any>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}
interface Level1ValueProps {
  label: string;
  value: number;
}

export function Level1({ formRef, loading, setLoading }: Level1Props) {
  const dispatch = useAppDispatch();
  const recordsLevel1 = useAppSelector(
    (state) => state.entities.commercialStructure.level1
  );
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const labelLevel1 = useParameterName("LEVEL1_LABEL");
  const [level1, setLevel1] = useState<Level1ValueProps[]>([]);

  useEffect(() => {
    if (recordsLevel1) {
      const array = recordsLevel1.map((level) => {
        return {
          label: level?.level1_external_name,
          value: level?.level1_id,
        };
      });
      setLevel1(array);
    } else {
      setLevel1([]);
    }
  }, [recordsLevel1, dispatch]);

  useEffect(() => {
    level1.length === 1 && onChange(recordsLevel1[0]?.level1_id);
  }, [level1]);

  const onChange = useCallback(
    async (value: number) => {
      try {
        if (value) {
          setLoading(true);
          const response =
            await CompanyCommercialStructureService.getAllCommercialStrucuteLevel2(
              companyId,
              value
            );
          dispatch(setCommercialStructureLevel2(response));
          dispatch(setCommercialStructureLevel3([]));
          dispatch(setCommercialStructureLevel4([]));
          formRef.current?.setFieldsValue({
            level1: value,
            level2: response.length === 1 ? response[0]?.level2_id : null,
            level3: null,
            level4: null,
          });
        } else {
          dispatch(setCommercialStructureLevel2([]));
          dispatch(setCommercialStructureLevel3([]));
          dispatch(setCommercialStructureLevel4([]));
          formRef.current?.setFieldsValue({
            level2: null,
            level3: null,
            level4: null,
          });
        }
      } catch (err) {
        message.error(err.response.data.message);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, formRef, companyId, recordsLevel1, setLoading]
  );

  return (
    <Form.Item name="level1" rules={[{ required: true, message: "" }]}>
      <Select
        loading={loading}
        onChange={onChange}
        placeholder={labelLevel1?.value}
        allowClear
        disabled={!level1.length}
        defaultValue={
          recordsLevel1.length === 1 ? recordsLevel1[0]?.level1_id : undefined
        }
      >
        {level1.sort(compareLabels).map((type) => (
          <Select.Option key={type.value} value={type.value}>
            {type.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}
