import {
  useEffect,
  useState,
  SetStateAction,
  Dispatch,
  RefObject,
  useCallback,
} from "react";
import { Select, Form, message } from "antd";
import {
  useAppDispatch,
  useAppSelector,
  useParameterName,
} from "../../../../hooks";
import CompanyCommercialStructureService from "../../../../services/Company/CommercialStructure";
import {
  setCommercialStructureLevel1,
  setCommercialStructureLevel2,
  setCommercialStructureLevel3,
  setCommercialStructureLevel4,
} from "../../../../entities/Reducer";
import { FormInstance } from "antd/es/form";
interface Level0Props {
  setLoading: Dispatch<SetStateAction<boolean>>;
  formRef: RefObject<FormInstance<any>>;
}
interface Level0ValueProps {
  label: string;
  value: number;
}

export function Level0({ setLoading, formRef }: Level0Props) {
  const dispatch = useAppDispatch();
  const recordsLevel0 = useAppSelector(
    (state) => state.entities.commercialStructure.level0
  );
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const labelLevel0 = useParameterName("LEVEL0_LABEL");
  const [level0, setLevel0] = useState<Level0ValueProps[]>([]);

  useEffect(() => {
    const array =
      recordsLevel0 &&
      recordsLevel0.map(
        (level: { level0_external_name: string; level0_id: number }) => {
          return {
            label: level?.level0_external_name,
            value: level?.level0_id,
          };
        }
      );

    setLevel0(array ? array : []);
  }, [recordsLevel0, dispatch]);

  useEffect(() => {
    level0.length === 1 && onChange(recordsLevel0[0]?.level0_id);
  }, [level0]);

  const onChange = useCallback(
    async (value: number) => {
      try {
        if (value) {
          setLoading(true);
          const response =
            await CompanyCommercialStructureService.getAllCommercialStrucuteLevel1(
              companyId,
              value
            );
          dispatch(setCommercialStructureLevel1(response));
          localStorage.setItem("level0", value.toString());
          formRef.current?.setFieldsValue({
            level0: value,
            level1: response.length === 1 ? response[0]?.level1_id : null,
            level2: null,
            level3: null,
            level4: null,
          });
          dispatch(setCommercialStructureLevel2([]));
          dispatch(setCommercialStructureLevel3([]));
          dispatch(setCommercialStructureLevel4([]));
        } else {
          formRef.current?.setFieldsValue({
            level1: null,
            level2: null,
            level3: null,
            level4: null,
          });

          dispatch(setCommercialStructureLevel2([]));
          dispatch(setCommercialStructureLevel3([]));
          dispatch(setCommercialStructureLevel4([]));
        }
      } catch (err) {
        message.error(err.response.data.message);
      } finally {
        setLoading(false);
      }
    },
    [companyId, dispatch, formRef]
  );

  return (
    <Form.Item name="level0" rules={[{ required: true, message: "" }]}>
      <Select
        allowClear
        onChange={onChange}
        placeholder={labelLevel0?.value}
        defaultValue={
          recordsLevel0.length === 1 ? recordsLevel0[0]?.level0_id : undefined
        }
      >
        {level0.map((type) => (
          <Select.Option key={type.value} value={type.value}>
            {type.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}
