import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Modal,
  Typography,
  Input,
  Select,
  Popconfirm,
  message,
  Tooltip,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { FiEdit2, FiFilter, FiPlus, FiSearch, FiTrash } from "react-icons/fi";
import CompanyBusinessTypeService from "../../../services/Company/BusinessType";
import GlobalBusinessType from "../../../services/Global/BusinessType";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { useAppSelector } from "../../../hooks";
import { Actions } from "./styles";
interface TasksProps {
  id: number;
  company_id: string;
  industry_id: number;
  BusinessType_id: any;
  business_type_id: number;
  BusinessType_name: string;
  updatedAt: number;
  createdAt: number;
}
interface InitiativeProps {
  type_name: string;
  id: number;
  name: string;
  company_id: number;
  business_type_id: number;
  industry_id: string;
  BusinessType_name: string;
}
const { Option } = Select;
export function BusinessType() {
  const [modalNewBusiness, setModalNewBusiness] = useState(false);
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const [textError, setTextError] = useState("");
  const [modalError, setModalError] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectGlobalBusinessType, setSelectGlobalBusinessType] = useState<
    number | null | any[]
  >(null);
  const [globalBussinessType, setGlobalBussinessType] = useState<
    InitiativeProps[]
  >([]);
  const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null);
  const [selectedInitiativeId, setSelectedInitiativeId] = useState<
    number | null
  >(null);
  const [listTasks, setListTasks] = useState<TasksProps[]>([]);
  const [searchData, setSearchData] = useState<any | TasksProps[]>([]);
  const [search, setSearch] = useState("");
  const getGlobalBussinessType = useCallback(async () => {
    const response: InitiativeProps[] =
      await GlobalBusinessType.getGlobalBusinessType();
  }, []);
  const getListTasks = useCallback(async () => {
    try {
      setLoading(true);
      const response: any = await CompanyBusinessTypeService.getBusinessType(
        companyId
      );
      const data: any = [];
      for (let i = 0; i < response.length; i++) {
        data.push(response[i].business_type);
      }
      setGlobalBussinessType(data);
      const formattedTasks = response.map((rawTask: any) => {
        return {
          id: rawTask.id,
          company_id: rawTask.company_id,
          industry_id: rawTask.industry_id,
          business_type_id: rawTask.business_type_id,
          BusinessType_id: rawTask.business_type.id,
          BusinessType_name: rawTask.business_type.name,
        };
      });
      setListTasks(
        formattedTasks.sort(function (a: any, b: any) {
          if (a.id < b.id) {
            return -1;
          } else {
            return 1;
          }
        }) as []
      );
      setSearchData(
        formattedTasks.sort(function (a: any, b: any) {
          if (a.id < b.id) {
            return -1;
          } else {
            return 1;
          }
        })
      );
    } catch (e) {
      console.error("Falha ao buscar");
      setModalError(true);
      setTextError(e.message);
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    getListTasks();
    getGlobalBussinessType();
  }, [companyId]);
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: "Nome",
      dataIndex: "BusinessType_name",
      key: "BusinessType_name",
      sorter: (a: any, b: any) =>
        a.BusinessType_name.localeCompare(b.BusinessType_name),
    },
    {
      title: "Ações",
      dataIndex: "uf",
      key: "uf",
      width: 150,
      align: "center" as const,
      render: (_: null, record: TasksProps) => {
        return (
          <Actions>
            <Tooltip title="Editar">
              <Button
                type="text"
                icon={
                  <FiEdit2
                    size={18}
                    color="#00CC66"
                    onClick={() => handleClickEdit(record)}
                  />
                }
              />
            </Tooltip>
            <Tooltip title="Excluir">
              <Popconfirm
                title="Tem certeza que deseja excluir?"
                onConfirm={() => confirmDelete(record)}
                okText="Excluir"
                okButtonProps={{ danger: true }}
                cancelText="Cancelar"
              >
                <Button type="text" icon={<FiTrash size={18} color="#00CC66" />} />
              </Popconfirm>
            </Tooltip>
          </Actions>
        );
      },
    },
  ];

  function showModalNewBusiness() {
    setModalNewBusiness(true);
  }
  function handleClickEdit(task: TasksProps) {
    console.log(task);
    setSelectedTaskId(task.id ?? null);
    setSelectGlobalBusinessType(task.business_type_id);
    setModalNewBusiness(true);
  }
  function disableSave(): boolean {
    return !selectGlobalBusinessType;
  }
  async function confirmDelete(task: TasksProps) {
    try {
      console.log(task);
      await CompanyBusinessTypeService.deleteTask(companyId, task.id);
    } catch (e) {
      console.error("Falha ao excluir: ");
      message.error("Falha ao excluir.");
      setModalError(true);
      setTextError(e.message);
      console.error(e);
    }
    await getListTasks();
  }
  async function handleOk() {
    setModalNewBusiness(false);
    if (selectedTaskId) {
      try {
        await CompanyBusinessTypeService.updateTask(
          companyId,
          {
            id: selectedTaskId,
            industry_id: selectedInitiativeId,
            business_type_id: selectGlobalBusinessType,
          },
          selectedTaskId
        );
      } catch (e) {
        console.log("Falha ao atualizando");
        setModalError(true);
        setTextError(e.message);
        message.error("Falha ao Atualizar");
        console.error(e);
      }
    } else {
      try {
        await CompanyBusinessTypeService.createTask(companyId, {
          industry_id: selectedInitiativeId,
          business_type_id: selectGlobalBusinessType,
        });
      } catch (e) {
        console.log("Falha ao criar");
        setModalError(true);
        setTextError(e.message);
        console.error(e);
      }
    }

    await getListTasks();
    handleCancel();
  }

  function handleCancel() {
    setSelectedTaskId(null);
    setSelectGlobalBusinessType(null);
    setModalNewBusiness(false);
  }
  useEffect(() => {
    filterTable();
    function filterTable() {
      if (!search) {
        setSearchData(listTasks);
      } else {
        const array = listTasks.filter(
          (record) =>
            !search ||
            record["BusinessType_name"]
              .toLowerCase()
              .indexOf(search.toLowerCase()) > -1
        );
        setSearchData(array);
      }
    }
  }, [search, listTasks]);

  const { Title } = Typography;

  return (
    <Breadcrumb path="Tipo de Negócio" child={["Tipo de Negócio"]}>
      <>
        <Row align="middle" style={{ marginBottom: 16 }}>
          {/* Breadcrumb on the left */}

          {/* Title in the center */}
          <Col style={{ textAlign: "center", flexGrow: 1, background: "none" }}>
            <Title level={4} style={{ paddingBottom: "5px" }}>
              Tipo de Negócio
            </Title>
          </Col>
        </Row>
        <Card bordered={false}>
          <Row gutter={[16, 16]}>
            <Col span={18}>
              <Button
                type="primary"
                icon={<FiPlus />}
                onClick={showModalNewBusiness}
              >
                Novo
              </Button>
            </Col>
            <Col span={6}>
              <Input
                allowClear
                placeholder="Pesquisar"
                prefix={<FiSearch color="#00CC66" />}
                suffix={<FiFilter color="#00CC66" />}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <Table
                size="small"
                columns={columns}
                dataSource={searchData}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Card>
        <Modal
          open={modalNewBusiness}
          onOk={handleOk}
          onCancel={handleCancel}
          okText={"Salvar"}
          okButtonProps={{ disabled: disableSave() }}
        >
          <Row justify="center">
            <Col span={16} style={{ textAlign: "center" }}>
              <Typography.Title level={4}>
                {selectedTaskId
                  ? "Atualizar Tipo de Negócio"
                  : "Novo Tipo de Negócio"}
              </Typography.Title>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  <div className="textModel">
                    <b>Tipo de Negócio</b> <b className="required">*</b>
                  </div>
                </Typography.Text>
                <Select
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  popupMatchSelectWidth={false}
                  showSearch
                  placeholder="Tipo de Negócio"
                  optionFilterProp="children"
                  onChange={(value) => setSelectGlobalBusinessType(value)}
                  value={selectGlobalBusinessType}
                >
                  {globalBussinessType.map((initiative) => (
                    <Option value={initiative.id}>{initiative.name}</Option>
                  ))}
                </Select>
              </Col>
            </Col>
          </Row>
        </Modal>
      </>
    </Breadcrumb>
  );
}
